import { Anchor, Image } from "@mantine/core";

const Logo: React.FC<{
  href?: string;
}> = ({ href, ...props }) => {
  return (
    <Anchor {...props} href={href ? href : undefined} underline="never">
      <Image h={18} w={"auto"} src={"/logo.svg"} />
    </Anchor>
  );
};

export default Logo;
