import { Button, Container, Flex, Text, TextInput } from "@mantine/core";
import {
  IconExclamationCircle,
  IconInfoCircle,
  IconPassword,
} from "@tabler/icons-react";
import PageHeader from "../../components/page/PageHeader";
import { Helmet } from "react-helmet";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { IEmployerChangePasswordRequest } from "../../interfaces/employer";
import { changePassword, checkCurrentPassword } from "../../services/employer";
import { isStrongPass } from "../../helpers/validation";
import { notifications } from "@mantine/notifications";

const UserSettingsPassword = () => {
  const authContext = useContext(AuthContext);
  const [changePasswordRequest, setChangePasswordRequest] = useState<
    IEmployerChangePasswordRequest
  >({
    password: null,
    password_error: null,
    new_password: null,
    new_password_error: null,
    confirm_password: null,
    confirm_password_error: null,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const newPassRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const confPassRef = useRef<HTMLInputElement>(null);

  const setRequestProp = (
    prop: keyof IEmployerChangePasswordRequest,
    data: any
  ) => {
    setChangePasswordRequest((prev) => ({ ...prev, [prop]: data }));
  };

  const onSave = async () => {
    let isValid = false;
    if (changePasswordRequest.password !== null) {
      setLoading(true);
      const check = await checkCurrentPassword(
        changePasswordRequest.password,
        authContext.employer!.id
      );

      if (check.status === false) {
        setLoading(false);
        setRequestProp("password_error", "Current password is invalid.");
      }
      isValid = check.status;
    } else {
      isValid = false;
      setLoading(false);
      setRequestProp("password_error", "Please enter your current password.");
    }

    if (
      changePasswordRequest.new_password === null ||
      !isStrongPass(changePasswordRequest.new_password)
    ) {
      setRequestProp(
        "new_password_error",
        "Password must includes at least 8 chars with one upper/lowercase letter, one number and one special char."
      );
      setLoading(false);
    }

    if (
      changePasswordRequest.confirm_password === null ||
      changePasswordRequest.confirm_password !==
        changePasswordRequest.new_password
    ) {
      setRequestProp("confirm_password_error", "Passwords are not matching.");
      setLoading(false);
    }

    if (
      changePasswordRequest.password !== null &&
      isValid &&
      changePasswordRequest.confirm_password !== null &&
      changePasswordRequest.new_password !== null &&
      changePasswordRequest.confirm_password ===
        changePasswordRequest.new_password &&
      isStrongPass(changePasswordRequest.new_password)
    ) {
      const changePass = await changePassword(
        authContext.employer!.id,
        changePasswordRequest.new_password!,
        changePasswordRequest.password!
      );
      setLoading(false);
      if (changePass.status === true) {
        notifications.show({
          position: "top-right",
          title: "Done!",
          message: "Password has been updated",
          icon: <IconInfoCircle color="#007B80" />,
          color: "white",
          styles: {
            root: { backgroundColor: "#1d2124" },
            title: { color: "white" },
            body: { color: "white" },
          },
        });
        newPassRef.current!.value = "";
        passRef.current!.value = "";
        confPassRef.current!.value = "";
      } else {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Password couldn't update.",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>User Settings - Password</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconPassword />,
          title: "Change Password",
          description: "Update your current password.",
        }}
      />
      <Container size={"xl"}>
        <Flex
          style={{ margin: "0 auto" }}
          align={"center"}
          direction={"column"}
          justify={"center"}
        >
          <Text mb={15} size="16px" fw={600}>
            Update Your Personal Info
          </Text>
          <Text mb={15} w={320} ta={"center"}>
            Password must includes at least 8 chars with one upper/lowercase
            letter, one number and one special char.
          </Text>
          <TextInput
            ref={passRef}
            type="password"
            error={changePasswordRequest.password_error}
            onChange={(e) => {
              setRequestProp("password", e.currentTarget.value);
              setRequestProp("password_error", null);
            }}
            label="Current Password"
            placeholder="enter your current password"
            variant="filled"
            w={320}
            radius={0}
            mb={15}
          />
          <TextInput
            ref={passRef}
            type="password"
            error={changePasswordRequest.new_password_error}
            onChange={(e) => {
              setRequestProp("new_password", e.currentTarget.value);
              setRequestProp("new_password_error", null);
            }}
            label="New Password"
            placeholder="enter your new password"
            variant="filled"
            w={320}
            radius={0}
            mb={15}
          />
          <TextInput
            ref={confPassRef}
            type="password"
            error={changePasswordRequest.confirm_password_error}
            onChange={(e) => {
              setRequestProp("confirm_password", e.currentTarget.value);
              setRequestProp("confirm_password_error", null);
            }}
            label="Confirm New Password"
            placeholder="confirm your new password"
            variant="filled"
            w={320}
            radius={0}
            mb={15}
          />
          <Button
            loading={loading}
            onClick={onSave}
            w={320}
            mb={15}
            radius={32}
            color="#1d2124"
            fullWidth
          >
            Change Password
          </Button>
        </Flex>
      </Container>
    </>
  );
};

export default UserSettingsPassword;
