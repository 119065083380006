import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createPaymentIntent, getOrder } from "../../services/checkout";
import { IOrder } from "../../interfaces/checkout";
import { Helmet } from "react-helmet";
import { Center, Container, Divider, Flex, Paper, Text } from "@mantine/core";
import Logo from "../../components/Logo";
import Loading from "../../components/Loading";
import CheckoutForm from "../../components/checkout/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const PurchaseCheckout = () => {
  const params = useParams<{ order_id: string }>();
  const [order, setOrder] = useState<IOrder | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [clientSecret, setClientSecret] = useState<string>("");
  const navigate = useNavigate();

  const stripePromise = loadStripe(
    "pk_test_51QZmwxIZq6qCx3xTzN6XDbyhZ5a9D7vwFbBSIvoQteffuPlJ3Dr1BuFBM6fkJl3vwo5EQnUL6AvEn6TXdcqWl2Wv00BxCp6Zht"
  );

  useEffect(() => {
    if (typeof params !== "undefined" && params.order_id !== null) {
      setTimeout(async () => {
        const orderDetails = await getOrder(params.order_id!);
        if (orderDetails.status === false) {
          //navigate("/purchase");
        } else {
          setOrder(orderDetails.result);
        }
      }, 10);
    }
  }, [params]);

  useEffect(() => {
    if (order !== null) {
      setTimeout(async () => {
        const getClientSecret = await createPaymentIntent(order.amount);
        setClientSecret(getClientSecret.result);
        setLoading(false);
      }, 10);
    }
  }, [order]);

  if (order === null || loading) {
    return (
      <>
        <Helmet>
          <title>Complete Purchasing Job Slots</title>
        </Helmet>
        <Flex
          align={"center"}
          direction={"column"}
          h={"100%"}
          justify={"center"}
          rowGap={15}
        >
          <Logo href="/" />
          <Loading />
        </Flex>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Complete Purchasing Job Slots</title>
        </Helmet>
        <div>
          <Center
            style={{ borderBottom: "2px solid #E7ECEE", padding: "30px 0" }}
            mb={30}
          >
            <Logo href="/" />
          </Center>
          <Container size={"lg"}>
            <Flex
              justify={"space-between"}
              wrap={"wrap"}
              rowGap={30}
              align={"flex-start"}
            >
              <Paper miw={500}>
                <Text fw={600} mb={15} size="18px" c={"#1d2124"}>
                  Your Order
                </Text>
                <Text fw={600} mb={15} size="18px" c={"#1d2124"}>
                  {order.slot_count} Premium Job Slots
                </Text>
                <Flex align={"center"} justify={"space-between"}>
                  <Text>Your Price : </Text>
                  <Text>{(order.amount / 100).toFixed(2)}£</Text>
                </Flex>
                <Divider my={15} />
                <Flex align={"center"} justify={"space-between"}>
                  <Text>Total (incl. VAT) : </Text>
                  <Text>{(order.amount / 100).toFixed(2)}£</Text>
                </Flex>
              </Paper>
              <Elements
                options={{
                  clientSecret: clientSecret,
                  appearance: { theme: "stripe" },
                }}
                stripe={stripePromise}
              >
                <CheckoutForm order={order!} />
              </Elements>
            </Flex>
          </Container>
        </div>
      </>
    );
  }
};

export default PurchaseCheckout;
