import { AppShell } from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import Header from "./components/header/Header";
import Navbar from "./components/navbar/Navbar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { auths, routes } from "./routes";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./contexts/AuthProvider";
import NavbarBottom from "./components/navbar/NavbarBottom";
import PurchaseCheckout from "./pages/dash/PurchaseCheckout";
import PurchaseCheckoutComplete from "./pages/dash/PurchaseCheckoutComplete";
import { ApplicationsContext } from "./contexts/ApplicationsProvider";

const App = () => {
  const authContext = useContext(AuthContext);
  const appContext = useContext(ApplicationsContext);
  const [mount, setMount] = useState<boolean>(false);
  const [opened, { toggle }] = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();

  if (typeof authContext === "undefined" || typeof appContext === "undefined") {
    return null;
  } else {
    if (location.pathname.includes("auth")) {
      return (
        <div className="auth-wrapper">
          <Routes>
            {auths.map((r, i) => {
              return <Route element={r.element} key={r.path} path={r.path} />;
            })}
          </Routes>
        </div>
      );
    } else if (location.pathname.includes("checkout")) {
      return (
        <div className="auth-wrapper">
          <Routes>
            <Route
              element={<PurchaseCheckout />}
              key={"/purchase/checkout"}
              path={"/purchase/checkout/:order_id"}
            />
            <Route
              element={<PurchaseCheckoutComplete />}
              key={"/purchase/checkout/complete"}
              path={"/purchase/checkout/complete/:order_id"}
            />
          </Routes>
        </div>
      );
    } else {
      return (
        <AppShell
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          layout="alt"
        >
          <AppShell.Header withBorder={true}>
            <Header opened={opened} toggle={toggle} />
          </AppShell.Header>
          <AppShell.Navbar
            style={{ borderRight: "2px solid #E7ECEE" }}
            withBorder={false}
            pt={"xs"}
            p={"lg"}
          >
            <AppShell.Section grow>
              <Navbar opened={opened} toggle={toggle} />
            </AppShell.Section>
            <AppShell.Section>
              <NavbarBottom />
            </AppShell.Section>
          </AppShell.Navbar>
          <AppShell.Main>
            <Routes>
              {routes.map((r, i) => {
                return <Route element={r.element} key={r.path} path={r.path} />;
              })}
            </Routes>
          </AppShell.Main>
        </AppShell>
      );
    }
  }
};

export default App;
