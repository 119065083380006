import jsSHA from "jssha";
import axios from "axios";
import { IResult } from "../interfaces/result";
import { isURL } from "validator";
import { ComboboxItem } from "@mantine/core";

export const hashPassword = (pass: string) => {
  let hashObj = new jsSHA("SHA-512", "TEXT", { numRounds: 8 });
  hashObj.update(pass);
  return hashObj.getHash("HEX");
};

export const randomString = (length: number) => {
  var result = "";
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const searchCityCountryOrAddress = (
  term: string,
  type: "city,region,country" | "address"
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const searchResult = await axios.get(
      "https://api.mapbox.com/search/searchbox/v1/suggest?q=" +
        term +
        "&language=en&types=" +
        type.toString() +
        "&session_token=0cf57519-9061-4391-893d-2aea7aa01cf3&access_token=pk.eyJ1IjoiYmVrYWRhbiIsImEiOiJjbTFwczR0dHAwN2NtMndxemhoMXA4NmV4In0.Q9N0K7K9flaoaywq7vJF6g&limit=10"
    );

    const addresses: ComboboxItem[] = [];
    console.log(searchResult.data.suggestions);

    Array.from(searchResult.data.suggestions).map((a: any, i) => {
      const fullAddress: string[] = [];
      if (a.name !== null && a.name !== "") {
        fullAddress.push(a.name);
      }

      if (
        typeof a.context.region !== "undefined" &&
        a.context.region !== undefined &&
        a.context.region.name !== null &&
        a.context.region.name !== ""
      ) {
        fullAddress.push(a.context.region.name);
      }

      if (a.context.country.name !== null && a.context.country.name !== "") {
        fullAddress.push(a.context.country.name);
      }

      const address = fullAddress.join(", ");

      if (addresses.filter((s) => s.value === address).length === 0)
        addresses.push({ value: address, label: address });
    });

    console.log(addresses);

    resolve({
      error: null,
      status: true,
      result: addresses,
    });
  });
};

export const toSeoUrl = (url: string) => {
  return url
    .toString() // Convert to string
    .normalize("NFD") // Change diacritics
    .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
    .replace(/\s+/g, "-") // Change whitespace to dashes
    .toLowerCase() // Change to lowercase
    .replace(/&/g, "-and-") // Replace ampersand
    .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
    .replace(/-+/g, "-") // Remove duplicate dashes
    .replace(/^-*/, "") // Remove starting dashes
    .replace(/-*$/, ""); // Remove trailing dashes
};

export const parseUrl = (url: string) => {
  if (isURL(url)) {
    const urlData = new URL(url);
    return urlData.host;
  } else {
    return null;
  }
};

export const getCityRegionCountry = (address: string) => {
  const arr = address.split(",");
  if (arr.length > 2) {
    console.log({
      city: arr[0].trim(),
      region: arr[1].trim(),
      country: arr[2].trim(),
    });
    return {
      city: arr[0].trim(),
      region: arr[1].trim(),
      country: arr[2].trim(),
    };
  } else if (arr.length === 2) {
    console.log({
      city: arr[0].trim().length === 0 ? null : arr[0].trim(),
      region: null,
      country: arr[1].trim().length === 0 ? null : arr[1].trim(),
    });
    return {
      city: arr[0].trim().length === 0 ? null : arr[0].trim(),
      region: null,
      country: arr[1].trim().length === 0 ? null : arr[1].trim(),
    };
  } else {
    console.log({ city: null, region: null, country: null });
    return { city: null, region: null, country: null };
  }
};
