import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { verify } from "../../services/auth";
import { IResult } from "../../interfaces/result";
import { Flex, Paper, Text } from "@mantine/core";
import Logo from "../../components/Logo";
import Loading from "../../components/Loading";

const Verify = () => {
  const [result, setResult] = useState<IResult | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams<{ code: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof params !== "undefined" && params.code !== undefined) {
      setTimeout(async () => {
        const verifyResponse = await verify(params.code!);
        if (verifyResponse.status === true) {
          navigate("/auth/sign-in");
        } else {
          setResult(verifyResponse);
        }
      }, 1000);
    } else {
      navigate("/");
    }
  }, [params]);

  if (result !== null) {
    return (
      <>
        <Helmet>
          <title>Account Verification</title>
        </Helmet>
        <Flex
          align={"center"}
          py={30}
          direction={"column"}
          h={"100%"}
          justify={"space-between"}
        >
          <Logo />
          <Paper bg={"transparent"} w={320} p={"md"} radius={8} mt={15}>
            {result.status === false && result.result === "CouldntVerified" && (
              <Text ta={"center"} c={"crimson"}>
                Something went wrong! We couldn't verify your account. Please
                make sure that you have used the link in the email that we sent
                during registration.
              </Text>
            )}
            {result.status === false && result.result === "CodeNotFound" && (
              <Text ta={"center"} c={"crimson"}>
                Invalid request! Code is invalid! Please make sure that you have
                used the link in the email that we sent during registration.
              </Text>
            )}
          </Paper>
          <p>&nbsp;</p>
        </Flex>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Account Verification</title>
        </Helmet>
        <Flex
          align={"center"}
          py={30}
          direction={"column"}
          h={"100%"}
          justify={"space-between"}
        >
          <Logo />
          <Loading />
          <p>&nbsp;</p>
        </Flex>
      </>
    );
  }
};

export default Verify;
