import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Flex,
  Menu,
  Text,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import {
  IconDots,
  IconPlus,
  IconSend,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { IJobListItem, JobFilter } from "../../interfaces/job";
import { useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import {
  closeJobs,
  deleteJobAdMultiple,
  publishJobs,
} from "../../services/job";
import { updateCompany } from "../../services/company";
import { AuthContext } from "../../contexts/AuthProvider";

const JobsHeader: React.FC<{
  setUpdateLoading: React.Dispatch<SetStateAction<boolean>>;
  clearSelected: React.Dispatch<SetStateAction<IJobListItem[]>>;
  selecteds: IJobListItem[];
  clearSearch: React.Dispatch<SetStateAction<string | null>>;
  search: string | null;
  filter: JobFilter;
  setFilter: React.Dispatch<SetStateAction<JobFilter>>;
}> = ({
  selecteds,
  search,
  filter,
  setUpdateLoading,
  clearSearch,
  setFilter,
  clearSelected,
}) => {
  const authContext = useContext(AuthContext);
  const { width } = useViewportSize();
  const [toBeDeleted, setToBeDeleted] = useState<IJobListItem[]>([]);
  const [toBePublished, setToBePublished] = useState<IJobListItem[]>([]);
  const [toBeClosed, setToBeClosed] = useState<IJobListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: "Delete Job Ads",
      children: (
        <Text size="sm">
          Do you really want to delete <strong>{selecteds.length}</strong> Job
          Ads permanently?
        </Text>
      ),
      styles: { title: { fontWeight: "600" } },
      labels: {
        confirm: `Delete ${selecteds.length} Job Ads`,
        cancel: "Cancel",
      },
      confirmProps: { bg: "crimson" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => setToBeDeleted(selecteds),
    });

  const openPublishModal = () =>
    modals.openConfirmModal({
      title: "Publish Job Ads",
      children: (
        <Text size="sm">
          Do you really want to publish <strong>{selecteds.length}</strong> Job
          Ads?
        </Text>
      ),
      styles: { title: { fontWeight: "600" } },
      labels: {
        confirm: `Publish ${selecteds.length} Job Ads`,
        cancel: "Cancel",
      },
      confirmProps: { bg: "#007B80", fw: "400" },
      cancelProps: { fw: "400" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => setToBePublished(selecteds),
    });

  const openClosehModal = () =>
    modals.openConfirmModal({
      title: "Close Job Ads",
      children: (
        <Text size="sm">
          Do you really want to close <strong>{selecteds.length}</strong> Job
          Ads?
        </Text>
      ),
      styles: { title: { fontWeight: "600" } },
      labels: {
        confirm: `Close ${selecteds.length} Job Ads`,
        cancel: "Cancel",
      },
      confirmProps: { bg: "#007B80", fw: "400" },
      cancelProps: { fw: "400" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => setToBeClosed(selecteds),
    });

  useEffect(() => {
    if (toBeDeleted.length > 0) {
      const ids: string[] = [];

      toBeDeleted.forEach((d) => {
        ids.push(d.id);
      });

      if (ids.length > 0) {
        setLoading(true);
        setUpdateLoading(true);

        setTimeout(async () => {
          const response = await deleteJobAdMultiple(ids);
          if (response.status === true) {
            clearSelected([]);
            setToBeDeleted([]);
          } else {
            console.log(response);
          }
          setLoading(false);
          setUpdateLoading(false);
        }, 10);
      }
    }
  }, [toBeDeleted]);

  useEffect(() => {
    if (toBeClosed.length > 0) {
      const ids: string[] = [];

      toBeClosed.forEach((d) => {
        ids.push(d.id);
      });

      if (ids.length > 0) {
        setLoading(true);
        setUpdateLoading(true);

        setTimeout(async () => {
          const response = await closeJobs(ids, authContext.employer!.id);
          if (response.status === true) {
            clearSelected([]);
            setToBeClosed([]);
          } else {
            console.log(response);
          }
          setLoading(false);
          setUpdateLoading(false);
        }, 10);
      }
    }
  }, [toBeClosed]);

  useEffect(() => {
    if (toBePublished.length > 0) {
      const ids: string[] = [];

      toBePublished.forEach((d) => {
        ids.push(d.id);
      });

      if (ids.length > 0) {
        setLoading(true);
        setUpdateLoading(true);

        setTimeout(async () => {
          const response = await publishJobs(ids);
          if (response.status === true) {
            const uc = await updateCompany({
              id: authContext.employer!.company.id,
              slots: authContext.employer!.company.slots - toBePublished.length,
            });
            console.log(uc);
            clearSelected([]);
            setToBePublished([]);
          } else {
            console.log(response);
          }
          setLoading(false);
          setUpdateLoading(false);
        }, 10);
      }
    }
  }, [toBePublished]);

  if (typeof width === "undefined") {
    return null;
  } else {
    return (
      <Container mt={-30} size={"md"}>
        <Flex
          mb={15}
          wrap={"wrap"}
          rowGap={15}
          align={"center"}
          justify={width > 600 ? "space-between" : "center"}
        >
          <Flex align={"center"} wrap={"wrap"} rowGap={15} columnGap={15}>
            <Button
              disabled={loading}
              onClick={() => {
                setFilter("all");
              }}
              radius={32}
              c={filter === "all" ? "white" : "#1d2124"}
              color={filter === "all" ? "#1d2124" : "#DBE2E6"}
            >
              All
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                setFilter("published");
              }}
              radius={32}
              c={filter === "published" ? "white" : "#1d2124"}
              color={filter === "published" ? "#1d2124" : "#DBE2E6"}
            >
              Published
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                setFilter("expired");
              }}
              radius={32}
              c={filter === "expired" ? "white" : "#1d2124"}
              color={filter === "expired" ? "#1d2124" : "#DBE2E6"}
            >
              Expired
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                setFilter("draft");
              }}
              radius={32}
              c={filter === "draft" ? "white" : "#1d2124"}
              color={filter === "draft" ? "#1d2124" : "#DBE2E6"}
            >
              Drafts
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                setFilter("closed");
              }}
              radius={32}
              c={filter === "closed" ? "white" : "#1d2124"}
              color={filter === "closed" ? "#1d2124" : "#DBE2E6"}
            >
              Closed
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                setFilter("ready");
              }}
              radius={32}
              c={filter === "ready" ? "white" : "#1d2124"}
              color={filter === "ready" ? "#1d2124" : "#DBE2E6"}
            >
              Ready
            </Button>
            {search !== null && (
              <Button
                disabled={loading}
                onClick={() => {
                  clearSearch(null);
                  setFilter("all");
                }}
                color="#C6F16D"
                c={"#1d2124"}
                radius={32}
              >
                Clear Search
              </Button>
            )}
          </Flex>

          <Button
            disabled={loading}
            onClick={() => {
              navigate("/job-ads/create-new");
            }}
            color="#C6F16D"
            c={"#1d2124"}
            radius={32}
            leftSection={<IconPlus size={18} />}
          >
            New Job Ad
          </Button>
        </Flex>
        {selecteds.length > 0 && filter !== "all" && (
          <>
            <Divider mb={15} />
            <Flex align={"center"} justify={"space-between"}>
              <Text>
                Selected <strong>{selecteds.length}</strong> Job Ads
              </Text>
              <Flex align={"center"} columnGap={15}>
                <ActionIcon
                  onClick={() => {
                    clearSelected([]);
                  }}
                  color="#1d2124"
                  variant="outline"
                  radius={32}
                >
                  <IconX color="#1d2124" />
                </ActionIcon>
                <Menu withArrow radius={12}>
                  <Menu.Target>
                    <ActionIcon color="#1d2124" variant="outline" radius={32}>
                      <IconDots color="#1d2124" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown miw={120}>
                    {filter === "ready" && (
                      <>
                        <Menu.Item
                          onClick={openPublishModal}
                          leftSection={<IconSend size={16} />}
                        >
                          Publish
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          onClick={openDeleteModal}
                          c={"crimson"}
                          leftSection={<IconTrash size={16} />}
                        >
                          Delete
                        </Menu.Item>
                      </>
                    )}
                    {filter === "closed" && (
                      <>
                        <Menu.Item
                          onClick={openPublishModal}
                          leftSection={<IconSend size={16} />}
                        >
                          Re-Open
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          onClick={openDeleteModal}
                          c={"crimson"}
                          leftSection={<IconTrash size={16} />}
                        >
                          Delete
                        </Menu.Item>
                      </>
                    )}
                    {filter === "published" && (
                      <>
                        <Menu.Item
                          onClick={openClosehModal}
                          leftSection={<IconX size={16} />}
                        >
                          Close
                        </Menu.Item>
                      </>
                    )}
                    {filter === "draft" && (
                      <>
                        <Menu.Item
                          onClick={openDeleteModal}
                          c={"crimson"}
                          leftSection={<IconTrash size={16} />}
                        >
                          Delete
                        </Menu.Item>
                      </>
                    )}
                    {filter === "expired" && (
                      <>
                        <Menu.Item
                          onClick={openPublishModal}
                          leftSection={<IconSend size={16} />}
                        >
                          Extend
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          onClick={openDeleteModal}
                          c={"crimson"}
                          leftSection={<IconTrash size={16} />}
                        >
                          Delete
                        </Menu.Item>
                      </>
                    )}
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Flex>
          </>
        )}
      </Container>
    );
  }
};

export default JobsHeader;
