import { randomString } from "../helpers/data";
import { IOrder } from "../interfaces/checkout";
import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";

const stripe = require("stripe")(
  "sk_test_51QZmwxIZq6qCx3xTjOGPsCwy0np4wx0EMdzXs9MAswohewTYrtd2WOojv3dRTcMLv5LXUBX4xNAgKa97nfkSIj6R00mhQuCT64"
);

export const createPaymentIntent = (amount: number): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const response = await stripe.paymentIntents.create({
      amount: amount,
      currency: "gbp",
      automatic_payment_methods: {
        enabled: true,
      },
    });

    resolve({ error: null, status: true, result: response.client_secret });
  });
};

export const setOrder = (
  amount: number,
  company_id: string,
  user_id: string,
  slots: number
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const orderId = randomString(256);

    const { data, error } = await supabase
      .from("orders")
      .insert({
        company_id,
        employer_id: user_id,
        amount,
        slot_count: slots,
        code: orderId,
        status: false,
        created_at: new Date(),
      })
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: orderId });
    } else {
      resolve({
        error: error !== null ? error.message : "CouldntPlaceOrder",
        status: false,
        result: null,
      });
    }
  });
};

export const getOrder = (order_id: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data, error } = await supabase
      .from("orders")
      .select("*")
      .eq("code", order_id)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data[0] });
    } else {
      resolve({
        error: error !== null ? error.message : "CouldntGetOrder",
        status: false,
        result: null,
      });
    }
  });
};

export const completeOrder = (order: IOrder): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data, error } = await supabase
      .from("orders")
      .update({ status: true })
      .eq("id", order.id)
      .select();

    if (data !== null && data.length > 0) {
      const { data: getSlots, error: getSlotsError } = await supabase
        .from("companies")
        .select("*")
        .eq("id", order.company_id)
        .select();

      if (getSlots !== null && getSlots.length > 0) {
        const { data: updateSlots, error: updateSlotsError } = await supabase
          .from("companies")
          .update({ slots: getSlots[0].slots + order.slot_count })
          .eq("id", order.company_id)
          .select();

        if (updateSlots !== null && updateSlots.length > 0) {
          resolve({ error: null, status: true, result: order });
        } else {
          console.log(updateSlotsError);
          resolve({
            error: "CannotUpdateSlots",
            status: false,
            result: null,
          });
        }
      } else {
        console.log(getSlotsError);
        resolve({
          error: "CannotGetSlots",
          status: false,
          result: null,
        });
      }
    } else {
      console.log(error);
      resolve({
        error: "CannotUpdateOrderStatus",
        status: false,
        result: null,
      });
    }
  });
};
