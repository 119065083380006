import { Container, Flex, Image, Text } from "@mantine/core";
import PageHeader from "../../components/page/PageHeader";
import { IconBasketCheck } from "@tabler/icons-react";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useState } from "react";
import { IBillingItem } from "../../interfaces/checkout";
import { AuthContext } from "../../contexts/AuthProvider";
import { getBillingsByCompany } from "../../services/billing";
import Loading from "../../components/Loading";
import BillingListItem from "../../components/billing/BillingListItem";
import { useViewportSize } from "@mantine/hooks";

const Billings = () => {
  const { width } = useViewportSize();
  const authContext = useContext(AuthContext);
  const [orders, setOrders] = useState<IBillingItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(async () => {
      const getOrders = await getBillingsByCompany(
        authContext.employer!.company.id
      );
      setOrders(getOrders.result);
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Purchases - O&G Talent Dashboard</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconBasketCheck />,
          title: "Purchases",
          description: "Here you can see job ads you purchased on O&G Talent.",
        }}
      />
      {loading === false && (
        <Container mb={50} size={"xl"}>
          <Flex direction={"column"} rowGap={5}>
            <Text mb={15} fw={600} size="18px">
              Find all invoices of completed orders.
            </Text>
            {orders.length > 0 &&
              orders
                .sort((a, b) => {
                  return (
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                  );
                })
                .map((o, i) => {
                  return (
                    <BillingListItem
                      order={o}
                      company={authContext.employer!.company}
                    />
                  );
                })}
          </Flex>
        </Container>
      )}

      {loading === true && (
        <Flex align={"center"} justify={"center"}>
          <Loading />
        </Flex>
      )}
    </>
  );
};

export default Billings;
