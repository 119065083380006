import { Flex, Image, Text } from "@mantine/core";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { useViewportSize } from "@mantine/hooks";

const HomeHeader = () => {
  const { width } = useViewportSize();
  const authContext = useContext(AuthContext);
  return (
    <Flex
      p={"15"}
      mt={width < 600 ? 30 : 0}
      style={{ borderRadius: "8px" }}
      bg={"#F4F4F5"}
      align={"center"}
      wrap={"wrap"}
      columnGap={15}
    >
      <Image visibleFrom="sm" src={"/home-header-icon.svg"} />
      <Flex direction={"column"} rowGap={5}>
        <Text lh={"xs"} size="18px" maw={600}>
          Hi, <strong>{authContext.employer!.full_name}</strong>
        </Text>
        <Text lh={"xs"}>
          Here you'll find everything you need for your job, talent search and
          applications.
        </Text>
      </Flex>
    </Flex>
  );
};

export default HomeHeader;
