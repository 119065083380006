import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";

export const getAllApplications = (company_id: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data: all } = await supabase
      .from("applications")
      .select("*")
      .eq("company_id", company_id)
      .select();

    if (all !== null && all.length > 0) {
      const jobIds: string[] = [];
      const candidateIds: string[] = [];

      all.forEach((a) => {
        jobIds.push(a.job_id);
        candidateIds.push(a.candidate_id);
      });

      const { data: jobs } = await supabase
        .from("jobs")
        .select("*")
        .in("id", jobIds)
        .select();

      const { data: candidates } = await supabase
        .from("candidates")
        .select("*")
        .in("id", candidateIds)
        .select();

      const applications: any[] = [];

      all.map((app) => {
        const application = {
          ...app,
          job: jobs!.find((j) => j.id === app.job_id)!,
          candidate: candidates!.find((c) => c.id === app.candidate_id)!,
        };
        applications.push(application);
      });

      console.log(applications);

      resolve({ error: null, status: true, result: applications });
    } else {
      resolve({ error: null, status: true, result: [] });
    }
  });
};

export const updateApplicantStage = (
  id: string,
  status: number
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data: change } = await supabase
      .from("applications")
      .update({ stage: status })
      .eq("id", id)
      .select();

    if (change !== null && change.length > 0) {
      resolve({ error: null, status: true, result: null });
    } else {
      resolve({ error: null, status: false, result: null });
    }
  });
};
