import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import { IconBell } from "@tabler/icons-react";

const Notifications = () => {
  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconBell />,
          title: "Notifications",
          description:
            "Access a complete list of all notifications you received.",
        }}
      />
    </>
  );
};

export default Notifications;
