import { randomString } from "../helpers/data";
import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";

export const uploadToStorage = (
  bucketName: string,
  file: File,
  oldFilePath: string | null
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const newFileName =
      randomString(26) +
      "." +
      file.name.split(".")[file.name.split(".").length - 1];

    if (oldFilePath !== null) {
      // const { data } = supabase.storage
      //   .from(bucketName)
      //   .getPublicUrl(oldFilePath);

      // console.log("public url", data.publicUrl);

      // const { data: deleteFile } = await supabase.storage
      //   .from(bucketName)
      //   .remove([data.publicUrl]);

      await removeFromStorage(bucketName, oldFilePath);
    }

    const {
      data: uploadFile,
      error: uploadError,
    } = await supabase.storage
      .from(bucketName)
      .upload(newFileName, file, { upsert: true });

    if (uploadFile !== null) {
      const { data: getFile } = await supabase.storage
        .from(bucketName)
        .getPublicUrl(uploadFile.path);

      resolve({ error: null, status: true, result: getFile.publicUrl });
    } else {
      resolve({
        error: uploadError !== null ? uploadError.message : "CannotUpload",
        status: false,
        result: null,
      });
    }
  });
};

export const removeFromStorage = (
  bucketName: string,
  filePath: string
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data, error } = await supabase.storage
      .from(bucketName)
      .remove([filePath.split("/")[filePath.split("/").length - 1]]);

    if (data !== null) {
      resolve({ error: null, status: true, result: null });
    } else {
      resolve({
        error: error !== null ? error.message : "CannotDelete",
        status: false,
        result: null,
      });
    }
  });
};
