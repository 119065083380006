import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IResult } from "../../interfaces/result";
import { completeOrder, getOrder } from "../../services/checkout";
import { Helmet } from "react-helmet";
import { Center, Container, Text } from "@mantine/core";
import Loading from "../../components/Loading";
import { IOrder } from "../../interfaces/checkout";

const PurchaseCheckoutComplete = () => {
  const [order, setOrder] = useState<IOrder | undefined | null>(undefined);
  const [result, setResult] = useState<IResult | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams<{ order_id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof params !== null && params.order_id !== null) {
      setTimeout(async () => {
        const orderDetails = await getOrder(params.order_id!);
        setOrder(
          orderDetails.status === true && orderDetails.result.status === false
            ? orderDetails.result
            : null
        );
      }, 1000);
    }
  }, [params]);

  useEffect(() => {
    if (order === null) {
      setLoading(false);
    } else if (order !== undefined && order !== null) {
      if (order.status === false) {
        setTimeout(async () => {
          const completeCheckout = await completeOrder(order);
          if (completeCheckout.status === true) {
            setTimeout(() => {
              navigate("/dashboard");
            }, 2000);
            setLoading(false);
            setResult(completeCheckout);
          } else {
            setLoading(false);
            setResult(completeCheckout);
          }
        }, 1000);
      } else {
        setResult({
          error: "AlreadyCompleted",
          status: false,
          result: null,
        });
        setLoading(false);
      }
    }
  }, [order]);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>Completing Order</title>
        </Helmet>
        <Container py={"xl"}>
          <Text mb={15} ta={"center"} fw={600}>
            We are completing your order... Please do not close or refresh the
            page.
          </Text>
          <Center>
            <Loading />
          </Center>
        </Container>
      </>
    );
  } else if (loading === false && order === undefined) {
    return null;
  } else {
    return (
      <>
        <Helmet>
          <title>Completing Order</title>
        </Helmet>
        <Container py={"xl"}>
          {result !== null &&
            result.status === false &&
            result.error === "CannotGetSlots" && (
              <Text c={"crimson"} ta={"center"} fw={600}>
                Something went wrong! We clouldn't get slot data from database.
              </Text>
            )}
          {result !== null &&
            result.status === false &&
            result.error === "CannotUpdateSlots" && (
              <Text c={"crimson"} ta={"center"} fw={600}>
                Something went wrong! We clouldn't update slot data from
                database.
              </Text>
            )}
          {result !== null &&
            result.status === false &&
            result.error === "CannotUpdateOrderStatus" && (
              <Text c={"crimson"} ta={"center"} fw={600}>
                Something went wrong! We clouldn't update order data from
                database.
              </Text>
            )}
          {result !== null &&
            result.status === false &&
            result.error === "AlreadyCompleted" && (
              <Text c={"crimson"} ta={"center"} fw={600}>
                This order was already completed before!
              </Text>
            )}
          {result !== null && result.status === true && (
            <>
              <Text mb={15} ta={"center"} fw={600}>
                Congrats! You have purchased {result.result.slot_count} Job
                Slots.
              </Text>
              <Text ta={"center"}>You are being redirected...</Text>
            </>
          )}
        </Container>
      </>
    );
  }

  return null;
};

export default PurchaseCheckoutComplete;
