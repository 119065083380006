import { isURL } from "validator";
import { IJobAdRequest, IJobEditRequest } from "../interfaces/job";
import { getCityRegionCountry } from "./data";
import { IStage } from "../interfaces/application";

export const isReadyForPublish = (
  request: IJobAdRequest | IJobEditRequest,
  type: "draft" | "publish"
) => {
  let ready: boolean = true;

  if (request.title === null || request.title.length < 5) {
    if (type === "publish") {
      request = {
        ...request,
        ["titleError"]: "Please provide a title that has at least 5 chars.",
      };
    }
    ready = false;
  }

  if (request.level === null) {
    if (type === "publish") {
      request = {
        ...request,
        ["levelError"]: "Please choose a level.",
      };
    }
    ready = false;
  }

  if (request.category === null) {
    if (type === "publish") {
      request = {
        ...request,
        ["categoryError"]: "Please choose a job category.",
      };
    }
    ready = false;
  }

  if (request.workplace === null) {
    if (type === "publish") {
      request = {
        ...request,
        ["workplaceError"]: "Please choose a workplace option.",
      };
    }
    ready = false;
  }

  if (request.type === null) {
    if (type === "publish") {
      request = {
        ...request,
        ["typeError"]: "Please choose a type option.",
      };
    }
    ready = false;
  }

  if (request.description === null || request.description.length < 10) {
    if (type === "publish") {
      request = {
        ...request,
        ["descriptionError"]: "Description must be at least 10 chars long.",
      };
    }
    ready = false;
  }

  if (request.is_external === true) {
    if (request.external_url === null || !isURL(request.external_url)) {
      if (type === "publish") {
        request = {
          ...request,
          ["externalUrlError"]: "Invalid url.",
        };
      }
      ready = false;
    }
  }

  if (request.is_salary_visible === true) {
    if (request.min_salary === null) {
      if (type === "publish") {
        request = {
          ...request,
          ["minSalaryError"]: "Cannot be empty.",
        };
      }
      ready = false;
    }

    if (request.max_salary === null) {
      if (type === "publish") {
        request = {
          ...request,
          ["maxSalaryError"]: "Cannot be empty.",
        };
      }
      ready = false;
    }

    if (request.salary_currency === null) {
      if (type === "publish") {
        request = {
          ...request,
          ["salaryCurrencyError"]: "Cannot be empty.",
        };
      }
      ready = false;
    }

    if (
      request.min_salary !== null &&
      request.max_salary !== null &&
      request.min_salary >= request.max_salary
    ) {
      if (type === "publish") {
        request = {
          ...request,
          ["minSalaryError"]: "Cannot be equal or greater than max salary.",
        };
      }
      ready = false;
    }
  }

  if (request.address === null || request.address.length === 0) {
    console.log("address null");
    if (type === "publish") {
      request = {
        ...request,
        ["addressError"]: "Cannot be empty.",
      };
      ready = false;
    }
  } else {
    const { city, country } = getCityRegionCountry(request.address);
    if (
      city === null ||
      city === undefined ||
      country === null ||
      country === undefined
    ) {
      if (type === "publish") {
        request = {
          ...request,
          ["addressError"]: "Invalid address.",
        };
        ready = false;
      }
    }
  }

  return { ready, request };
};

export const parseAddress = (
  city: string,
  region: string | null,
  country: string
) => {
  const addressParts: string[] = [];
  addressParts.push(city);
  if (region !== null) {
    addressParts.push(region);
  }
  addressParts.push(country);
  return addressParts.join(", ");
};

export const parseApplicationListMessage = (stage: IStage) => {
  if (stage.stage === 0) {
    return "No Application Received for this Job Ad!";
  } else if (stage.stage === 1) {
    return "No Viewed Application for this Job Ad!";
  } else if (stage.stage === 2) {
    return "No Candidate who is in review for this Job Ad!";
  } else if (stage.stage === 3) {
    return "No Candidate who is being interviewed for this Job Ad!";
  } else if (stage.stage === 4) {
    return "No Rejected Candidate for this Job Ad!";
  } else if (stage.stage === 5) {
    return "No Accepted Candidate for this Job Ad!";
  }
};
