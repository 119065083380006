import {
  ActionIcon,
  Anchor,
  Badge,
  Checkbox,
  Flex,
  Menu,
  NumberFormatter,
  Paper,
  Text,
  Tooltip,
} from "@mantine/core";
import { IJobListItem, JobFilter } from "../../interfaces/job";
import {
  jobCategories,
  jobCurrencies,
  jobLevels,
  jobTypes,
  jobWorkplaces,
} from "../../data/job";
import React, { SetStateAction, useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  IconDots,
  IconExclamationCircle,
  IconEye,
  IconHelp,
  IconInfoCircle,
  IconPencil,
  IconSend,
  IconTrash,
  IconWand,
  IconX,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { closeJobs, deleteJobAd, publishJobs } from "../../services/job";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import Loading from "../Loading";
import { AuthContext } from "../../contexts/AuthProvider";

const JobListItem: React.FC<{
  setJobLoading: React.Dispatch<SetStateAction<boolean>>;
  isSelected: boolean;
  setSelected(code: string, selected: boolean): void;
  filter: JobFilter;
  job: IJobListItem;
  remove(code: string): void;
}> = ({ isSelected, setSelected, filter, job, remove, setJobLoading }) => {
  const authContext = useContext(AuthContext);
  const [address, setAddress] = useState<string | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const openPublishModal = () =>
    modals.openConfirmModal({
      title: "Publish Job Ads",
      children: (
        <Text size="sm">
          Do you really want to publish <strong>{job.title}</strong> Job Ads?
        </Text>
      ),
      styles: { title: { fontWeight: "600" } },
      labels: {
        confirm: `Publish Job Ads`,
        cancel: "Cancel",
      },
      confirmProps: { bg: "#007B80", fw: "400" },
      cancelProps: { fw: "400" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => publish(),
    });

  const openCloseModal = () =>
    modals.openConfirmModal({
      title: "Close Job Ads",
      children: (
        <Text size="sm">
          Do you really want to close <strong>{job.title}</strong> Job Ads?
        </Text>
      ),
      styles: { title: { fontWeight: "600" } },
      labels: {
        confirm: `Close Job Ads`,
        cancel: "Cancel",
      },
      confirmProps: { bg: "#007B80", fw: "400" },
      cancelProps: { fw: "400" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => closeJob(),
    });

  const closeJob = async () => {
    setLoading(true);
    setJobLoading(true);
    const closeIt = await closeJobs([job.id], authContext.employer!.id);
    if (closeIt.status === true) {
      notifications.show({
        position: "top-right",
        title: "Done!",
        message: `${job.title} job ad has been closed!`,
        icon: <IconInfoCircle color="#007B80" />,
        color: "white",
        styles: {
          root: { backgroundColor: "#1d2124" },
          title: { color: "white" },
          body: { color: "white" },
        },
      });
    } else {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "Something went wrong during closing job ad.",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
    }
    setLoading(false);
    setJobLoading(false);
  };

  const publish = async () => {
    setLoading(true);
    setJobLoading(true);
    const publishIt = await publishJobs([job.id]);
    if (publishIt.status === true) {
      notifications.show({
        position: "top-right",
        title: "Done!",
        message: `${job.title} job ad has been published!`,
        icon: <IconInfoCircle color="#007B80" />,
        color: "white",
        styles: {
          root: { backgroundColor: "#1d2124" },
          title: { color: "white" },
          body: { color: "white" },
        },
      });
    } else {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "Something went wrong during publishing job ad.",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
    }
    setLoading(false);
    setJobLoading(false);
  };

  const openModal = () =>
    modals.openConfirmModal({
      title: "Delete Job Ad Permanently",
      children: (
        <Text size="sm">
          Do you really want to delete this job ad?{" "}
          <strong>You cannot revert this action.</strong>
        </Text>
      ),
      confirmProps: { bg: "crimson", fw: "400" },
      labels: { confirm: "Delete", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteJob(),
      styles: { title: { fontWeight: "600" } },
    });

  useEffect(() => {
    const addressParts: string[] = [];
    if (job.city !== null) {
      addressParts.push(job.city);
    }
    if (job.region !== null) {
      addressParts.push(job.region);
    }
    if (job.country !== null) {
      addressParts.push(job.country);
    }

    if (addressParts.length > 0) {
      setAddress(addressParts.join(", "));
    } else {
      setAddress(null);
    }
    console.log(job);
  }, [job]);

  const deleteJob = async () => {
    setLoading(true);
    const deleteJobResult = await deleteJobAd(job.short_code!);
    if (deleteJobResult.status === true) {
      notifications.show({
        position: "top-right",
        title: "Done!",
        message: `${job.title} job ad has been removed!`,
        icon: <IconInfoCircle color="#007B80" />,
        color: "white",
        styles: {
          root: { backgroundColor: "#1d2124" },
          title: { color: "white" },
          body: { color: "white" },
        },
      });
      remove(job.short_code!);
    } else {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "Something went wrong during deleting job ad.",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
    }
    setLoading(false);
  };

  return (
    <Paper
      radius={12}
      p={"md"}
      style={
        job.status === 0
          ? { border: "2px solid #E7ECEE" }
          : job.status === 1
          ? { border: "2px solid #007B80", backgroundColor: "#f9fcfc" }
          : job.status === 2
          ? { border: "2px solid #1d2124" }
          : { border: "2px solid #C6F16D", backgroundColor: "#f9fdf0" }
      }
    >
      <Flex
        mb={(job.ready && job.status === 0) || job.status === 2 ? 15 : 0}
        columnGap={15}
        align={"center"}
      >
        {filter !== "all" && (
          <Checkbox
            onChange={(e) => {
              setSelected(job.id, e.currentTarget.checked);
            }}
            checked={isSelected}
            radius={32}
            color="#007B80"
            style={{ cursor: "pointer" }}
            styles={{ input: { cursor: "pointer" } }}
          />
        )}
        <Flex w={"100%"} align={"center"} justify={"space-between"}>
          <Flex direction={"column"} rowGap={5}>
            <Anchor
              mb={5}
              href={
                job.status !== 1
                  ? "/job-ads/edit/" + job.short_code
                  : job.status === 1
                  ? "/applications/" + job.short_code
                  : undefined
              }
              c={"#1d2124"}
              underline="never"
            >
              <Text size="16px" fw={600}>
                {job.title}
              </Text>
            </Anchor>
            <Flex
              mb={5}
              wrap={"wrap"}
              rowGap={10}
              align={"center"}
              columnGap={10}
            >
              {job.category !== null && (
                <Flex
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  tt={"capitalize"}
                  bg={"#F4F4F5"}
                  c="#1d2124"
                >
                  {
                    jobCategories.find(
                      (l) => l.value === job.category!.toString()
                    )!.label
                  }
                </Flex>
              )}
              {address !== null && (
                <Flex
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  tt={"capitalize"}
                  bg={"#F4F4F5"}
                  c="#1d2124"
                >
                  {address}
                </Flex>
              )}
              {job.type !== null && (
                <Flex
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  tt={"capitalize"}
                  bg={"#F4F4F5"}
                  c="#1d2124"
                >
                  {
                    jobTypes.find((l) => l.value === job.type!.toString())!
                      .label
                  }
                </Flex>
              )}
              {job.workplace !== null && (
                <Flex
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  tt={"capitalize"}
                  bg={"#F4F4F5"}
                  c="#1d2124"
                >
                  {
                    jobWorkplaces.find(
                      (l) => l.value === job.workplace!.toString()
                    )!.label
                  }
                </Flex>
              )}
              {job.level !== null && (
                <Flex
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  tt={"capitalize"}
                  bg={"#F4F4F5"}
                  c="#1d2124"
                >
                  {
                    jobLevels.find((l) => l.value === job.level!.toString())!
                      .label
                  }
                </Flex>
              )}
              {job.min_salary !== null &&
                job.min_salary > 0 &&
                job.max_salary !== null &&
                job.max_salary > 0 &&
                job.salary_currency !== null &&
                job.salary_currency !== undefined && (
                  <Flex
                    style={{ borderRadius: "32px" }}
                    px={"xs"}
                    py={1}
                    fz="13px"
                    fw={400}
                    tt={"capitalize"}
                    bg={"#F4F4F5"}
                    c="#1d2124"
                  >
                    <NumberFormatter
                      suffix={
                        jobCurrencies.find(
                          (l) => l.value === job.salary_currency!.toString()
                        )!.label
                      }
                      value={job.min_salary}
                      thousandSeparator
                    />
                    &nbsp;{"-"}&nbsp;
                    <NumberFormatter
                      suffix={
                        jobCurrencies.find(
                          (l) => l.value === job.salary_currency!.toString()
                        )!.label
                      }
                      value={job.max_salary}
                      thousandSeparator
                    />
                  </Flex>
                )}
            </Flex>
            <Flex columnGap={15} align={"center"} wrap={"wrap"}>
              <Text>
                created by <strong>{job.created_full_name}</strong>{" "}
                {moment(job.created_at).fromNow()}
              </Text>
              {job.status === 1 && (
                <Text>published {moment(job.published_at).fromNow()}</Text>
              )}
              {job.status === 2 && (
                <Text>queued {moment(job.created_at).fromNow()}</Text>
              )}
            </Flex>
          </Flex>
          {isSelected === false && loading === false && (
            <Menu withArrow radius={12}>
              <Menu.Target>
                <ActionIcon color="#1d2124" variant="outline" radius={32}>
                  <IconDots color="#1d2124" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown miw={120}>
                {job.status === 0 && (
                  <>
                    <Menu.Item
                      onClick={() => {
                        openPublishModal();
                      }}
                      c={"#1d2124"}
                      leftSection={<IconSend size={16} color="#1d2124" />}
                    >
                      Publish
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        navigate("/job-ads/edit/" + job.short_code);
                      }}
                      c={"#1d2124"}
                      leftSection={<IconPencil size={16} color="#1d2124" />}
                    >
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      onClick={openModal}
                      c={"crimson"}
                      leftSection={<IconTrash size={16} color="#1d2124" />}
                    >
                      Delete
                    </Menu.Item>
                  </>
                )}
                {job.status === 2 && (
                  <>
                    <Menu.Item
                      onClick={() => {
                        openPublishModal();
                      }}
                      c={"#1d2124"}
                      leftSection={<IconSend size={16} color="#1d2124" />}
                    >
                      Extend
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        navigate("/job-ads/edit/" + job.short_code);
                      }}
                      c={"#1d2124"}
                      leftSection={<IconPencil size={16} color="#1d2124" />}
                    >
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      onClick={openModal}
                      c={"crimson"}
                      leftSection={<IconTrash size={16} color="#1d2124" />}
                    >
                      Delete
                    </Menu.Item>
                  </>
                )}
                {job.status === 1 && (
                  <>
                    <Menu.Item
                      c={"#1d2124"}
                      leftSection={<IconWand size={16} color="#1d2124" />}
                    >
                      View Applications
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      onClick={() => {
                        window.open(
                          "https://oilandgastalent.com/job/" + job.short_code,
                          "_blank"
                        );
                      }}
                      c={"#1d2124"}
                      leftSection={<IconEye size={16} color="#1d2124" />}
                    >
                      View on Website
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      onClick={openCloseModal}
                      c={"crimson"}
                      leftSection={<IconX size={16} color="crimson" />}
                    >
                      Close
                    </Menu.Item>
                  </>
                )}
                {job.status === 3 && (
                  <>
                    <Menu.Item
                      onClick={openPublishModal}
                      c={"#1d2124"}
                      leftSection={<IconSend size={16} color="#1d2124" />}
                    >
                      Re-Open
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      onClick={openModal}
                      c={"crimson"}
                      leftSection={<IconTrash size={16} />}
                    >
                      Delete
                    </Menu.Item>
                  </>
                )}
              </Menu.Dropdown>
            </Menu>
          )}
          {loading === true && <Loading />}
        </Flex>
      </Flex>
      {job.ready && job.status === 0 && (
        <Flex align={"center"} columnGap={5}>
          <Badge fz={10} bg={"#C6F16D"} c={"#1d2124"}>
            Ready for Publish
          </Badge>

          <Tooltip
            withArrow
            label="Job Ad is ready for publish. Before publish we highly recommend to check its details."
          >
            <IconHelp size={16} />
          </Tooltip>
        </Flex>
      )}
      {job.ready && job.status === 2 && (
        <Flex align={"center"} columnGap={5}>
          <Badge fz={10} bg={"#C6F16D"} c={"#1d2124"}>
            Ready for Extending
          </Badge>
          <Tooltip
            withArrow
            label="Job Ad is ready for extending. Before publish we highly recommend to check its details."
          >
            <IconHelp size={16} />
          </Tooltip>
        </Flex>
      )}
      {job.status === 1 && job.is_external === false && (
        <Flex mt={5} align={"center"} columnGap={5}>
          <Badge fz={10} bg={"#C6F16D"} c={"#1d2124"}>
            Application Count : {job.application_count}
          </Badge>
        </Flex>
      )}
      {job.status === 1 && job.is_external === true && (
        <Flex mt={5} align={"center"} columnGap={5}>
          <Badge fz={10} bg={"#C6F16D"} c={"#1d2124"}>
            External Url Click Count : {job.external_url_click_count}
          </Badge>
        </Flex>
      )}
    </Paper>
  );
};

export default JobListItem;
