import moment from "moment-timezone";
import { getCityRegionCountry, randomString, toSeoUrl } from "../helpers/data";
import {
  IJob,
  IJobAdRequest,
  IJobEditRequest,
  IJobListItem,
} from "../interfaces/job";
import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";

export const getJobByShortCode = (code: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("jobs")
      .select("*")
      .eq("short_code", code)
      .select();

    if (data !== null && data.length > 0) {
      const job: IJob = {
        id: data[0].id,
        title: data[0].title,
        level: data[0].level,
        category: data[0].category,
        workplace: data[0].workplace,
        type: data[0].type,
        description: data[0].description,
        city: data[0].city,
        region: data[0].region,
        country: data[0].country,
        is_external: data[0].is_external,
        external_url: data[0].external_url,
        min_salary: data[0].min_salary,
        max_salary: data[0].max_salary,
        salary_currency: data[0].salary_currency,
        status: data[0].status,
        company_id: data[0].company_id,
        view_count: data[0].view_count,
        application_count: data[0].application_count,
        created_by: data[0].created_by,
        created_at: data[0].created_at,
        published_at: data[0].published_at,
        short_code: data[0].short_code,
        valid_until: data[0].valid_until,
        closed_by: data[0].closed_by,
        closed_at: data[0].closed_at,
        extended_by: data[0].extended_by,
        extended_at: data[0].extended_at,
        ready: data[0].ready,
        ref: data[0].ref,
        external_url_click_count: data[0].external_url_click_count,
      };

      resolve({ error: null, status: true, result: job });
    } else {
      resolve({ error: null, status: true, result: null });
    }
  });
};

export const getJobListByCompanyId = (company_id: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("jobs")
      .select("*")
      .eq("company_id", company_id)
      .select();

    if (data !== null && data.length > 0) {
      const ids: string[] = [];

      Array.from(data).map((d) => {
        if (new Date(d.valid_until) < new Date() && d.status === 1) {
          ids.push(d.id);
        }
      });

      let jobItems = data;

      if (ids.length > 0) {
        const { data: expired } = await supabase
          .from("jobs")
          .update({ status: 2 })
          .in("id", ids)
          .select();

        if (expired !== null && expired.length > 0) {
          jobItems = expired;
        }
      }

      const { data: employers } = await supabase.from("employers").select("*");

      const jobList: IJobListItem[] = [];

      jobItems.forEach((d) => {
        const job: IJobListItem = {
          id: d.id,
          title: d.title,
          level: d.level,
          category: d.category,
          workplace: d.workplace,
          type: d.type,
          description: d.description,
          city: d.city,
          region: d.region,
          country: d.country,
          is_external: d.is_external,
          external_url: d.external_url,
          min_salary: d.min_salary,
          max_salary: d.max_salary,
          salary_currency: d.salary_currency,
          status: d.status,
          company_id: d.company_id,
          view_count: d.view_count,
          application_count: d.application_count,
          created_by: d.created_by,
          created_at: d.created_at,
          published_at: d.published_at,
          short_code: d.short_code,
          valid_until: d.valid_until,
          closed_by: d.closed_by,
          closed_at: d.closed_at,
          extended_by: d.extended_by,
          extended_at: d.extended_at,
          ready: d.ready,
          ref: d.ref,
          external_url_click_count: d.external_url_click_count,
          created_full_name: employers!.find((e) => e.id === d.created_by)!
            .full_name,
        };
        jobList.push(job);
      });

      resolve({ error: null, status: true, result: jobList });
    } else {
      resolve({ error: null, status: true, result: [] });
    }
  });
};

export const saveJobAdAsDraft = (
  job: IJobAdRequest,
  company_id: string,
  employer_id: string
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    let city = null;
    let region = null;
    let country = null;
    if (job.address !== null) {
      const addressParts = getCityRegionCountry(job.address!);
      city = addressParts.city;
      region = addressParts.region;
      country = addressParts.country;
    }

    const seoUrl: string[] = [];

    seoUrl.push(toSeoUrl(job.title!));
    if (city !== null) {
      seoUrl.push("in-" + toSeoUrl(city));
    }
    if (region !== null) {
      seoUrl.push(toSeoUrl(region));
    }
    if (country !== null) {
      seoUrl.push(toSeoUrl(country));
    }

    seoUrl.push(randomString(7).toLowerCase());

    const short_code = seoUrl.join("-");
    const { data, error } = await supabase
      .from("jobs")
      .insert({
        title: job.title,
        level: job.level,
        category: job.category,
        workplace: job.workplace,
        type: job.type,
        description: job.description,
        city,
        region,
        country,
        is_external: job.is_external,
        external_url: job.external_url,
        external_url_click_count: 0,
        is_salary_visible: job.is_salary_visible,
        min_salary: job.min_salary,
        max_salary: job.max_salary,
        salary_currency: job.salary_currency,
        status: 0,
        company_id,
        view_count: 0,
        application_count: 0,
        created_by: employer_id,
        created_at: moment.tz(new Date(), "Europe/Berlin").local().toDate(),
        short_code,
        published_at: null,
        valid_until: null,
        closed_by: null,
        closed_at: null,
        extended_by: null,
        extended_at: null,
        ready: job.ready,
        ref: job.ref,
      })
      .select();

    console.log(data, error);

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data[0].short_code });
    } else {
      resolve({
        error: error !== null ? error.message : "CannotSavedAsDraft",
        status: false,
        result: null,
      });
    }
  });
};

export const saveJobAd = (job: IJobEditRequest): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    let city = null;
    let region = null;
    let country = null;
    if (job.address !== null) {
      const addressParts = getCityRegionCountry(job.address!);
      city = addressParts.city;
      region = addressParts.region;
      country = addressParts.country;
    }

    const seoUrl: string[] = [];

    seoUrl.push(toSeoUrl(job.title!));
    if (city !== null) {
      seoUrl.push("in-" + toSeoUrl(city));
    }
    if (region !== null) {
      seoUrl.push(toSeoUrl(region));
    }
    if (country !== null) {
      seoUrl.push(toSeoUrl(country));
    }

    seoUrl.push(
      job.short_code!.split("-")[job.short_code!.split("-").length - 1]
    );

    const short_code = seoUrl.join("-");

    const { data, error } = await supabase
      .from("jobs")
      .update({
        title: job.title,
        level: job.level,
        category: job.category,
        workplace: job.workplace,
        type: job.type,
        description: job.description,
        city,
        region,
        country,
        is_external: job.is_external,
        external_url: job.external_url,
        external_url_click_count: 0,
        is_salary_visible: job.is_salary_visible,
        min_salary: job.min_salary,
        max_salary: job.max_salary,
        salary_currency: job.salary_currency,
        short_code,
        ready: job.ready,
        ref: job.ref,
      })
      .eq("short_code", job.short_code)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data[0] });
    } else {
      resolve({
        error: error !== null ? error.message : "CannotSavedAsDraft",
        status: false,
        result: null,
      });
    }
  });
};

export const deleteJobAd = (code: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("jobs")
      .delete()
      .eq("short_code", code)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: null });
    } else {
      resolve({ error: null, status: false, result: null });
    }
  });
};

export const deleteJobAdMultiple = (ids: string[]): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("jobs")
      .delete()
      .in("id", ids)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: null });
    } else {
      resolve({ error: null, status: false, result: null });
    }
  });
};

export const publishJobs = (ids: string[]): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data, error } = await supabase
      .from("jobs")
      .update({
        published_at: new Date(),
        valid_until: moment
          .tz(new Date(), "Europe/Berlin")
          .add(1, "month")
          .local()
          .toDate(),
        status: 1,
      })
      .in("id", ids)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data });
    } else {
      resolve({
        error: error !== null ? error.message : null,
        status: false,
        result: null,
      });
    }
  });
};

export const closeJobs = (ids: string[], by: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data, error } = await supabase
      .from("jobs")
      .update({
        published_at: null,
        valid_until: null,
        status: 3,
        closed_at: new Date(),
        closed_by: by,
      })
      .in("id", ids)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data });
    } else {
      resolve({
        error: error !== null ? error.message : null,
        status: false,
        result: null,
      });
    }
  });
};

export const publishDirectly = (
  job: IJobAdRequest,
  company_id: string,
  employer_id: string
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    let city = null;
    let region = null;
    let country = null;
    if (job.address !== null) {
      const addressParts = getCityRegionCountry(job.address!);
      city = addressParts.city;
      region = addressParts.region;
      country = addressParts.country;
    }

    const seoUrl: string[] = [];

    seoUrl.push(toSeoUrl(job.title!));
    if (city !== null) {
      seoUrl.push("in-" + toSeoUrl(city));
    }
    if (region !== null) {
      seoUrl.push(toSeoUrl(region));
    }
    if (country !== null) {
      seoUrl.push(toSeoUrl(country));
    }

    seoUrl.push(randomString(7).toLowerCase());

    const short_code = seoUrl.join("-");

    const { data, error } = await supabase
      .from("jobs")
      .insert({
        title: job.title,
        level: job.level,
        category: job.category,
        workplace: job.workplace,
        type: job.type,
        description: job.description,
        city,
        region,
        country,
        is_external: job.is_external,
        external_url: job.external_url,
        external_url_click_count: 0,
        is_salary_visible: job.is_salary_visible,
        min_salary: job.min_salary,
        max_salary: job.max_salary,
        salary_currency: job.salary_currency,
        status: 1,
        company_id,
        view_count: 0,
        application_count: 0,
        created_by: employer_id,
        created_at: new Date(),
        short_code,
        published_at: new Date(),
        valid_until: moment
          .tz(new Date(), "Europe/Berlin")
          .add(1, "month")
          .local()
          .toDate(),
        closed_by: null,
        closed_at: null,
        extended_by: null,
        extended_at: null,
        ready: job.ready,
        ref: job.ref,
      })
      .select();

    console.log(data, error);

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data[0].short_code });
    } else {
      resolve({
        error: error !== null ? error.message : "CannotPublishedDirectly",
        status: false,
        result: null,
      });
    }
  });
};
