import { Flex, Image, Text } from "@mantine/core";

const TeamContactSummary: React.FC<{ count: number }> = ({ count }) => {
  return (
    <Flex
      style={{ borderRadius: "8px", border: "2px solid #E7ECEE" }}
      w={"100%"}
      p={15}
      wrap={"wrap"}
      rowGap={15}
      align={"center"}
      justify={"space-between"}
    >
      <Flex bg={"white"} align={"center"} columnGap={15}>
        <Image src={"/team-contact.svg"} />
        <Text size="18px">
          You have <strong>{count} contacts.</strong>
        </Text>
      </Flex>
    </Flex>
  );
};

export default TeamContactSummary;
