import { IApplication } from "../interfaces/application";
import { ICandidate } from "../interfaces/candidate";

export const getLocationsOfApplicants = (
  applications: IApplication[],
  job_id: string
) => {
  const cities: string[] = [];
  const regions: string[] = [];
  const countries: string[] = [];

  const candidates: ICandidate[] = [];

  const apps = applications.filter((a) => a.job.id === job_id);

  apps.forEach((a) => {
    candidates.push(a.candidate);
  });

  candidates.forEach((c) => {
    if (!countries.includes(c.country)) {
      countries.push(c.country);
    }

    if (c.region !== null && !regions.includes(c.region)) {
      regions.push(c.region);
    }

    if (!cities.includes(c.city)) {
      cities.push(c.city);
    }
  });

  return { countries, cities, regions };
};
