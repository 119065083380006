import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import { IconMessage } from "@tabler/icons-react";
import { Container, Divider, Flex, Grid, Image, Text } from "@mantine/core";
import MessageChatList from "../../components/messages/MessageChatList";
import { useViewportSize } from "@mantine/hooks";

const Messages = () => {
  const { width } = useViewportSize();
  return (
    <>
      <Helmet>
        <title>Job Ads</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconMessage />,
          title: "Messages",
          description:
            "Access a complete list of ongoing chats, sorted by recent activity or importance.",
        }}
      />
    </>
  );
};

export default Messages;
