import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { ScrollMode, Viewer, ViewMode, Worker } from "@react-pdf-viewer/core";
import { Avatar, Flex, Modal, ScrollArea, Text } from "@mantine/core";
import React, { SetStateAction, useEffect, useState } from "react";
import { IApplication } from "../interfaces/application";
import { parseAddress } from "../helpers/job";
import moment from "moment";

const ApplicationNavigatorModal: React.FC<{
  currentApplication: IApplication | null;
  opened: boolean;
  close: React.Dispatch<SetStateAction<boolean>>;
  setCurrentApplication: React.Dispatch<SetStateAction<IApplication | null>>;
}> = ({ currentApplication, opened, close, setCurrentApplication }) => {
  const [application, setApplication] = useState<IApplication | null>();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    setApplication(currentApplication);
  }, []);
  useEffect(() => {
    setApplication(currentApplication);
  }, [currentApplication]);

  if (currentApplication === undefined || application === null) {
    return null;
  } else if (application !== null && application !== undefined) {
    return (
      <Modal
        closeOnClickOutside={false}
        title={
          "Application Navigator for " +
          application.job.title +
          " " +
          application.job.ref
        }
        size={"70%"}
        opened={opened}
        onClose={() => setCurrentApplication(null)}
        styles={{ title: { fontWeight: "600" } }}
      >
        <Flex direction={"column"} rowGap={15}>
          <Flex direction={"column"} rowGap={5}>
            <Flex align={"center"} columnGap={15}>
              <Avatar size={48} src={application.candidate.picture} />
              <Flex direction={"column"} rowGap={5}>
                <Text size="18px" fw={600}>
                  {application.candidate.name +
                    " " +
                    application.candidate.surname}
                </Text>
                <Text>applied {moment(application.applied_at).fromNow()}</Text>
              </Flex>
            </Flex>
            <Flex align={"center"} wrap={"wrap"} rowGap={15} columnGap={5}>
              <Flex
                miw={"fit-content"}
                style={{ borderRadius: "32px" }}
                px={"xs"}
                py={1}
                fz="13px"
                fw={400}
                bg={"#F4F4F5"}
                c="#1d2124"
              >
                {application.candidate.email_address}
              </Flex>
              <Flex
                miw={"fit-content"}
                style={{ borderRadius: "32px" }}
                px={"xs"}
                py={1}
                fz="13px"
                fw={400}
                bg={"#F4F4F5"}
                c="#1d2124"
              >
                {parseAddress(
                  application.candidate.city,
                  application.candidate.region,
                  application.candidate.country
                )}
              </Flex>
              {application.candidate.phone !== null && (
                <Flex
                  miw={"fit-content"}
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  bg={"#F4F4F5"}
                  c="#1d2124"
                >
                  {application.candidate.phone}
                </Flex>
              )}
              {application.candidate.linkedin !== null && (
                <Flex
                  miw={"fit-content"}
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  bg={"#F4F4F5"}
                  c="#1d2124"
                >
                  <a
                    style={{ textDecoration: "none", color: "#1d2124" }}
                    href={application.candidate.linkedin}
                    target="_blank"
                  >
                    {application.candidate.linkedin}
                  </a>
                </Flex>
              )}
            </Flex>
          </Flex>
          <div style={{ height: "600px" }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                scrollMode={ScrollMode.Vertical}
                enableSmoothScroll
                plugins={[defaultLayoutPluginInstance]}
                theme={{ theme: "auto" }}
                fileUrl={application.candidate.cv_path!}
              />
            </Worker>
          </div>
        </Flex>
      </Modal>
    );
  } else {
    return null;
  }
};

export default ApplicationNavigatorModal;
