import { IEmployer } from "../interfaces/employer";

export const isEmployerCookie = (data: any) => {
  if (data === null || data === undefined || typeof data === "undefined") {
    return false;
  } else {
    let result = true;
    const employerKeys = Array<string>();

    const emp: IEmployer = {
      id: "",
      company_id: "",
      created_at: new Date(),
      email_address: "",
      is_verified: false,
      full_name: "",
      profile_picture: null,
      linkedin: null,
      company: {
        accepted: false,
        address: "",
        cover: null,
        logo: null,
        created_at: new Date(),
        created_by: "",
        id: "",
        linkedin: null,
        name: "",
        slots: 0,
        web_site: "",
      },
    };

    Object.keys(emp).forEach((k, i) => {
      employerKeys.push(k as string);
    });

    Object.keys(JSON.parse(data)).forEach((d, i, []) => {
      if (!employerKeys.includes(d as string)) {
        result = false;
      }
    });

    return result;
  }
};
