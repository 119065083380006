import { createContext, useEffect, useState } from "react";
import { supabase } from "../supabase";
import { IJob } from "../interfaces/job";

export const JobContext = createContext<{
  changed: boolean;
  toggleChanged(): void;
  jobs: IJob[];
}>({
  changed: false,
  jobs: [],
  toggleChanged() {},
});

const JobProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [jobList, setJobList] = useState<IJob[]>([]);
  const [changes, setChanges] = useState<boolean>(false);

  const toggleChanged = () => {
    setChanges(false);
  };

  useEffect(() => {
    supabase
      .channel("jobs-update-channel")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "jobs" },
        (payload) => {
          setChanges(true);
          setJobList(payload.new as IJob[]);
        }
      )
      .subscribe();
  }, []);

  useEffect(() => {
    supabase
      .channel("jobs-insert-channel")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "jobs" },
        (payload) => {
          setChanges(true);
          setJobList(payload.new as IJob[]);
        }
      )
      .subscribe();
  }, []);

  useEffect(() => {
    supabase
      .channel("jobs-delete-channel")
      .on(
        "postgres_changes",
        { event: "DELETE", schema: "public", table: "jobs" },
        (payload) => {
          setChanges(true);
          setJobList(payload.new as IJob[]);
        }
      )
      .subscribe();
  }, []);

  return (
    <JobContext.Provider
      value={{ jobs: jobList, changed: changes, toggleChanged }}
    >
      {children}
    </JobContext.Provider>
  );
};

export default JobProvider;
