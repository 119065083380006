import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import { IconPlayBasketball } from "@tabler/icons-react";
import { Button, Container, Flex, Image, Text } from "@mantine/core";
import TeamContactSummary from "../../components/team/TeamContactSummary";
import { useState } from "react";
import { IEmployer } from "../../interfaces/employer";
import TeamInvitation from "../../modals/TeamInvitation";
import { useDisclosure } from "@mantine/hooks";

const Team = () => {
  const [opened, { toggle }] = useDisclosure();
  const [members, setMembers] = useState<IEmployer[]>([]);
  return (
    <>
      <Helmet>
        <title>Team</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconPlayBasketball />,
          title: "Team",
          description: "Access all your collegues and invite ones if you need.",
        }}
      />
      <TeamInvitation opened={opened} toggle={toggle} />
      <Container size={"xl"}>
        <Flex direction={"column"}>
          {members.length > 0 && <TeamContactSummary count={members.length} />}
          {members.length === 0 && (
            <Flex
              direction={"column"}
              align={"center"}
              justify={"center"}
              h={300}
            >
              <Image mb={15} h={120} w={"auto"} src={"/connect.svg"} />
              <Text mb={5} size="18px" ta={"center"} fw={600}>
                Connect with your collegues
              </Text>
              <Text mb={15}>
                You don't have any team member/collegue for now.
              </Text>
              <Button onClick={toggle} radius={32} color="#C6F16D" c={"#1d2124"}>
                Invite a Team Member
              </Button>
            </Flex>
          )}
        </Flex>
      </Container>
    </>
  );
};

export default Team;
