import { IRoute } from "./interfaces/routes";
import CreateAccount from "./pages/auth/CreateAccount";
import CreatePassword from "./pages/auth/CreatePassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Verify from "./pages/auth/Verify";
import Applications from "./pages/dash/Applications";
import Billings from "./pages/dash/Billings";
import CompanySettings from "./pages/dash/CompanySettings";
import JobAds from "./pages/dash/JobAds";
import JobAdsEdit from "./pages/dash/JobAdsEdit";
import JobAdsNew from "./pages/dash/JobAdsNew";
import Messages from "./pages/dash/Messages";
import Notifications from "./pages/dash/Notifications";
import Purchase from "./pages/dash/Purchase";
import PurchaseCheckout from "./pages/dash/PurchaseCheckout";
import PurchaseCheckoutComplete from "./pages/dash/PurchaseCheckoutComplete";
import TalentPool from "./pages/dash/TalentPool";
import Team from "./pages/dash/Team";
import UserProfile from "./pages/dash/UserProfile";
import UserSettings from "./pages/dash/UserSettings";
import UserSettingsPassword from "./pages/dash/UserSettingsPassword";
import UserSettingsPersonal from "./pages/dash/UserSettingsPersonal";
import UserSettingsProfilePicture from "./pages/dash/UserSettingsProfilePicture";
import UserSettingsSocial from "./pages/dash/UserSettingsSocial";
import Home from "./pages/Home";

export const routes: IRoute[] = [
  { path: "/dashboard", element: <Home /> },
  { path: "/job-ads", element: <JobAds /> },
  { path: "/job-ads/create-new", element: <JobAdsNew /> },
  { path: "/job-ads/edit/:short_code", element: <JobAdsEdit /> },
  { path: "/applications/:short_code", element: <Applications /> },
  { path: "/messages", element: <Messages /> },
  { path: "/notifications", element: <Notifications /> },
  { path: "/talent-pool", element: <TalentPool /> },
  { path: "/team", element: <Team /> },
  { path: "/billing", element: <Billings /> },
  { path: "/settings", element: <CompanySettings /> },
  { path: "/profile", element: <UserProfile /> },
  { path: "/user-settings", element: <UserSettings /> },
  { path: "/user-settings/personal", element: <UserSettingsPersonal /> },
  { path: "/user-settings/social", element: <UserSettingsSocial /> },
  { path: "/user-settings/password", element: <UserSettingsPassword /> },
  {
    path: "/user-settings/profile-picture",
    element: <UserSettingsProfilePicture />,
  },
  { path: "/purchase", element: <Purchase /> },
  { path: "/purchase/checkout/:order_id", element: <PurchaseCheckout /> },
  {
    path: "/purchase/checkout/complete/:order_id",
    element: <PurchaseCheckoutComplete />,
  },
];

export const auths: IRoute[] = [
  { path: "/auth/sign-in", element: <Login /> },
  { path: "/auth/create-account", element: <CreateAccount /> },
  { path: "/auth/create-password/:code", element: <CreatePassword /> },
  { path: "/auth/verify/:code", element: <Verify /> },
  { path: "/auth/recovery", element: <ForgotPassword /> },
  { path: "/auth/sign-out", element: <Logout /> },
];
