import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { IEmployerSocialUpdateRequest } from "../../interfaces/employer";
import { Button, Container, Flex, Text, TextInput } from "@mantine/core";
import {
  IconExclamationCircle,
  IconInfoCircle,
  IconSocial,
} from "@tabler/icons-react";
import PageHeader from "../../components/page/PageHeader";
import { Helmet } from "react-helmet";
import { isHost } from "../../helpers/validation";
import isURL from "validator/lib/isURL";
import { updateEmployer } from "../../services/employer";
import { notifications } from "@mantine/notifications";

const UserSettingsSocial = () => {
  const authContext = useContext(AuthContext);
  const [socialUpdateRequest, setSocialUpdateRequest] = useState<
    IEmployerSocialUpdateRequest
  >({
    linkedin: null,
    linkedin_error: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const setRequestProp = (
    prop: keyof IEmployerSocialUpdateRequest,
    data: any
  ) => {
    setSocialUpdateRequest((prev) => ({ ...prev, [prop]: data }));
  };

  useEffect(() => {
    setRequestProp("linkedin", authContext.employer!.linkedin);
  }, [authContext]);

  const onSave = async () => {
    if (socialUpdateRequest.linkedin === null) {
      setLoading(true);
      const update = await updateEmployer({
        id: authContext.employer!.id,
        linkedin: null,
      });
      setLoading(false);
      if (update.status === true) {
        notifications.show({
          position: "top-right",
          title: "Done!",
          message: "Social Info has been updated",
          icon: <IconInfoCircle color="#007B80" />,
          color: "white",
          styles: {
            root: { backgroundColor: "#1d2124" },
            title: { color: "white" },
            body: { color: "white" },
          },
        });
      } else {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Social Info couldn't update.",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
      }
    } else {
      if (!isURL(socialUpdateRequest.linkedin)) {
        setRequestProp("linkedin_error", "Please provide a valid url.");
      }

      if (!isHost(socialUpdateRequest.linkedin, "linkedin.com")) {
        setRequestProp(
          "linkedin_error",
          "Please provide a valid linkedin profile url."
        );
      }

      if (
        isHost(socialUpdateRequest.linkedin, "linkedin.com") &&
        isURL(socialUpdateRequest.linkedin)
      ) {
        setLoading(true);
        const update = await updateEmployer({
          id: authContext.employer!.id,
          linkedin: null,
        });
        setLoading(false);

        if (update.status === true) {
          authContext.update("linkedin", socialUpdateRequest.linkedin);
          notifications.show({
            position: "top-right",
            title: "Done!",
            message: "Social Info has been updated",
            icon: <IconInfoCircle color="#007B80" />,
            color: "white",
            styles: {
              root: { backgroundColor: "#1d2124" },
              title: { color: "white" },
              body: { color: "white" },
            },
          });
        } else {
          notifications.show({
            position: "top-right",
            title: "Error!",
            message: "Social Info couldn't update.",
            icon: <IconExclamationCircle color="crimson" />,
            color: "white",
            styles: {
              root: { backgroundColor: "crimson" },
              title: { color: "white" },
              body: { color: "white" },
              description: { color: "white" },
            },
          });
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>User Settings - Social</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconSocial />,
          title: "Social Details",
          description: "Update your social platform info.",
        }}
      />
      <Container size={"xl"}>
        <Flex
          style={{ margin: "0 auto" }}
          align={"center"}
          direction={"column"}
          justify={"center"}
        >
          <Text mb={15} size="16px" fw={600}>
            Update Your Social Link
          </Text>
          <TextInput
            error={socialUpdateRequest.linkedin_error}
            onChange={(e) => {
              const data = e.currentTarget.value;
              setRequestProp("linkedin", data === "" ? null : data);
              setRequestProp("linkedin_error", null);
            }}
            label="LinkedIn"
            defaultValue={
              authContext.employer!.linkedin
                ? authContext.employer!.linkedin
                : undefined
            }
            variant="filled"
            placeholder="linkedin profile url"
            w={320}
            radius={0}
            mb={15}
          />
          <Button
            onClick={onSave}
            loading={loading}
            w={320}
            mb={15}
            radius={32}
            color="#1d2124"
            fullWidth
          >
            Save Social Info
          </Button>
        </Flex>
      </Container>
    </>
  );
};

export default UserSettingsSocial;
