import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs";
import { QueueType, queueUrls } from "../data/message";

export const sendMessageToQueue = async (queue: QueueType, payload: any) => {
  const queueUrl = queueUrls[queue];
  const client = new SQSClient({
    region: "eu-central-1",
    credentials: {
      accessKeyId: "AKIAWNHTHD4FZ2LK6AMH",
      secretAccessKey: "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",
    },
  });
  const command = new SendMessageCommand({
    QueueUrl: queueUrl,
    MessageBody: JSON.stringify(payload),
  });
  await client.send(command);
};
