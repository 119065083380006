import { SetStateAction, useEffect, useState } from "react";
import { IApplication, IStage } from "../../interfaces/application";
import { applicationStages } from "../../data/job";
import { ActionIcon, Checkbox, Flex, Menu, Paper, Text } from "@mantine/core";
import moment from "moment";
import {
  IconBan,
  IconDots,
  IconDownload,
  IconExclamationCircle,
  IconEye,
} from "@tabler/icons-react";
import { parseAddress } from "../../helpers/job";
import { updateApplicantStage } from "../../services/applications";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import Loading from "../Loading";
import { useViewportSize } from "@mantine/hooks";

const ApplicationDetail: React.FC<{
  app: IApplication;
  setCurrentState: React.Dispatch<SetStateAction<boolean>>;
  openNavigator: React.Dispatch<SetStateAction<boolean>>;
  setCurrentApplication: React.Dispatch<SetStateAction<IApplication | null>>;
}> = ({ app, setCurrentState, openNavigator, setCurrentApplication }) => {
  const { width } = useViewportSize();
  const [stage, setStage] = useState<IStage>();
  const [loading, setLoading] = useState<boolean>(false);

  const changeStatus = async (status: number) => {
    setLoading(true);
    const response = await updateApplicantStage(app.id, status);
    if (response.status === false) {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "We couldn't update the status of applicant.",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
      setLoading(false);
    } else {
      setTimeout(() => {
        setCurrentState(true);
        setLoading(false);
      }, 1000);
    }
  };

  const openBanModal = () =>
    modals.openConfirmModal({
      title: "Ban/Restrict Candidate",
      children: (
        <Text size="sm">
          Do you really want to ban/restrict candidate? If you proceed, the
          candidate will not be able to apply any job ad of your company.
        </Text>
      ),
      styles: { title: { fontWeight: "600" } },
      labels: { confirm: "Ban/Restrict", cancel: "Cancel" },
      confirmProps: { bg: "crimson", fw: "400" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
    });

  useEffect(() => {
    const s = applicationStages.find((as) => as.stage === app.stage)!;
    setStage(s);
  }, [app]);

  if (stage === undefined) {
    return null;
  } else {
    return (
      <Paper
        radius={12}
        p={"md"}
        style={{ border: `2px solid ${stage.color}` }}
      >
        <Flex w={"100%"} justify={"space-between"} align={"center"}>
          <Flex align={"center"} columnGap={15}>
            <Checkbox
              radius={32}
              color="#007B80"
              style={{ cursor: "pointer" }}
              styles={{ input: { cursor: "pointer" } }}
            />
            <Flex
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrentApplication(app);
              }}
              direction={"column"}
              rowGap={5}
            >
              <Text size="18px" fw={600}>
                {app.candidate.name + " " + app.candidate.surname}
              </Text>
              {width >= 600 && (
                <Flex columnGap={5} wrap={"wrap"} rowGap={15}>
                  <Flex
                    w={"fit-content"}
                    style={{ borderRadius: "32px" }}
                    px={"xs"}
                    py={1}
                    fz="13px"
                    fw={400}
                    bg={"#F4F4F5"}
                    c="#1d2124"
                  >
                    {app.candidate.email_address}
                  </Flex>
                  <Flex
                    w={"fit-content"}
                    style={{ borderRadius: "32px" }}
                    px={"xs"}
                    py={1}
                    fz="13px"
                    fw={400}
                    bg={"#F4F4F5"}
                    c="#1d2124"
                  >
                    {parseAddress(
                      app.candidate.city,
                      app.candidate.region,
                      app.candidate.country
                    )}
                  </Flex>
                </Flex>
              )}
              <Flex wrap={"wrap"} rowGap={5} columnGap={3} align={"center"}>
                <Text>applied for</Text>
                <Text fw={600}>
                  {app.job.title}{" "}
                  {app.job.ref === null ? null : " - (" + app.job.ref + ")"}
                </Text>
              </Flex>
              <Flex columnGap={15} align={"center"} wrap={"wrap"}>
                <Flex
                  columnGap={5}
                  style={{ borderRadius: "32px" }}
                  px={"xs"}
                  py={1}
                  fz="13px"
                  fw={400}
                  bg={stage.color}
                  c={stage.stage > 1 ? "white" : "#1d2124"}
                >
                  {stage.label}
                </Flex>
                <Text>applied on {moment(app.applied_at).fromNow()}</Text>
              </Flex>
            </Flex>
          </Flex>
          <Menu disabled={loading} radius={12} withArrow>
            <Menu.Target>
              <Flex>
                {loading && <Loading />}
                {loading === false && (
                  <ActionIcon color="#1d2124" variant="outline" radius={32}>
                    <IconDots color="#1d2124" />
                  </ActionIcon>
                )}
              </Flex>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item leftSection={<IconDownload size={16} />}>
                Download CV
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item leftSection={<IconEye size={16} />}>
                View Application
              </Menu.Item>
              <Menu.Divider />
              {applicationStages
                .filter((as) => as.stage > 1)
                .map((as, i) => {
                  return (
                    <Menu.Item
                      key={i}
                      onClick={() => {
                        changeStatus(as.stage);
                      }}
                      leftSection={as.icon}
                    >
                      {as.label}
                    </Menu.Item>
                  );
                })}
              <Menu.Divider />
              <Menu.Item
                onClick={openBanModal}
                leftSection={<IconBan size={16} color="crimson" />}
              >
                Restrict Candidate
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Paper>
    );
  }
};

export default ApplicationDetail;
