import { hashPassword } from "../helpers/data";
import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";

export const updateEmployer = (val: any): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data, error } = await supabase
      .from("employers")
      .update(val)
      .eq("id", val.id)
      .select();

    console.log(data, error);

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data[0] });
    } else {
      resolve({ error: "EmployerCouldntUpdate", status: false, result: null });
    }
  });
};

export const checkCurrentPassword = (
  pass: string,
  id: string
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const password = hashPassword(pass);
    const { data } = await supabase
      .from("employers")
      .select("*")
      .eq("id", id)
      .eq("password", password)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: null });
    } else {
      resolve({ error: null, status: false, result: null });
    }
  });
};

export const changePassword = (
  id: string,
  newPass: string,
  currPass:string
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const password = hashPassword(newPass);
    const { data } = await supabase
      .from("employers")
      .update({
        password,
      })
      .eq("id", id)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: null });
    } else {
      resolve({ error: null, status: false, result: null });
    }
  });
};
