import { Anchor, Flex, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { IconArrowRight } from "@tabler/icons-react";

const UserSettingsType: React.FC<{
  title: string;
  href: string;
  alt: string;
}> = ({ title, href, alt }) => {
  const { width } = useViewportSize();
  return (
    <Anchor
      c={"#1d2124"}
      miw={320}
      w={width < 600 ? "100%" : 350}
      href={href}
      underline="never"
    >
      <Flex align={"flex-start"} justify={"space-between"}>
        <Flex direction={"column"} rowGap={10}>
          <Text size="20px" fw={600}>
            {title}
          </Text>
          <Text>{alt}</Text>
        </Flex>
        <IconArrowRight />
      </Flex>
    </Anchor>
  );
};

export default UserSettingsType;
