import { Burger, Center, Divider, Flex } from "@mantine/core";
import { navbarMenuItems } from "../../data/navbar";
import NavbarMenuItem from "./NavbarMenuItem";
import {
  IconLogout,
  IconReceipt,
  IconSettings,
  IconSettings2,
  IconUserCircle,
} from "@tabler/icons-react";
import Logo from "../Logo";

const Navbar: React.FC<{ opened: boolean; toggle(): void }> = ({
  opened,
  toggle,
}) => {
  return (
    <Flex rowGap={3} pt={5} direction={"column"}>
      <Center visibleFrom="sm" mb={"md"}>
        <Logo href="/dashboard" />
      </Center>
      <Flex mb={10} align={"center"} justify={"space-between"} hiddenFrom="sm">
        <Logo href="/dashboard" />
        <Burger hiddenFrom="sm" opened={opened} onClick={toggle} size={"sm"} />
      </Flex>
      {navbarMenuItems.map((n, i) => {
        return <NavbarMenuItem key={i} item={n} />;
      })}
      <Divider />
      <NavbarMenuItem
        item={{
          icon: <IconReceipt />,
          title: "Billing",
          href: "/billing",
          iconActive: <IconReceipt color="#007B80" />,
        }}
      />
      <NavbarMenuItem
        item={{
          title: "Company Settings",
          icon: <IconSettings />,
          href: "/settings",
          iconActive: <IconSettings color="#007B80" />,
        }}
      />
      <Divider />
      <NavbarMenuItem
        item={{
          icon: <IconUserCircle />,
          title: "User Profile",
          href: "/profile",
          iconActive: <IconUserCircle color="#007B80" />,
        }}
      />
      <NavbarMenuItem
        item={{
          title: "User Settings",
          icon: <IconSettings2 />,
          href: "/user-settings",
          iconActive: <IconSettings2 color="#007B80" />,
        }}
      />
      <Divider />
      <NavbarMenuItem
        item={{
          icon: <IconLogout />,
          title: "Logout",
          href: "/auth/sign-out",
          iconActive: <IconLogout color="#007B80" />,
        }}
      />
    </Flex>
  );
};

export default Navbar;
