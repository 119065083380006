import { ActionIcon, Flex, Paper, Text, Tooltip } from "@mantine/core";
import { IBillingItem } from "../../interfaces/checkout";
import { ICompany } from "../../interfaces/company";
import moment from "moment";
import { IconDownload } from "@tabler/icons-react";
import { useViewportSize } from "@mantine/hooks";

const BillingListItem: React.FC<{ order: IBillingItem; company: ICompany }> = ({
  order,
  company,
}) => {
  const { width } = useViewportSize();
  return (
    <Paper
      className="billing-item"
      radius={12}
      style={{ border: "2px solid #E7ECEE" }}
      p={"sm"}
      w={"100%"}
    >
      <Flex align={"center"} justify={"space-between"}>
        <Flex direction={"column"} rowGap={10}>
          <Text fw={600} size="18px" c={"#9c195b"}>
            {order.slot_count} Job Slots
          </Text>
          <Flex wrap={"wrap"} rowGap={5} align={"center"} columnGap={3}>
            <Text>
              on{" "}
              <u>{moment(order.created_at).format("MMMM Do YYYY HH:mm a")}</u>
            </Text>

            <Text>
              by&nbsp;
              <strong>
                {width < 600 && order.full_name.length > 35
                  ? order.full_name.substring(0, 33) + "..."
                  : order.full_name}
              </strong>
            </Text>
          </Flex>
        </Flex>
        <Tooltip label="Download Invoice" position="left" withArrow>
          <ActionIcon size={"lg"} color="#DBE2E6" radius={32}>
            <IconDownload size={18} stroke={2.5} color="#1d2124" />
          </ActionIcon>
        </Tooltip>
      </Flex>
    </Paper>
  );
};

export default BillingListItem;
