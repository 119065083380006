import cookies from "js-cookie";
import { createContext, SetStateAction, useEffect, useState } from "react";
import { supabase } from "../supabase";
import { getAllApplications } from "../services/applications";
import { isEmployerCookie } from "../helpers/auth";
import { IEmployer } from "../interfaces/employer";
import { IStage } from "../interfaces/application";
import { applicationStages } from "../data/job";

export const ApplicationsContext = createContext<{
  applications: any[];
  currentStage: IStage;
  setCurrentStage: React.Dispatch<SetStateAction<IStage>>;
}>({
  applications: [],
  currentStage: applicationStages[0],
  setCurrentStage: () => {},
});

const ApplicationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentStage, setCurrentStage] = useState<IStage>(
    applicationStages[0]
  );
  const [applicationsList, setApplicationList] = useState<any[]>([]);
  const [changes, setChanges] = useState<boolean>(false);

  useEffect(() => {
    supabase
      .channel("applications-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "applications" },
        () => {
          setChanges(true);
        }
      )
      .subscribe();
  }, []);

  useEffect(() => {
    if (changes === true) {
      const cookie = cookies.get("employer");
      if (isEmployerCookie(cookie)) {
        const emp = JSON.parse(cookie!) as IEmployer;
        setTimeout(async () => {
          const getApplications = await getAllApplications(emp.company.id);
          const newList = Array.from(getApplications.result).sort(
            (a: any, b: any) => {
              return (
                new Date(b.applied_at).getTime() -
                new Date(a.applied_at).getTime()
              );
            }
          );
          setApplicationList(newList);
          setChanges(false);
        }, 5);
      }
    }
  }, [changes]);

  useEffect(() => {
    const cookie = cookies.get("employer");
    if (isEmployerCookie(cookie)) {
      const emp: IEmployer = JSON.parse(cookie!) as IEmployer;
      console.log(emp.company);
      setTimeout(async () => {
        const getApplications = await getAllApplications(emp.company.id);
        const newList = Array.from(getApplications.result).sort(
          (a: any, b: any) => {
            return (
              new Date(b.applied_at).getTime() -
              new Date(a.applied_at).getTime()
            );
          }
        );
        setApplicationList(newList);
        setChanges(false);
      }, 5);
    }
  }, []);

  return (
    <ApplicationsContext.Provider
      value={{
        currentStage,
        applications: applicationsList,
        setCurrentStage,
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  );
};

export default ApplicationsProvider;
