import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import { IconSettings2 } from "@tabler/icons-react";
import { Container, Flex, Grid, Image } from "@mantine/core";
import UserSettingsType from "../../components/user/UserSettingsType";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";

const UserSettings = () => {
  const authContext = useContext(AuthContext);
  return (
    <>
      <Helmet>
        <title>User Settings</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconSettings2 />,
          title: "User Settings",
          description:
            "Update personal details like name, email, phone number, and password securely.",
        }}
      />
      <Container size={"xl"}>
        <Grid>
          <Grid.Col span={{ xl: 8, lg: 8, md: 8, sm: 12, xs: 12 }}>
            <Flex
              wrap={"wrap"}
              align={"center"}
              rowGap={20}
              justify={"space-between"}
              mb={30}
            >
              <UserSettingsType
                title="Personal"
                href="/user-settings/personal"
                alt={authContext.employer!.full_name}
              />
              <UserSettingsType
                title="Password"
                href="/user-settings/password"
                alt="********"
              />
              <UserSettingsType
                title="Social"
                href="/user-settings/social"
                alt="LinkedIn"
              />
              <UserSettingsType
                title="Profile Picture"
                href="/user-settings/profile-picture"
                alt="Update/remove your profile picture."
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, xs: 12 }}>
            <Image src={"/user.svg"} />
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default UserSettings;
