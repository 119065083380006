import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import { IconBriefcase } from "@tabler/icons-react";
import JobsHeader from "../../components/jobs/JobsHeader";
import { Checkbox, Container, Flex, Text, TextInput } from "@mantine/core";
import { useContext, useEffect, useRef, useState } from "react";
import { IJobListItem, JobFilter } from "../../interfaces/job";
import { AuthContext } from "../../contexts/AuthProvider";
import { getJobListByCompanyId } from "../../services/job";
import Loading from "../../components/Loading";
import JobListItem from "../../components/jobs/JobListItem";
import { jobFilter } from "../../data/job";
import { JobContext } from "../../contexts/JobProvider";
import { useViewportSize } from "@mantine/hooks";
import { modals } from "@mantine/modals";

const JobAds = () => {
  const { width } = useViewportSize();
  const jobContext = useContext(JobContext);
  const authContext = useContext(AuthContext);
  const [jobAds, setJobAds] = useState<IJobListItem[]>([]);
  const [jobAdList, setJobAdList] = useState<IJobListItem[]>([]);
  const [filter, setFilter] = useState<JobFilter>("all");
  const [loading, setLoading] = useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [selectedJobAds, setSelectedJobAds] = useState<IJobListItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState<number>(0);

  const selectJobAd = (id: string, selected: boolean) => {
    if (selected) {
      const job = jobAds.find((j) => j.id === id)!;
      setSelectedJobAds([...selectedJobAds, job]);
    } else {
      const selectedList = selectedJobAds.filter((j) => j.id !== id);
      setSelectedJobAds(selectedList);
    }
  };

  const remove = (code: string) => {
    setJobAds(jobAds.filter((j) => j.short_code !== code));
    setJobAdList(jobAdList.filter((j) => j.short_code !== code));
    const sj = selectedJobAds.filter((j) => j.short_code !== code);
    setSelectedJobAds(sj);
  };

  useEffect(() => {
    setTimeout(async () => {
      const getJobs = await getJobListByCompanyId(
        authContext.employer!.company.id
      );
      setJobAds(getJobs.result);
      setJobAdList(
        getJobs.result.sort((a: IJobListItem, b: IJobListItem) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        })
      );
      setLoading(false);
    }, 10);
  }, []);

  useEffect(() => {
    setPage(0);
    if (filter === "all") {
      searchInputRef.current!.value = "";
      setSearchTerm(null);
      setJobAdList(
        jobAds.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        })
      );
    } else if (filter !== "search" && filter !== "ready") {
      const status = jobFilter.find((f) => f.label === filter.toString())!
        .filter;
      const newJobList = jobAds
        .filter((f) => f.status === status)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
      setJobAdList(newJobList);
    } else if (filter === "ready") {
      const newJobList = jobAds
        .filter((f) => f.ready === true && f.status === 0)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
      setJobAdList(newJobList);
    }
    setSelectedJobAds([]);
  }, [filter]);

  useEffect(() => {
    if (searchTerm !== null) {
      setFilter("search");
      const newJobList = jobAds
        .filter(
          (f) =>
            f.title.toLowerCase().includes(searchTerm) ||
            (f.short_code !== null && f.short_code.includes(searchTerm))
        )
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
      setJobAdList(newJobList);
    } else {
      searchInputRef.current!.value = "";
      setFilter("all");
    }
  }, [searchTerm]);

  useEffect(() => {
    if (filter === "all") {
      setJobAdList(
        jobAds.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        })
      );
    } else {
      const status = jobFilter.find((f) => f.label === filter.toString())!
        .filter;
      const newJobList = jobAds
        .filter((f) => f.status === status)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
      setJobAdList(newJobList);
    }
  }, [jobAds]);

  useEffect(() => {
    if (jobContext.changed === true) {
      setTimeout(async () => {
        const getJobs = await getJobListByCompanyId(
          authContext.employer!.company.id
        );
        setJobAds(getJobs.result);
        jobContext.toggleChanged();
      }, 10);
    }
  }, [jobContext.changed]);

  return (
    <>
      <Helmet>
        <title>Job Ads</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconBriefcase />,
          title: "Job Ads",
          description: "Here you'll find everything you need for your job ads.",
        }}
        right={
          <TextInput
            ref={searchInputRef}
            onChange={(e) => {
              const data = e.currentTarget.value;
              setSearchTerm(data.length === 0 ? null : data.toLowerCase());
              if (data.length === 0) {
                setFilter("all");
              }
            }}
            placeholder="search for jobs by id or name"
            w={width < 600 ? "100%" : 400}
          />
        }
      />
      <JobsHeader
        clearSelected={setSelectedJobAds}
        selecteds={selectedJobAds}
        clearSearch={setSearchTerm}
        search={searchTerm}
        filter={filter}
        setFilter={setFilter}
        setUpdateLoading={setUpdateLoading}
      />
      <Container py={"lg"} size={"md"}>
        {loading === true && (
          <Flex align={"center"} justify={"center"}>
            <Loading />
          </Flex>
        )}
        <Flex direction={"column"} rowGap={15}>
          {filter !== "all" && jobAdList.length > 0 && (
            <Checkbox
              disabled={updateLoading}
              checked={selectedJobAds.length === jobAdList.length}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  setSelectedJobAds(jobAdList);
                } else {
                  setSelectedJobAds([]);
                }
              }}
              label="Select All"
              radius={32}
              color="#007B80"
              style={{ cursor: "pointer" }}
              styles={{
                input: { cursor: "pointer" },
                label: { cursor: "pointer" },
              }}
            />
          )}
          {jobAdList.length > 0 &&
            jobAdList.map((j, i) => {
              return (
                <JobListItem
                setJobLoading={setUpdateLoading}
                  setSelected={selectJobAd}
                  filter={filter}
                  remove={remove}
                  key={i}
                  job={j}
                  isSelected={
                    selectedJobAds.find((f) => f.id === j.id) !== undefined
                  }
                />
              );
            })}
        </Flex>

        {loading === false && jobAdList.length === 0 && (
          <Flex
            rowGap={5}
            align={"center"}
            justify={"center"}
            direction={"column"}
          >
            <IconBriefcase />
            <Text>
              No{" "}
              {filter === "all"
                ? null
                : filter[0].toUpperCase() + filter.slice(1)}{" "}
              Jobs!
            </Text>
          </Flex>
        )}
      </Container>
    </>
  );
};

export default JobAds;
