import { Anchor, Flex } from "@mantine/core";

const AuthFooter = () => {
  return (
    <Flex columnGap={15} align={"center"} justify={"center"}>
      <Anchor href="https://oilandgastalent.com" target="_blank" c={"#0698a0"}>
        Main Site
      </Anchor>
      <Anchor target="_blank" c={"#0698a0"}>
        Terms & Conditions
      </Anchor>
      <Anchor target="_blank" c={"#0698a0"}>
        Privacy Policy
      </Anchor>
    </Flex>
  );
};

export default AuthFooter;
