import { Button, Paper } from "@mantine/core";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { FormEvent, useState } from "react";
import { IOrder } from "../../interfaces/checkout";

const CheckoutForm: React.FC<{ order: IOrder }> = ({ order }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          "http://localhost:3000/purchase/checkout/complete/" + order.code,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message!);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <Paper>
        <form
          id="payment-form"
          style={{ width: "100%" }}
          onSubmit={handleSubmit}
        >
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
            className="payment-elements"
          />

          <Button
            mt={15}
            onClick={handleSubmit}
            loading={isLoading}
            id="submit"
            disabled={isLoading || !stripe || !elements}
            fullWidth
            color="#007AFF"
          >
            Pay Now
          </Button>

          {message && <div id="payment-message">{message}</div>}
        </form>
      </Paper>
    </>
  );
};

export default CheckoutForm;
