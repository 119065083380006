import { Anchor, Badge, Flex, Text, UnstyledButton } from "@mantine/core";
import { INavbarMenuItem } from "../../interfaces/navbar";
import { useLocation } from "react-router-dom";

const NavbarMenuItem: React.FC<{ item: INavbarMenuItem }> = ({ item }) => {
  const location = useLocation();

  return (
    <Anchor href={item.href} underline="never">
      <UnstyledButton
        className={
          location.pathname.startsWith(item.href)
            ? "navbar-menu-item active"
            : "navbar-menu-item"
        }
      >
        <Flex align={"center"} justify={"space-between"}>
          <Flex align={"center"} columnGap={5}>
            {location.pathname.startsWith(item.href)
              ? item.iconActive
              : item.icon}
            <Text
              className="navbar-menu-item-text"
              c={
                location.pathname.startsWith(item.href) ? "#007B80" : "#1d2124"
              }
            >
              {item.title}
            </Text>
          </Flex>
          {item.nCount && (
            <Badge fw={400} bg={"#007b80"} c={"white"}>
              {item.nCount}
            </Badge>
          )}
        </Flex>
      </UnstyledButton>
    </Anchor>
  );
};

export default NavbarMenuItem;
