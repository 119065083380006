import { Anchor, Avatar, Flex, Text, UnstyledButton } from "@mantine/core";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";

const NavbarUser = () => {
  const authContext = useContext(AuthContext);

  return (
    <Anchor mb={-5} c={"#1d2124"} href="/profile" underline="never">
      <UnstyledButton visibleFrom="sm">
        <Flex align={"center"} columnGap={10}>
          <Avatar
            radius={8}
            size={48}
            src={authContext.employer!.profile_picture}
          />
          <Flex direction={"column"} rowGap={5}>
            <Text fw={600} size="16px">
              {authContext.employer!.full_name}
            </Text>
            <Text>
              {authContext.employer!.email_address.length > 27
                ? authContext.employer!.email_address.substring(0, 24) + "..."
                : authContext.employer!.email_address}
            </Text>
          </Flex>
        </Flex>
      </UnstyledButton>
    </Anchor>
  );
};

export default NavbarUser;
