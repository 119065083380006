import {
  Anchor,
  Button,
  Flex,
  Image,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import AuthFooter from "../../components/auth/AuthFooter";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useState } from "react";
import { IEmployerSignUpRequest } from "../../interfaces/employer";
import { IResult } from "../../interfaces/result";
import { isBusinessEmail } from "../../helpers/validation";
import { isEmail } from "validator";
import { signup } from "../../services/auth";
import { IconMailFilled } from "@tabler/icons-react";
import isURL from "validator/lib/isURL";
import { createCompany } from "../../services/company";
import { updateEmployer } from "../../services/employer";

const CreateAccount = () => {
  const { width } = useViewportSize();
  const [createAccountRequest, setCreateAccountRequest] = useState<
    IEmployerSignUpRequest
  >({
    company_name: null,
    company_name_error: null,
    company_address: null,
    company_address_error: null,
    company_website: null,
    company_website_error: null,
    email_address: null,
    email_address_error: null,
    full_name: null,
    full_name_error: null,
    password: null,
    password_error: null,
  });
  const [result, setResult] = useState<IResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const setRequestProp = (prop: keyof IEmployerSignUpRequest, data: any) => {
    setCreateAccountRequest((prev) => ({ ...prev, [prop]: data }));
  };

  const onSignUp = async () => {
    if (
      createAccountRequest.email_address === null ||
      !isEmail(createAccountRequest.email_address)
    ) {
      setRequestProp(
        "email_address_error",
        "Please provide a valid email address."
      );
    }

    let emailData = null;

    if (createAccountRequest.email_address !== null) {
      emailData = await isBusinessEmail(createAccountRequest.email_address!);
      if (!emailData) {
        setRequestProp(
          "email_address_error",
          "Please provide a valid business email address."
        );
      }
    }

    if (
      createAccountRequest.company_name === null ||
      createAccountRequest.company_name.length < 3
    ) {
      setRequestProp(
        "company_name_error",
        "Please provide a valid company name."
      );
    }

    if (
      createAccountRequest.company_website === null ||
      !isURL(createAccountRequest.company_website)
    ) {
      setRequestProp(
        "company_website_error",
        "Please provide a valid company website url."
      );
    }

    if (
      createAccountRequest.company_address === null ||
      createAccountRequest.company_address.length < 5
    ) {
      setRequestProp(
        "company_address_error",
        "Please provide a valid company address."
      );
    }

    if (
      createAccountRequest.password === null ||
      createAccountRequest.password.length < 8
    ) {
      setRequestProp(
        "password_error",
        "Password must includes at least 8 chars with one upper/lowercase letter, one number and one special char. "
      );
    }

    if (
      emailData !== null &&
      emailData &&
      isEmail(createAccountRequest.email_address!) &&
      createAccountRequest.password !== null &&
      createAccountRequest.password.length > 7 &&
      createAccountRequest.company_address !== null &&
      createAccountRequest.company_address.length > 4 &&
      createAccountRequest.company_name !== null &&
      createAccountRequest.company_name.length > 2 &&
      createAccountRequest.company_website !== null &&
      isURL(createAccountRequest.company_website)
    ) {
      setLoading(true);
      const signUpResponse = await signup(createAccountRequest);

      if (signUpResponse.status === true) {
        const companyProfile = await createCompany(
          createAccountRequest.company_name,
          createAccountRequest.company_website,
          createAccountRequest.company_address,
          signUpResponse.result.id
        );
        if (companyProfile.status === true) {
          const up = await updateEmployer({
            id: signUpResponse.additional.id,
            company_id: companyProfile.result.id,
          });

          if (up.status === true) {
            setResult({
              error: null,
              status: true,
              result: "VerificationEmailSent",
            });
          } else {
            setResult(up);
          }
        } else {
          setResult(companyProfile);
        }

        setLoading(false);
      } else {
        setResult(signUpResponse);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Account</title>
      </Helmet>
      <Flex
        align={"center"}
        py={30}
        direction={"column"}
        h={"100%"}
        justify={"space-between"}
      >
        <Logo href="/" />
        {result !== null && result.status === true && (
          <Flex mt={15} align={"center"} direction={"column"} rowGap={15}>
            <IconMailFilled />
            <Text fw={500} maw={400} ta={"center"}>
              Verification email has been sent. Please verify your email address
              to sign into your account.
            </Text>
          </Flex>
        )}
        {(result === null || result.status === false) && (
          <Paper bg={"transparent"} w={320} p={"md"} radius={8} mt={15}>
            <Text ta={"center"} mb={5} size="20px" fw={700}>
              Create Account
            </Text>
            <Text ta={"center"} mb={15}>
              Already joined?{" "}
              <Anchor href="/auth/sign-in" c={"#0698a0"}>
                Sign In
              </Anchor>
            </Text>
            {result !== null &&
              result.status === false &&
              result.error === "DuplicateAccount" && (
                <Text mb={15} c="crimson" ta={"center"}>
                  Duplicate Email Address
                </Text>
              )}
            {result !== null &&
              result.status === false &&
              result.error === "CannotCreateAccount" && (
                <Text mb={15} c="crimson" ta={"center"}>
                  Something went wrong! Please refresh the page and try again!
                </Text>
              )}
            <TextInput
              required
              disabled={loading}
              variant="filled"
              radius={0}
              label="Full Name"
              placeholder="full name"
              mb={15}
              error={createAccountRequest.full_name_error}
              onChange={(e) => {
                setRequestProp("full_name", e.currentTarget.value);
                setRequestProp("full_name_error", null);
                setResult(null);
              }}
            />
            <TextInput
              required
              disabled={loading}
              variant="filled"
              radius={0}
              label="Email Address"
              placeholder="business email"
              mb={15}
              error={createAccountRequest.email_address_error}
              onChange={(e) => {
                setRequestProp("email_address", e.currentTarget.value);
                setRequestProp("email_address_error", null);
                setResult(null);
              }}
            />
            <TextInput
              description="Password must includes at least 8 chars with one upper/lowercase letter, one number and one special char. "
              required
              disabled={loading}
              type="password"
              variant="filled"
              radius={0}
              label="Password"
              placeholder="enter password"
              mb={15}
              error={createAccountRequest.password_error}
              onChange={(e) => {
                setRequestProp("password", e.currentTarget.value);
                setRequestProp("password_error", null);
                setResult(null);
              }}
            />

            <TextInput
              required
              disabled={loading}
              variant="filled"
              radius={0}
              label="Website"
              placeholder="company website url"
              mb={15}
              error={createAccountRequest.company_website_error}
              onChange={(e) => {
                setRequestProp("company_website", e.currentTarget.value);
                setRequestProp("company_website_error", null);
                setResult(null);
              }}
            />
            <TextInput
              required
              disabled={loading}
              variant="filled"
              radius={0}
              label="Company Name"
              placeholder="company name"
              mb={15}
              error={createAccountRequest.company_name_error}
              onChange={(e) => {
                setRequestProp("company_name", e.currentTarget.value);
                setRequestProp("company_name_error", null);
                setResult(null);
              }}
            />
            <TextInput
              required
              disabled={loading}
              variant="filled"
              radius={0}
              label="Company Address"
              placeholder="company address"
              mb={15}
              error={createAccountRequest.company_address_error}
              onChange={(e) => {
                setRequestProp("company_address", e.currentTarget.value);
                setRequestProp("company_address_error", null);
                setResult(null);
              }}
            />
            <Button
              onClick={onSignUp}
              loading={loading}
              mb={15}
              radius={32}
              color="#1d2124"
              fullWidth
            >
              Create Account
            </Button>
          </Paper>
        )}
        {width < 700 && <Image src={"/people.svg"} w={200} h={200} />}
        <AuthFooter />
      </Flex>
    </>
  );
};

export default CreateAccount;
