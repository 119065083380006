import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IJobAdRequest, IJobEditRequest } from "../../interfaces/job";
import { getJobByShortCode, saveJobAd } from "../../services/job";
import { notifications } from "@mantine/notifications";
import {
  IconBriefcase,
  IconCheck,
  IconExclamationCircle,
  IconInfoCircle,
} from "@tabler/icons-react";
import { Button, Flex } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import Loading from "../../components/Loading";
import JobEditForm from "../../components/jobs/JobEditForm";
import JobAdPreview from "../../components/jobs/JobAdPreview";
import { isReadyForPublish } from "../../helpers/job";

const JobAdsEdit = () => {
  const { width } = useViewportSize();
  const [jobEditRequest, setJobEditRequest] = useState<IJobEditRequest>({
    id: null,
    title: null,
    titleError: null,
    level: null,
    levelError: null,
    category: null,
    categoryError: null,
    workplace: null,
    workplaceError: null,
    type: null,
    typeError: null,
    description: null,
    descriptionError: null,
    address: null,
    addressError: null,
    is_external: false,
    external_url: null,
    externalUrlError: null,
    is_salary_visible: false,
    min_salary: null,
    minSalaryError: null,
    max_salary: null,
    maxSalaryError: null,
    salary_currency: null,
    salaryCurrencyError: null,
    status: 0,
    company_id: null,
    view_count: 0,
    application_count: 0,
    created_by: null,
    created_at: 0,
    short_code: null,
    published_at: null,
    ready: false,
    ref: null,
  });
  const [draftLoading, setDraftLoading] = useState<boolean>(false);
  const [publishLoading, setPublishLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams<{ short_code: string }>();
  const navigate = useNavigate();

  const setRequestProp = (prop: keyof IJobEditRequest, data: any) => {
    setJobEditRequest((prev) => ({ ...prev, [prop]: data }));
  };

  const editJob = async () => {
    if (jobEditRequest.title === null || jobEditRequest.title.length < 5) {
      setRequestProp(
        "titleError",
        "Please provide a title that has at least 5 chars."
      );
    }

    const { ready, request } = isReadyForPublish(jobEditRequest, "draft");

    let newJobAdRequest = request;
    newJobAdRequest = { ...newJobAdRequest, ["ready"]: ready };

    if (newJobAdRequest.title !== null && newJobAdRequest.title.length > 4) {
      setDraftLoading(true);
      const save = await saveJobAd(newJobAdRequest as IJobEditRequest);
      setDraftLoading(false);
      if (save.status === true) {
        notifications.show({
          position: "top-right",
          title: "Done!",
          message: `${jobEditRequest.title} has been saved as draft!`,
          icon: <IconInfoCircle color="#007B80" />,
          color: "white",
          styles: {
            root: { backgroundColor: "#1d2124" },
            title: { color: "white" },
            body: { color: "white" },
          },
        });
      } else {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Something went wrong during saving job ad.",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (typeof params !== "undefined" && params.short_code !== null) {
      setTimeout(async () => {
        const jobDetails = await getJobByShortCode(params.short_code!);
        if (jobDetails.result === null) {
          notifications.show({
            position: "top-right",
            title: "Error!",
            message: "We couldn't find the job ad.",
            icon: <IconExclamationCircle color="crimson" />,
            color: "white",
            styles: {
              root: { backgroundColor: "crimson" },
              title: { color: "white" },
              body: { color: "white" },
              description: { color: "white" },
            },
          });
          navigate("/job-ads");
        }
        if (jobDetails.result.status === 1) {
          navigate("/job-ads");
        } else {
          Object.entries(jobDetails.result!).forEach((entry) => {
            setRequestProp(entry[0] as keyof IJobEditRequest, entry[1]);
          });

          const addressParts: string[] = [];
          if (jobDetails.result.city !== null) {
            addressParts.push(jobDetails.result.city);
            if (jobDetails.result.region !== null) {
              addressParts.push(jobDetails.result.region);
            }
            addressParts.push(jobDetails.result.country);
            setRequestProp("address", addressParts.join(", "));
          }
          setLoading(false);
        }
      }, 10);
    }
  }, [params]);

  if (loading) {
    return (
      <Flex
        px={"xl"}
        align={"center"}
        justify={"center"}
        py={"xl"}
        columnGap={30}
      >
        <Loading />
      </Flex>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>{jobEditRequest.title} - Edit Job Ad</title>
        </Helmet>
        <PageHeader
          h={
            width < 800
              ? {
                  icon: <IconBriefcase />,
                  title: "Edit Job Ad",
                  description: "",
                }
              : {
                  icon: <IconBriefcase />,
                  title: "Edit Job Ad",
                  description:
                    "Craft compelling job postings that attract the best candidates.",
                }
          }
          right={
            <>
              <Flex
                w={width < 600 ? "100%" : "fit-content"}
                align={"center"}
                columnGap={15}
              >
                <Button
                  disabled={draftLoading || publishLoading}
                  loading={draftLoading}
                  fw={400}
                  radius={32}
                  color="#1d2124"
                  c={"white"}
                  fullWidth={width < 600}
                  onClick={editJob}
                >
                  Save as Draft
                </Button>
                <Button
                  disabled={draftLoading || publishLoading}
                  loading={publishLoading}
                  fw={400}
                  radius={32}
                  color="#0698A0"
                  c={"white"}
                  fullWidth={width < 600}
                  rightSection={<IconCheck size={16} />}
                >
                  Publish
                </Button>
              </Flex>
            </>
          }
        />
        <Flex px={"xl"} align={"flex-start"} columnGap={30}>
          <JobEditForm request={jobEditRequest} setRequest={setRequestProp} />
          {width > 1500 && <JobAdPreview job={jobEditRequest} />}
        </Flex>
      </>
    );
  }
};

export default JobAdsEdit;
