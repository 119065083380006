import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import {
  IconDots,
  IconExclamationCircle,
  IconPencil,
  IconSettings,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Box,
  Container,
  FileButton,
  Flex,
  Image,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import CompanyProfileUpdateModal from "../../modals/CompanyProfileUpdateModal";
import { notifications } from "@mantine/notifications";
import { removeFromStorage, uploadToStorage } from "../../services/storage";
import { updateCompany } from "../../services/company";

const CompanySettings = () => {
  const authContext = useContext(AuthContext);
  const [isSame, setIsSame] = useState<boolean>(() => {
    return authContext.employer!.id === authContext.employer!.id;
  });
  const [opened, { toggle }] = useDisclosure();

  const [logo, setLogo] = useState<File | null>(null);
  const [logoLoading, setLogoLoading] = useState<boolean>(false);
  const [logoRemoveLoading, setRemoveLogoLoading] = useState<boolean>(false);
  const [logoSize, setLogoSize] = useState<"none" | "contain" | "inherit">(
    "contain"
  );

  const logoRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (logoRef.current!.src) {
      if (logoRef.current!.naturalWidth > 128) {
        setLogoSize("contain");
      } else {
        setLogoSize("none");
      }
    }
  }, [logoRef.current!]);

  useEffect(() => {
    if (logoRef.current!.naturalWidth > 128) {
      setLogoSize("contain");
    } else {
      setLogoSize("none");
    }
  }, []);

  const selectLogo = async (f: File | null) => {
    if (f !== null) {
      if (!["image/png", "image/jpg", "image/jpeg"].includes(f.type)) {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Only PNG, JPG and JPEG file types are allowed!",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
      } else {
        setLogoLoading(true);
        const uploadLogo = await uploadToStorage(
          "company_logos",
          f,
          authContext.employer!.company.logo
        );
        setLogoLoading(false);
        if (uploadLogo.status === false) {
          notifications.show({
            position: "top-right",
            title: "Error!",
            message: "Logo couldn't uploaded!",
            icon: <IconExclamationCircle color="crimson" />,
            color: "white",
            styles: {
              root: { backgroundColor: "crimson" },
              title: { color: "white" },
              body: { color: "white" },
              description: { color: "white" },
            },
          });
        } else {
          const comp = {
            ...authContext.employer!.company,
            ["logo"]: uploadLogo.result,
          };
          authContext.update("company", comp);
          logoRef.current!.src = uploadLogo.result;
          await updateCompany({
            id: authContext.employer!.company.id,
            logo: uploadLogo.result,
          });
        }
      }
    } else {
      setLogo(null);
    }
  };

  const removeLogo = async () => {
    setRemoveLogoLoading(true);
    const removeCompanyLogo = await removeFromStorage(
      "company_logos",
      authContext.employer!.company.logo!
    );
    setRemoveLogoLoading(false);

    if (removeCompanyLogo.status === false) {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "Logo couldn't deleted!",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
    } else {
      const comp = {
        ...authContext.employer!.company,
        ["logo"]: null,
      };
      authContext.update("company", comp);
      await updateCompany({
        id: authContext.employer!.company.id,
        logo: null,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Company Settings</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconSettings />,
          title: "Company Settings",
          description:
            "Manage your company setting and create company profile page.",
        }}
      />
      <CompanyProfileUpdateModal opened={opened} toggle={toggle} />
      <Container size={"md"}>
        <Flex
          align={"center"}
          justify={"space-between"}
          p={"md"}
          h={200}
          direction={"row"}
          rowGap={5}
          mb={5}
          style={
            authContext.employer!.company.cover !== null
              ? {
                  backgroundImage: `url(${
                    authContext.employer!.company.cover
                  })`,
                  backgroundSize: "cover",
                  borderRadius: "12px",
                }
              : {
                  backgroundSize: "cover",
                  backgroundImage: `url("/profile-header.png")`,
                  borderRadius: "12px",
                }
          }
        >
          <Flex align={"flex-start"} columnGap={5}>
            <Flex
              align={"center"}
              justify={"center"}
              bg={"white"}
              w={132}
              h={132}
              style={{ borderRadius: "12px" }}
            >
              <Image
                ref={logoRef}
                fit={logoSize}
                src={
                  authContext.employer!.company.logo === null
                    ? "/default-logo.png"
                    : authContext.employer!.company.logo
                }
                w={128}
                h={128}
              />
            </Flex>
            <Flex direction={"column"} justify={"space-between"} h={132}>
              {authContext.employer!.company.logo !== null && (
                <ActionIcon
                  onClick={removeLogo}
                  disabled={logoLoading || logoRemoveLoading}
                  radius={32}
                  variant="transparent"
                >
                  <IconTrash color="crimson" size={16} />
                </ActionIcon>
              )}
              {authContext.employer!.company.logo === null && <p>&nbsp;</p>}
              <FileButton
                accept="image/png, image/jpg, image/jpeg"
                onChange={selectLogo}
              >
                {(props) => (
                  <ActionIcon
                    {...props}
                    disabled={logoLoading || logoRemoveLoading}
                    radius={32}
                    variant="transparent"
                  >
                    <IconUpload color="#1d2124" size={16} />
                  </ActionIcon>
                )}
              </FileButton>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          w={"100%"}
          direction={"row"}
          mb={50}
          columnGap={15}
          align={"flex-start"}
        >
          <Flex w={"100%"} direction={"column"} rowGap={5}>
            <Text size="24px" fw={600}>
              {authContext!.employer?.company.name}
            </Text>

            <Text>{authContext.employer!.company.address}</Text>

            <Flex align={"center"} columnGap={15} wrap={"wrap"}>
              {authContext.employer!.company.linkedin !== null && (
                <Badge bg={"#CAD3E2"} c={"#1d2124"} radius={3}>
                  <Anchor
                    target="_blank"
                    href={authContext.employer!.company.linkedin!}
                    underline="never"
                    fz={"12px"}
                    c={"#1d2124"}
                    fw={600}
                  >
                    LinkedIn
                  </Anchor>
                </Badge>
              )}
              <Badge bg={"#CAD3E2"} c={"#1d2124"} radius={3}>
                <Anchor
                  target="_blank"
                  href={"https://" + authContext.employer!.company.web_site}
                  underline="never"
                  fz={"12px"}
                  c={"#1d2124"}
                  fw={600}
                >
                  Website
                </Anchor>
              </Badge>
            </Flex>
          </Flex>
          <ActionIcon onClick={toggle} size={"xl"} color="#DBE2E6" radius={32}>
            <IconDots size={24} stroke={2.5} color="#1d2124" />
          </ActionIcon>
        </Flex>
      </Container>
    </>
  );
};

export default CompanySettings;
