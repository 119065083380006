import { Anchor, Badge, Button, Divider, Flex, Text } from "@mantine/core";
import { IconDiamond } from "@tabler/icons-react";
import NavbarUser from "./NavbarUser";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";

const NavbarBottom = () => {
  const authContext = useContext(AuthContext);
  return (
    <Flex direction={"column"} rowGap={5}>
      <NavbarUser />
      <Divider my={15} />
      <Flex align={"center"} justify={"space-between"}>
        <Flex align={"center"} columnGap={5}>
          <IconDiamond />
          <Text fw={600}>Available Job Slots</Text>
        </Flex>
        <Badge bg={"#C6F16D"} c={"#1d2124"}>
          {authContext.employer!.company.slots}
        </Badge>
      </Flex>
      <Anchor underline="never" href="/purchase">
        <Button fullWidth color="#C6F16D" c={"#1d2124"} radius={32}>
          Purchase Job Slots
        </Button>
      </Anchor>
    </Flex>
  );
};

export default NavbarBottom;
