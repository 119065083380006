import cookies from "js-cookie";
import React, { createContext, useEffect, useState } from "react";
import { IEmployer } from "../interfaces/employer";
import { isEmployerCookie } from "../helpers/auth";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { supabase } from "../supabase";
import { getCompanyBy } from "../services/company";

export const AuthContext = createContext<{
  employer: IEmployer | null;
  update(prop: keyof IEmployer, data: any): void;
  login(emp: IEmployer): void;
  logout(): void;
}>({
  employer: null,
  update(prop, data) {},
  login(emp) {},
  logout() {},
});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [employerData, setEmployerData] = useState<IEmployer | null>(null);
  const [changes, setChanges] = useState<boolean>(false);
  const [mount, setMount] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const update = (prop: keyof IEmployer, data: any) => {
    setEmployerData((prev) => ({ ...prev!, [prop]: data }));
  };

  const redirect = (path: string) => {
    if (path.includes("auth")) {
      if (!isEmployerCookie(cookies.get("employer"))) {
        navigate("/auth/sign-in");
      } else {
        navigate("/dashboard");
      }
    } else if (path === "/" && isEmployerCookie(cookies.get("employer"))) {
      navigate("/dashboard");
    }
  };

  const login = (emp: IEmployer) => {
    setMount(false);
    cookies.set("employer", JSON.stringify(emp), {
      expires: moment(new Date()).add(3, "month").toDate(),
    });
    setEmployerData(emp);
    setMount(true);
    navigate("/dashboard");
  };

  const logout = () => {
    cookies.remove("employer");
    setEmployerData(null);
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    const cookie = cookies.get("employer");
    if (isEmployerCookie(cookie)) {
      setEmployerData(JSON.parse(cookie!));
      setMount(true);
    } else {
      console.log("redirecting...");
      setEmployerData(null);
      navigate("/auth/sign-in");
      setMount(true);
    }
  }, []);

  useEffect(() => {
    supabase
      .channel("company-update-channel")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "companies" },
        () => {
          setChanges(true);
        }
      )
      .subscribe();
  }, []);

  useEffect(() => {
    if (changes === true) {
      if (employerData !== null) {
        setTimeout(async () => {
          const companyDetails = await getCompanyBy(
            "id",
            employerData.company_id
          );
          update("company", companyDetails.result);
          cookies.set("employer", JSON.stringify(employerData), {
            expires: moment(new Date()).add(3, "month").toDate(),
          });
          setChanges(false);
        }, 100);
      }
    } else {
      setChanges(false);
    }
  }, [changes]);

  useEffect(() => {
    redirect(location.pathname);
  }, []);

  return (
    <AuthContext.Provider
      value={{ employer: employerData, login, logout, update }}
    >
      {mount && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
