import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import {
  IconAdjustments,
  IconExclamationCircle,
  IconNotesOff,
  IconWand,
} from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { ApplicationsContext } from "../../contexts/ApplicationsProvider";
import { Button, Center, Container, Flex, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import {
  IApplication,
  IApplicationFilter,
  IStage,
} from "../../interfaces/application";
import { getJobByShortCode } from "../../services/job";
import Loading from "../../components/Loading";
import ApplicationDetail from "../../components/applications/Application";
import { IJob } from "../../interfaces/job";
import ApplicationStageFiltersDrawer from "../../drawers/ApplicationStageFiltersDrawer";
import ApplicationNavigatorModal from "../../modals/ApplicationNavigatorModal";
import { useViewportSize } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { updateApplicantStage } from "../../services/applications";

const Applications = () => {
  const { width } = useViewportSize();
  const appContext = useContext(ApplicationsContext);
  const [applicationList, setApplicationList] = useState<IApplication[]>([]);
  const [jobDetail, setJobDetail] = useState<IJob>();
  const [title, setTitle] = useState<string>("");
  const params = useParams<{ short_code: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedStages, setSelectedStages] = useState<IStage[]>([]);
  const [changes, setChanges] = useState<boolean>(false);
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false);
  const [appNavigatorModalOpened, setAppNavigatorModalOpened] = useState<
    boolean
  >(false);
  const [
    currentApplication,
    setCurrentApplication,
  ] = useState<IApplication | null>(null);
  const [filters, setFilters] = useState<IApplicationFilter[]>([]);

  useEffect(() => {
    if (
      typeof params !== "undefined" &&
      params.short_code !== null &&
      typeof appContext !== "undefined"
    ) {
      const list = appContext.applications.filter(
        (a) => a.job.short_code === params.short_code!
      );
      setApplicationList(list);
      setTimeout(async () => {
        const getJobDetails = await getJobByShortCode(params.short_code!);
        let jobTitle = getJobDetails.result.title;
        jobTitle =
          getJobDetails.result.ref !== null
            ? jobTitle + " - (" + getJobDetails.result.ref + ")"
            : jobTitle + "";
        setJobDetail(getJobDetails.result);
        setTitle(jobTitle);
        setLoading(false);
      }, 5);
    }
  }, [params]);

  useEffect(() => {
    setLoading(true);
    const filterForStages: number[] = [];

    selectedStages.forEach((ss) => {
      filterForStages.push(ss.stage);
    });

    let list: IApplication[] = [];
    if (filterForStages.length > 0) {
      list = appContext.applications.filter(
        (a) =>
          a.job.short_code === params.short_code! &&
          filterForStages.includes(a.stage)
      );
    } else {
      list = appContext.applications.filter(
        (a) => a.job.short_code === params.short_code!
      );
    }
    setApplicationList(list);

    setLoading(false);
  }, [selectedStages]);

  useEffect(() => {
    if (changes === true) {
      setLoading(true);
      const filterForStages: number[] = [];

      selectedStages.forEach((ss) => {
        filterForStages.push(ss.stage);
      });

      let list: IApplication[] = [];
      if (filterForStages.length > 0) {
        list = appContext.applications.filter(
          (a) =>
            a.job.short_code === params.short_code! &&
            filterForStages.includes(a.stage)
        );
      } else {
        list = appContext.applications.filter(
          (a) => a.job.short_code === params.short_code!
        );
      }
      setApplicationList(list);
      setLoading(false);
      setChanges(false);
    }
  }, [changes]);

  useEffect(() => {
    if (
      appNavigatorModalOpened === false &&
      currentApplication !== null &&
      width > 1000
    ) {
      setAppNavigatorModalOpened(true);
    } else if (currentApplication === null) {
      setAppNavigatorModalOpened(false);
    }

    if (currentApplication !== null && width <= 1100) {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "Please use desktop screen!",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
      setCurrentApplication(null);
      setAppNavigatorModalOpened(false);
    }
  }, [currentApplication, width]);

  useEffect(() => {
    if (
      appNavigatorModalOpened === true &&
      currentApplication !== null &&
      currentApplication.stage === 0
    ) {
      setTimeout(async () => {
        const stageUpdate = updateApplicantStage(currentApplication.id, 1);
      }, 5);
    }
  }, [appNavigatorModalOpened]);

  if (typeof jobDetail !== "undefined") {
    return (
      <>
        <Helmet>
          <title>Received Applications</title>
        </Helmet>
        <PageHeader
          h={{
            icon: <IconWand />,
            title: title,
            description: `Here you can see all received applications of ${title}.`,
          }}
          right={
            <Button
              variant="outline"
              c={"#1d2124"}
              color="#eaeaea"
              radius={32}
              bg={"#F4F4F5"}
              onClick={() => setFilterDrawerOpened(true)}
              rightSection={<IconAdjustments color="#1d2124" />}
            >
              {filters.length === 0 && "Filters"}
              {filters.length > 0 &&
                filters.length.toString() + " Selected Filters"}
            </Button>
          }
        />
        <ApplicationNavigatorModal
          setCurrentApplication={setCurrentApplication}
          currentApplication={currentApplication}
          opened={appNavigatorModalOpened}
          close={setAppNavigatorModalOpened}
        />
        <ApplicationStageFiltersDrawer
          filters={filters}
          setFilters={setFilters}
          selectedStages={selectedStages}
          changeStage={setSelectedStages}
          opened={filterDrawerOpened}
          close={setFilterDrawerOpened}
          apps={appContext.applications}
        />
        <Container size={"lg"}>
          {loading === true && <Loading />}
          {loading === false && applicationList.length === 0 && (
            <>
              <Center mt={30} mb={10}>
                <IconNotesOff />
              </Center>
              <Text ta={"center"}>
                {applicationList.length === 0 ? "No Application!" : null}
              </Text>
            </>
          )}

          {loading === false && applicationList.length > 0 && (
            <Flex mt={30} direction={"column"} rowGap={15}>
              {applicationList.map((app, i) => {
                return (
                  <ApplicationDetail
                    setCurrentApplication={setCurrentApplication}
                    openNavigator={setAppNavigatorModalOpened}
                    setCurrentState={setChanges}
                    app={app}
                    key={i}
                  />
                );
              })}
            </Flex>
          )}
        </Container>
      </>
    );
  } else {
    return null;
  }
};

export default Applications;
