type Breadcrumb = Record<string, string>;

const breadcrumbList: Breadcrumb = {
  "/": "Dashboard",
  "/job-ads": "Job Ads",
  "/applications": "Applications",
  "/notifications": "Notifications",
  "/talent-pool": "Talent Pool",
  "/messages": "Messages",
  "/team": "Team",
  "/billing": "Billing",
  "/settings": "Company Settings",
  "/profile": "User Profile",
  "/user-settings": "User Settings",
  "/user-settings/personal": "User Settings",
  "/user-settings/social": "User Settings",
  "/user-settings/password": "User Settings",
  "/user-settings/profile-picture": "User Settings",
  "/purchase": "Purchase Job Slots",
  "/job-ads/create-new": "Create New Job Ad",
  "/job-ads/edit": "Edit Job Ad",
};

export const getBreadcrumbs = (path: string) => {
  if (path.split("/").length > 2) {
    const parts: string[] = [];
    for (let index = 0; index < 3; index++) {
      parts.push(path.split("/")[index]);
    }
    return breadcrumbList[parts.join("/")];
  }
  return breadcrumbList[path];
};
