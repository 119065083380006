import { Flex, NumberFormatter, Text } from "@mantine/core";
import { IJobAdRequest, IJobEditRequest } from "../../interfaces/job";
import {
  jobCategories,
  jobCurrencies,
  jobLevels,
  jobTypes,
  jobWorkplaces,
} from "../../data/job";

const JobAdPreview: React.FC<{ job: IJobAdRequest | IJobEditRequest }> = ({
  job,
}) => {
  return (
    <div>
      <Text size="18px" mb={5} fw={600}>
        Preview
      </Text>
      <Flex miw={600} direction={"column"}>
        <Text mb={10} fw={600}>
          {job.title}
        </Text>
        <Flex wrap={"wrap"} rowGap={10} align={"center"} columnGap={10}>
          {job.category !== null && (
            <Flex
              style={{ borderRadius: "32px" }}
              px={"xs"}
              py={1}
              fz="13px"
              fw={400}
              tt={"capitalize"}
              bg={"#F4F4F5"}
              c="#1d2124"
            >
              {
                jobCategories.find((l) => l.value === job.category!.toString())!
                  .label
              }
            </Flex>
          )}
          {job.address !== null && (
            <Flex
              style={{ borderRadius: "32px" }}
              px={"xs"}
              py={1}
              fz="13px"
              fw={400}
              tt={"capitalize"}
              bg={"#F4F4F5"}
              c="#1d2124"
            >
              {job.address}
            </Flex>
          )}
          {job.type !== null && (
            <Flex
              style={{ borderRadius: "32px" }}
              px={"xs"}
              py={1}
              fz="13px"
              fw={400}
              tt={"capitalize"}
              bg={"#F4F4F5"}
              c="#1d2124"
            >
              {jobTypes.find((l) => l.value === job.type!.toString())!.label}
            </Flex>
          )}
          {job.workplace !== null && (
            <Flex
              style={{ borderRadius: "32px" }}
              px={"xs"}
              py={1}
              fz="13px"
              fw={400}
              tt={"capitalize"}
              bg={"#F4F4F5"}
              c="#1d2124"
            >
              {
                jobWorkplaces.find(
                  (l) => l.value === job.workplace!.toString()
                )!.label
              }
            </Flex>
          )}
          {job.level !== null && (
            <Flex
              style={{ borderRadius: "32px" }}
              px={"xs"}
              py={1}
              fz="13px"
              fw={400}
              tt={"capitalize"}
              bg={"#F4F4F5"}
              c="#1d2124"
            >
              {jobLevels.find((l) => l.value === job.level!.toString())!.label}
            </Flex>
          )}
          {job.is_salary_visible === true &&
            job.min_salary !== null &&
            job.min_salary > 0 &&
            job.max_salary !== null &&
            job.max_salary > 0 &&
            job.salary_currency !== null &&
            job.salary_currency !== undefined && (
              <Flex
                style={{ borderRadius: "32px" }}
                px={"xs"}
                py={1}
                fz="13px"
                fw={400}
                tt={"capitalize"}
                bg={"#F4F4F5"}
                c="#1d2124"
              >
                <NumberFormatter
                  suffix={
                    jobCurrencies.find(
                      (l) => l.value === job.salary_currency!.toString()
                    )!.label
                  }
                  value={job.min_salary}
                  thousandSeparator
                />
                &nbsp;{"-"}&nbsp;
                <NumberFormatter
                  suffix={
                    jobCurrencies.find(
                      (l) => l.value === job.salary_currency!.toString()
                    )!.label
                  }
                  value={job.max_salary}
                  thousandSeparator
                />
              </Flex>
            )}
        </Flex>
        <div dangerouslySetInnerHTML={{ __html: job.description! }} />
      </Flex>
    </div>
  );
};

export default JobAdPreview;
