import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import {
  IconBriefcase,
  IconCheck,
  IconExclamationCircle,
  IconInfoCircle,
} from "@tabler/icons-react";
import { Button, Flex } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import JobAdNewForm from "../../components/jobs/JobAdNewForm";
import { useContext, useState } from "react";
import { IJobAdRequest } from "../../interfaces/job";
import JobAdPreview from "../../components/jobs/JobAdPreview";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  publishDirectly,
  publishJobs,
  saveJobAdAsDraft,
} from "../../services/job";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { isReadyForPublish } from "../../helpers/job";

const JobAdsNew = () => {
  const authContext = useContext(AuthContext);
  const { width } = useViewportSize();
  const [jobAddRequest, setJobAdRequest] = useState<IJobAdRequest>({
    title: null,
    titleError: null,
    level: null,
    levelError: null,
    category: null,
    categoryError: null,
    workplace: null,
    workplaceError: null,
    type: null,
    typeError: null,
    description: null,
    descriptionError: null,
    address: null,
    addressError: null,
    is_external: false,
    external_url: null,
    externalUrlError: null,
    is_salary_visible: false,
    min_salary: null,
    minSalaryError: null,
    max_salary: null,
    maxSalaryError: null,
    salary_currency: null,
    salaryCurrencyError: null,
    status: 0,
    ready: false,
    ref: null,
  });
  const [draftLoading, setDraftLoading] = useState<boolean>(false);
  const [publishLoading, setPublishLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const setRequestProp = (prop: keyof IJobAdRequest, data: any) => {
    setJobAdRequest((prev) => ({ ...prev, [prop]: data }));
  };

  const saveAsDraft = async () => {
    if (jobAddRequest.title === null || jobAddRequest.title.length < 5) {
      setRequestProp(
        "titleError",
        "Please provide a title that has at least 5 chars."
      );
    }

    const { ready } = isReadyForPublish(jobAddRequest, "draft");

    let newJobAdRequest = { ...jobAddRequest, ["ready"]: ready };

    if (newJobAdRequest.title !== null && newJobAdRequest.title.length > 4) {
      setDraftLoading(true);
      const save = await saveJobAdAsDraft(
        newJobAdRequest,
        authContext.employer!.company.id,
        authContext.employer!.id
      );
      setDraftLoading(false);
      if (save.status === true) {
        notifications.show({
          position: "top-right",
          title: "Done!",
          message: `${jobAddRequest.title} has been saved as draft!`,
          icon: <IconInfoCircle color="#007B80" />,
          color: "white",
          styles: {
            root: { backgroundColor: "#1d2124" },
            title: { color: "white" },
            body: { color: "white" },
          },
        });
        navigate("/job-ads/edit/" + save.result);
      } else {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Something went wrong during saving job ad.",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
      }
    }
  };

  const publish = async () => {
    const { ready, request } = isReadyForPublish(jobAddRequest, "publish");

    let newJobAdRequest = request;
    newJobAdRequest = { ...newJobAdRequest, ["ready"]: ready };

    if (ready === false) {
      setJobAdRequest(request);
    } else {
      setPublishLoading(true);
      const response = await publishDirectly(
        newJobAdRequest,
        authContext.employer!.company.id,
        authContext.employer!.id
      );
      setPublishLoading(false);
      if (response.status === true) {
        notifications.show({
          position: "top-right",
          title: "Done!",
          message: `${jobAddRequest.title} has been published!`,
          icon: <IconInfoCircle color="#007B80" />,
          color: "white",
          styles: {
            root: { backgroundColor: "#1d2124" },
            title: { color: "white" },
            body: { color: "white" },
          },
        });
        navigate("/job-ads/");
      } else {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Something went wrong during publishing job ad.",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
        console.log(response);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>New Job Ad</title>
      </Helmet>
      <PageHeader
        h={
          width < 800
            ? { icon: <IconBriefcase />, title: "New Job Ad", description: "" }
            : {
                icon: <IconBriefcase />,
                title: "New Job Ad",
                description:
                  "Craft compelling job postings that attract the best candidates.",
              }
        }
        right={
          <>
            <Flex
              w={width < 600 ? "100%" : "fit-content"}
              align={"center"}
              columnGap={15}
            >
              <Button
                disabled={draftLoading || publishLoading}
                loading={draftLoading}
                fw={400}
                radius={32}
                color="#1d2124"
                c={"white"}
                fullWidth={width < 600}
                onClick={saveAsDraft}
              >
                Save as Draft
              </Button>
              <Button
                onClick={publish}
                disabled={draftLoading || publishLoading}
                loading={publishLoading}
                fw={400}
                radius={32}
                color="#0698A0"
                c={"white"}
                fullWidth={width < 600}
                rightSection={<IconCheck size={16} />}
              >
                Publish
              </Button>
            </Flex>
          </>
        }
      />
      <Flex px={"xl"} align={"flex-start"} columnGap={30}>
        <JobAdNewForm request={jobAddRequest} setRequest={setRequestProp} />
        {width > 1500 && <JobAdPreview job={jobAddRequest} />}
      </Flex>
    </>
  );
};

export default JobAdsNew;
