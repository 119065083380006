import { ComboboxItem } from "@mantine/core";
import {
  IconCheck,
  IconCircleCheckFilled,
  IconCircleDashedX,
  IconDots,
  IconEye,
  IconEyeDotted,
  IconLoader,
} from "@tabler/icons-react";
import { IStage } from "../interfaces/application";

export const jobTypes: ComboboxItem[] = [
  { value: "0", label: "Full-Time" },
  { value: "1", label: "Part-Time" },
  { value: "2", label: "Internship" },
  { value: "3", label: "Contract / Self-Employed / Freelance" },
  { value: "4", label: "Internship / Apprenticeship" },
];

export const jobCategories: ComboboxItem[] = [
  { value: "0", label: "Drilling and Rig Operations" },
  { value: "1", label: "Exploration and Production (E&P)" },
  { value: "2", label: "Health, Safety, and Environment (HSE)" },
  { value: "3", label: "Refining and Downstream Operations" },
  { value: "4", label: "Offshore and Marine" },
  { value: "5", label: "Maintenance and Reliability" },
  { value: "6", label: "Supply Chain and Logistics" },
  { value: "7", label: "Project Management" },
  { value: "8", label: "Inspection and Quality Assurance" },
  { value: "9", label: "Data Science and Digital Technology" },
  { value: "10", label: "Commercial and Business Development" },
  { value: "11", label: "Finance and Accounting" },
  { value: "12", label: "Human Resources" },
  { value: "13", label: "Environmental and Sustainability" },
  { value: "14", label: "Research and Development" },
  { value: "15", label: "Support Services" },
  { value: "16", label: "Renewable Energy Integration" },
];

export const jobWorkplaces: ComboboxItem[] = [
  { value: "0", label: "On-Site" },
  { value: "1", label: "Hybrid" },
  { value: "2", label: "Remote" },
];

export const jobLevels: ComboboxItem[] = [
  { value: "0", label: "Entry-Level" },
  { value: "1", label: "Mid-Level" },
  { value: "2", label: "Senior" },
  { value: "3", label: "Management" },
  { value: "4", label: "Executive" },
  { value: "5", label: "Skilled Trades and Technical" },
  { value: "6", label: "Consulting and Advisory" },
  { value: "7", label: "Board and Leadership" },
];

export const jobCurrencies: ComboboxItem[] = [
  { value: "0", label: "USD" },
  { value: "1", label: "EUR" },
  { value: "2", label: "GBP" },
  { value: "3", label: "CAD" },
  { value: "4", label: "AUD" },
  { value: "5", label: "CHF" },
  { value: "6", label: "SEK" },
  { value: "7", label: "NOK" },
  { value: "8", label: "PLN" },
  { value: "9", label: "THB" },
  { value: "10", label: "SAR" },
  { value: "11", label: "QAR" },
];

export const jobFilter: { filter: number; label: string }[] = [
  {
    filter: 0,
    label: "draft",
  },
  {
    filter: 1,
    label: "published",
  },
  {
    filter: 2,
    label: "expired",
  },
  {
    filter: 3,
    label: "closed",
  },
  {
    filter: 4,
    label: "ready",
  },
];

export const applicationStages: IStage[] = [
  {
    stage: 0,
    label: "Application Received",
    icon: <IconCheck size={18} color="#007B80" />,
    color: "#C6F16D",
  },
  {
    stage: 1,
    label: "Application Viewed",
    icon: <IconEye size={18} color="#007B80" />,
    color: "#eaeaea",
  },
  {
    stage: 2,
    label: "In Review",
    icon: <IconDots size={18} color="#219ebc" />,
    color: "#219ebc",
  },
  {
    stage: 3,
    label: "Interviewing",
    icon: <IconLoader size={18} color="#0F65B6" />,
    color: "#0F65B6",
  },
  {
    stage: 4,
    label: "Rejected",
    icon: <IconCircleDashedX color="crimson" size={18} />,
    color: "crimson",
  },
  {
    stage: 5,
    label: "Accepted",
    icon: <IconCircleCheckFilled size={18} color="#007B80" />,
    color: "#007B80",
  },
];
