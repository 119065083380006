import { Button, Flex, Modal, TextInput } from "@mantine/core";
import { IconSend } from "@tabler/icons-react";

const TeamInvitation: React.FC<{ opened: boolean; toggle(): void }> = ({
  opened,
  toggle,
}) => {
  return (
    <Modal
      title="Invite a Team Member"
      styles={{ title: { fontWeight: "600" } }}
      opened={opened}
      onClose={toggle}
      closeOnClickOutside={false}
    >
      <Flex direction={"column"} rowGap={15}>
        <TextInput
          label="Email Address"
          placeholder="enter your collegue's email address"
          radius={0}
        />
        <Button
          rightSection={<IconSend size={16} />}
          radius={32}
          fw={400}
          color="#1d2124"
          fullWidth
        >
          Send Invitation
        </Button>
      </Flex>
    </Modal>
  );
};

export default TeamInvitation;
