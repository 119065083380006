import { Anchor, Box, Burger, Flex, Text } from "@mantine/core";
import Logo from "../Logo";
import { IconChevronRight } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBreadcrumbs } from "../../helpers/breadcrumb";
const Header: React.FC<{ opened: boolean; toggle(): void }> = ({
  opened,
  toggle,
}) => {
  const location = useLocation();
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/dashboard") {
      setCurrentBreadcrumb(null);
    } else {
      setCurrentBreadcrumb(getBreadcrumbs(location.pathname));
    }
  }, [location.pathname]);

  return (
    <Flex
      px={"md"}
      py={"md"}
      h={"100%"}
      align={"center"}
      justify={"space-between"}
    >
      <Flex w={"100%"} visibleFrom="sm" align={"center"} columnGap={3}>
        <Anchor underline="never" c={"#1d2124"} href="/dashboard">
          <Text>Dashboard</Text>
        </Anchor>
        {currentBreadcrumb !== null && (
          <>
            <IconChevronRight size={16} />
            <Text fw={600}>{currentBreadcrumb}</Text>
          </>
        )}
      </Flex>
      <Box hiddenFrom="sm">
        <Logo href="/dashboard" />
      </Box>
      <Burger hiddenFrom="sm" opened={opened} onClick={toggle} size={"sm"} />
    </Flex>
  );
};

export default Header;
