import { hashPassword, randomString } from "../helpers/data";
import { ICompany } from "../interfaces/company";
import {
  IEmployer,
  IEmployerPasswordRecoveryRequest,
  IEmployerResetPasswordRequest,
  IEmployerSignInRequest,
  IEmployerSignUpRequest,
} from "../interfaces/employer";
import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";
import { sendMessageToQueue } from "./message";

export const signin = (request: IEmployerSignInRequest): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const pass = hashPassword(request.password!);
    const { data, error } = await supabase
      .from("employers")
      .select("*")
      .eq("email_address", request.email_address)
      .eq("password", pass)
      .select();

    if (data !== null && data.length > 0) {
      const { data: comp } = await supabase
        .from("companies")
        .select("*")
        .eq("id", data[0].company_id)
        .select();

      if (comp !== null && comp.length > 0) {
        const company: ICompany = {
          id: comp[0].id,
          address: comp[0].address,
          cover: comp[0].cover,
          logo: comp[0].logo,
          created_at: comp[0].created_at,
          created_by: comp[0].created_by,
          linkedin: comp[0].linkedin,
          name: comp[0].name,
          slots: comp[0].slots,
          web_site: comp[0].web_site,
          accepted: comp[0].accepted,
        };
        const employer: IEmployer = {
          id: data[0].id,
          company_id: data[0].company_id,
          created_at: data[0].created_at,
          email_address: data[0].email_address,
          full_name: data[0].full_name,
          is_verified: data[0].is_verified,
          profile_picture: data[0].profile_picture,
          linkedin: data[0].linkedin,
          company,
        };

        const { data: update, error: updateError } = await supabase
          .from("employers")
          .update({ last_login_at: new Date() })
          .eq("id", data[0].id)
          .select();

        if (employer.is_verified === true) {
          resolve({ error: null, status: true, result: employer });
        } else {
          resolve({ error: "NotVerified", status: false, result: null });
        }
      } else {
        resolve({
          error: "CompanyDetailsCouldntGet",
          status: false,
          result: null,
        });
      }
    } else {
      resolve({ error: "InvalidCredentials", status: false, result: null });
    }
  });
};

export const signup = (request: IEmployerSignUpRequest): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const pass = hashPassword(request.password!);

    const { data: check } = await supabase
      .from("employers")
      .select("*")
      .eq("email_address", request.email_address)
      .select();

    if (check === null || check.length === 0) {
      const verificationCode = randomString(256);
      const { data } = await supabase
        .from("employers")
        .insert({
          full_name: request.full_name,
          email_address: request.email_address,
          password: pass,
          is_verified: false,
          company_id: null,
          profile_picture: null,
          reset_password_code: null,
          verification_code: verificationCode,
          created_at: new Date(),
        })
        .select();
      if (data !== null && data.length > 0) {
        await sendMessageToQueue("employer-verification-emails", {
          to: request.email_address,
          code: verificationCode,
          fullName: request.full_name,
        });
        resolve({
          error: null,
          status: true,
          result: "VerificationEmailSent",
          additional: data[0],
        });
      } else {
        resolve({ error: "CannotCreateAccount", status: true, result: null });
      }
    } else {
      resolve({ error: "DuplicateAccount", status: false, result: null });
    }
  });
};

export const recovery = (
  request: IEmployerPasswordRecoveryRequest
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("employers")
      .select("*")
      .eq("email_address", request.email_address)
      .select();

    if (data !== null && data.length > 0) {
      const resetPasswordCode = randomString(256);

      const { data: update } = await supabase
        .from("employers")
        .update({
          reset_password_code: resetPasswordCode,
        })
        .eq("id", data[0].id)
        .select();

      if (update !== null && update.length > 0) {
        await sendMessageToQueue("employer-recovery-emails", {
          to: request.email_address,
          code: resetPasswordCode,
          fullName: update[0].full_name,
        });
        resolve({ error: null, status: true, result: "RecoveryEmailSent" });
      } else {
        resolve({
          error: null,
          status: true,
          result: "RecoveryCouldntEmailSent",
        });
      }
    } else {
      resolve({ error: "EmailNotFound", status: false, result: null });
    }
  });
};

export const verify = (code: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("employers")
      .select("*")
      .eq("verification_code", code)
      .select();

    if (data !== null && data.length > 0) {
      const { data: update } = await supabase
        .from("employers")
        .update({ verification_code: null, is_verified: true })
        .eq("id", data[0].id)
        .select();

      if (update !== null && update.length > 0) {
        const employer: IEmployer = update[0];
        resolve({ error: null, status: true, result: employer });
      } else {
        resolve({ error: "CouldntVerified", status: false, result: null });
      }
    } else {
      resolve({ error: "CodeNotFound", status: false, result: null });
    }
  });
};

export const checkRecovery = (code: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("employers")
      .select("*")
      .eq("reset_password_code", code)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data[0].email_address });
    } else {
      resolve({ error: null, status: false, result: null });
    }
  });
};

export const savePassword = (
  req: IEmployerResetPasswordRequest
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const pass = hashPassword(req.password!);
    const { data } = await supabase
      .from("employers")
      .update({ password: pass })
      .eq("email_address", req.email_address)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: true });
    } else {
      resolve({ error: null, status: false, result: false });
    }
  });
};
