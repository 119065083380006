import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";

const Logout = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.logout();
  }, []);

  return (
    <>
      <Helmet>
        <title>Signing out...</title>
      </Helmet>
    </>
  );
};

export default Logout;
