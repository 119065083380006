import {
  Anchor,
  Button,
  Center,
  Flex,
  Image,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import AuthFooter from "../../components/auth/AuthFooter";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useState } from "react";
import { IResult } from "../../interfaces/result";
import { IEmployerPasswordRecoveryRequest } from "../../interfaces/employer";
import { isEmail } from "validator";
import { recovery } from "../../services/auth";
import { IconMailFilled } from "@tabler/icons-react";

const ForgotPassword = () => {
  const { width } = useViewportSize();
  const [recoveryRequest, setRecoveryRequest] = useState<
    IEmployerPasswordRecoveryRequest
  >({
    email_address: null,
    email_address_error: null,
  });
  const [result, setResult] = useState<IResult>({
    error: null,
    status: false,
    result: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const setRequestProp = (
    prop: keyof IEmployerPasswordRecoveryRequest,
    data: any
  ) => {
    setRecoveryRequest((prev) => ({ ...prev, [prop]: data }));
  };

  const onRecovery = async () => {
    if (
      recoveryRequest.email_address === null ||
      !isEmail(recoveryRequest.email_address)
    ) {
      setRequestProp(
        "email_address_error",
        "Please provide a valid email address."
      );
    }

    if (
      recoveryRequest.email_address !== null &&
      isEmail(recoveryRequest.email_address!)
    ) {
      setLoading(true);
      const recoveryResponse = await recovery(recoveryRequest);
      setResult(recoveryResponse);
      setLoading(false);
    }
  };

  if (result !== null && result.status === true) {
    return (
      <>
        <Helmet>
          <title>Password Recovery</title>
        </Helmet>
        <Flex
          align={"center"}
          py={30}
          direction={"column"}
          h={"100%"}
          justify={"space-between"}
        >
          <Logo href="/" />
          <Flex direction={"column"} align={"center"} w={320} p={"md"} mt={15}>
            <IconMailFilled />
            <Text ta={"center"} mt={5} mb={15}>
              We have just sent a password recovery link to you.
            </Text>
          </Flex>
          <AuthFooter />
        </Flex>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Password Recovery</title>
        </Helmet>
        <Flex
          align={"center"}
          py={30}
          direction={"column"}
          h={"100%"}
          justify={"space-between"}
        >
          <Logo href="/" />
          <Paper bg={"transparent"} w={320} p={"md"} radius={8} mt={15}>
            <Text ta={"center"} mb={5} size="20px" fw={700}>
              Password Recovery
            </Text>
            <Text ta={"center"} mb={15}>
              Please provide your O&G Talent account email address.
            </Text>
            {result !== null &&
              result.status === false &&
              result.error === "RecoveryCouldntEmailSent" && (
                <Text mb={15} c="crimson" ta={"center"}>
                  Email couldn't send! Please refresh the page and try again!
                </Text>
              )}
            {result !== null &&
              result.status === false &&
              result.error === "EmailNotFound" && (
                <Text mb={15} c="crimson" ta={"center"}>
                  Email Not Found!
                </Text>
              )}
            <TextInput
              error={recoveryRequest.email_address_error}
              onChange={(e) => {
                setRequestProp("email_address", e.currentTarget.value);
                setRequestProp("email_address_error", null);
                setResult((prev) => ({ ...prev, ["result"]: null }));
              }}
              variant="filled"
              radius={0}
              label="Email Address"
              placeholder="enter email address"
              mb={15}
            />
            <Button
              loading={loading}
              mb={15}
              radius={32}
              color="#1d2124"
              fullWidth
              onClick={onRecovery}
            >
              Send
            </Button>
          </Paper>

          {width < 700 && (
            <Image src={"/forgot-password-person.svg"} w={200} h={200} />
          )}
          <AuthFooter />
        </Flex>
      </>
    );
  }
};

export default ForgotPassword;
