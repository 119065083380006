import { IOrder } from "../interfaces/checkout";
import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";

export const getBillingsByCompany = (company_id: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("orders")
      .select("*")
      .eq("status", true)
      .eq("company_id", company_id)
      .select();

    if (data !== null && data.length > 0) {
      const { data: employers } = await supabase.from("employers").select("*");

      const combinedData = data.map((d: IOrder) => {
        const full_name = employers!.find((e) => e.id === d.employer_id)!
          .full_name;
        return {
          ...d,
          full_name,
        };
      });
      resolve({ error: null, status: true, result: combinedData });
    } else {
      resolve({ error: null, status: true, result: [] });
    }
  });
};
