import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthProvider from "./contexts/AuthProvider";
import JobProvider from "./contexts/JobProvider";
import ApplicationsProvider from "./contexts/ApplicationsProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MantineProvider
    theme={{
      fontFamily: "XING Sans",
      fontSizes: { xl: "14px", lg: "14px", md: "14px", sm: "14px", xs: "14px" },
    }}
  >
    <Notifications />
    <ModalsProvider />
    <BrowserRouter>
      <AuthProvider>
        <JobProvider>
          <ApplicationsProvider>
            <App />
          </ApplicationsProvider>
        </JobProvider>
      </AuthProvider>
    </BrowserRouter>
  </MantineProvider>
);
