import { Flex, Text } from "@mantine/core";
import { IPageHeader } from "../../interfaces/page";
import { useViewportSize } from "@mantine/hooks";

const PageHeader: React.FC<{ h: IPageHeader; right?: React.ReactNode }> = ({
  h,
  right,
}) => {
  return (
    <div style={{ marginBottom: "50px", marginTop: "30px", padding: "0 30px" }}>
      <Flex
        wrap={"wrap"}
        rowGap={15}
        align={"center"}
        justify={"space-between"}
      >
        <Flex align={"flex-start"} columnGap={5}>
          {h.icon}
          <Flex rowGap={5} direction={"column"}>
            <Text lh={"xs"} size="20px" fw={600}>
              {h.title}
            </Text>
            <Text>{h.description}</Text>
          </Flex>
        </Flex>
        {right}
      </Flex>
    </div>
  );
};

export default PageHeader;
