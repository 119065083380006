import { Anchor, Flex, Image, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";

const HomeTeamSummary = () => {
  const { width } = useViewportSize();
  return (
    <Flex
      style={{ borderRadius: "8px", border: "2px solid #E7ECEE" }}
      w={"100%"}
      p={15}
      wrap={"wrap"}
      rowGap={15}
      align={"center"}
      justify={"space-between"}
    >
      <Flex bg={"white"} align={"center"} columnGap={15}>
        <Image src={"/team-contact.svg"} />
        <Text size="18px">
          You have <strong>0 contacts.</strong>
        </Text>
      </Flex>
      <Anchor
        style={width <= 500 ? { justifySelf: "flex-end" } : {}}
        href="/team"
        underline="never"
        c={"#0698A0"}
      >
        <Flex align={"center"} columnGap={5}>
          <IconPlus size={16} />
          <Text>Invite Collegue</Text>
        </Flex>
      </Anchor>
    </Flex>
  );
};

export default HomeTeamSummary;
