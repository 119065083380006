import { Flex, Group, Select, Switch, TextInput } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import JobAdTextEditor from "./JobAdTextEditor";
import JobAddress from "./JobAddress";
import { IJobAdRequest } from "../../interfaces/job";
import {
  jobCategories,
  jobCurrencies,
  jobLevels,
  jobTypes,
  jobWorkplaces,
} from "../../data/job";

const JobAdNewForm: React.FC<{
  request: IJobAdRequest;
  setRequest(prop: keyof IJobAdRequest, data: any): void;
}> = ({ request, setRequest }) => {
  const { width } = useViewportSize();
  return (
    <Flex
      mb={30}
      style={{ borderRadius: "8px" }}
      p={"xl"}
      bg={"#fafafa"}
      miw={width < 1500 ? "100%" : 800}
      direction={"column"}
      rowGap={15}
    >
      <TextInput
        classNames={{
          root: request.titleError !== null ? "show-error" : "",
        }}
        styles={
          request.titleError !== null
            ? { input: { color: "crimson", borderColor: "crimson" } }
            : {}
        }
        required
        error={request.titleError}
        onChange={(e) => {
          setRequest("title", e.currentTarget.value);
          setRequest("titleError", null);
        }}
        radius={0}
        placeholder="job title"
        label="Job Title"
      />
      {width > 1500 && (
        <Group grow>
          <Select
            classNames={{
              root: request.levelError !== null ? "show-error" : "",
            }}
            styles={
              request.levelError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            onOptionSubmit={(e) => {
              setRequest("level", parseInt(e));
              setRequest("levelError", null);
            }}
            error={request.levelError}
            required
            placeholder="Select Level"
            label=" Level"
            allowDeselect={false}
            data={jobLevels}
            radius={0}
          />
          <Select
            styles={
              request.categoryError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            onOptionSubmit={(e) => {
              setRequest("category", parseInt(e));
              setRequest("categoryError", null);
            }}
            data={jobCategories}
            error={request.categoryError}
            required
            placeholder="Select Job Category"
            label="Job Category"
            radius={0}
            allowDeselect={false}
          />
        </Group>
      )}
      {width <= 1500 && (
        <Flex rowGap={15} direction={"column"}>
          <Select
            classNames={{
              root: request.levelError !== null ? "show-error" : "",
            }}
            styles={
              request.levelError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            onOptionSubmit={(e) => {
              setRequest("level", parseInt(e));
              setRequest("levelError", null);
            }}
            error={request.levelError}
            required
            placeholder="Select Level"
            label="Level"
            allowDeselect={false}
            data={jobLevels}
            radius={0}
          />
          <Select
            classNames={{
              root: request.categoryError !== null ? "show-error" : "",
            }}
            styles={
              request.categoryError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            allowDeselect={false}
            data={jobCategories}
            onOptionSubmit={(e) => {
              setRequest("category", parseInt(e));
              setRequest("categoryError", null);
            }}
            error={request.categoryError}
            required
            placeholder="Select Job Category"
            label="Job Category"
            radius={0}
          />
        </Flex>
      )}
      {width > 1500 && (
        <Group grow>
          <Select
            classNames={{
              root: request.workplaceError !== null ? "show-error" : "",
            }}
            styles={
              request.workplaceError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            onOptionSubmit={(e) => {
              setRequest("workplace", parseInt(e));
              setRequest("workplaceError", null);
            }}
            error={request.workplaceError}
            required
            placeholder="Select Workplace"
            label="Workplace"
            allowDeselect={false}
            data={jobWorkplaces}
            radius={0}
          />
          <Select
            classNames={{
              root: request.typeError !== null ? "show-error" : "",
            }}
            styles={
              request.typeError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            onOptionSubmit={(e) => {
              setRequest("type", parseInt(e));
              setRequest("typeError", null);
            }}
            allowDeselect={false}
            data={jobTypes}
            error={request.workplaceError}
            required
            placeholder="Select Job Type"
            label="Job Type"
            radius={0}
          />
        </Group>
      )}
      {width <= 1500 && (
        <Flex rowGap={15} direction={"column"}>
          <Select
            classNames={{
              root: request.workplaceError !== null ? "show-error" : "",
            }}
            styles={
              request.workplaceError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            onOptionSubmit={(e) => {
              setRequest("workplace", parseInt(e));
              setRequest("workplaceError", null);
            }}
            error={request.workplaceError}
            required
            placeholder="Select Workplace"
            label="Workplace"
            allowDeselect={false}
            data={jobWorkplaces}
            radius={0}
          />
          <Select
            classNames={{
              root: request.typeError !== null ? "show-error" : "",
            }}
            styles={
              request.typeError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            allowDeselect={false}
            onOptionSubmit={(e) => {
              setRequest("type", parseInt(e));
              setRequest("typeError", null);
            }}
            error={request.typeError}
            required
            placeholder="Select Job Type"
            label="Job Type"
            radius={0}
            data={jobTypes}
          />
        </Flex>
      )}
      <JobAdTextEditor
        request={request}
        setRequest={setRequest}
        label="Job Description"
      />
      <JobAddress request={request} setRequest={setRequest} />

      <TextInput
        onChange={(e) => {
          const data = e.currentTarget.value;
          setRequest("ref", data.length === 0 ? null : data);
        }}
        placeholder="e.g. AE-2536"
        w={"100%"}
        label="Reference ID"
        description="If you have a Reference ID for this job, please specify."
      />

      <Flex direction={"column"} align={"flex-start"} rowGap={15}>
        <Switch
          onChange={(e) => {
            setRequest("is_external", e.currentTarget.checked);
          }}
          styles={{
            thumb: { cursor: "pointer" },
            track: { cursor: "pointer" },
            label: { cursor: "pointer" },
          }}
          label="External Apply?"
          color="#1d2124"
        />
        <TextInput
          classNames={{
            root: request.externalUrlError !== null ? "show-error" : "",
          }}
          styles={
            request.externalUrlError !== null
              ? { input: { color: "crimson", borderColor: "crimson" } }
              : {}
          }
          onChange={(e) => {
            setRequest("external_url", e.currentTarget.value);
            setRequest("externalUrlError", null);
          }}
          disabled={request.is_external === false}
          error={request.externalUrlError}
          placeholder="enter external apply link"
          w={"100%"}
          label="External Link"
        />
      </Flex>

      <Switch
        onChange={(e) => {
          setRequest("is_salary_visible", e.currentTarget.checked);
        }}
        styles={{
          thumb: { cursor: "pointer" },
          track: { cursor: "pointer" },
          label: { cursor: "pointer" },
        }}
        label="Is Salary Visible?"
        color="#1d2124"
      />

      {width > 1500 && (
        <Group grow>
          <TextInput
            classNames={{
              root: request.minSalaryError !== null ? "show-error" : "",
            }}
            styles={
              request.minSalaryError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            disabled={!request.is_salary_visible}
            onChange={(e) => {
              setRequest("min_salary", e.currentTarget.value);
              setRequest("minSalaryError", null);
            }}
            error={request.minSalaryError}
            description="Optional"
            min={0}
            type="number"
            label="Min Salary"
            placeholder="80000"
          />
          <TextInput
            classNames={{
              root: request.maxSalaryError !== null ? "show-error" : "",
            }}
            styles={
              request.maxSalaryError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            disabled={!request.is_salary_visible}
            onChange={(e) => {
              setRequest("max_salary", e.currentTarget.value);
              setRequest("maxSalaryError", null);
            }}
            error={request.maxSalaryError}
            description="Optional"
            min={0}
            type="number"
            label="Max Salary"
            placeholder="100000"
          />
          <Select
            classNames={{
              root: request.salaryCurrencyError !== null ? "show-error" : "",
            }}
            styles={
              request.salaryCurrencyError !== null
                ? { input: { color: "crimson", borderColor: "crimson" } }
                : {}
            }
            disabled={!request.is_salary_visible}
            onOptionSubmit={(e) => {
              setRequest("salary_currency", parseInt(e));
              setRequest("salaryCurrencyError", null);
            }}
            data={jobCurrencies}
            error={request.salaryCurrencyError}
            description="Optional"
            label="Currency"
            placeholder="GBP"
          />
        </Group>
      )}
      {width <= 1500 && (
        <Flex rowGap={15} direction={"column"}>
          <TextInput
            disabled={!request.is_salary_visible}
            onChange={(e) => {
              setRequest("min_salary", e.currentTarget.value);
              setRequest("minSalaryError", null);
            }}
            error={request.minSalaryError}
            description="Optional"
            min={0}
            type="number"
            label="Min Salary"
            placeholder="80000"
          />
          <TextInput
            disabled={!request.is_salary_visible}
            onChange={(e) => {
              setRequest("max_salary", e.currentTarget.value);
              setRequest("maxSalaryError", null);
            }}
            error={request.maxSalaryError}
            description="Optional"
            min={0}
            type="number"
            label="Max Salary"
            placeholder="100000"
          />
          <Select
            disabled={!request.is_salary_visible}
            data={jobCurrencies}
            onOptionSubmit={(e) => {
              setRequest("salary_currency", parseInt(e));
              setRequest("salaryCurrencyError", null);
            }}
            error={request.salaryCurrencyError}
            description="Optional"
            label="Currency"
            placeholder="GBP"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default JobAdNewForm;
