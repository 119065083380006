import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import { IconUserCircle } from "@tabler/icons-react";
import { Avatar, Badge, Container, Flex, Text } from "@mantine/core";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import moment from "moment";
import { useViewportSize } from "@mantine/hooks";

const UserProfile = () => {
  const { width } = useViewportSize();
  const authContext = useContext(AuthContext);
  return (
    <>
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconUserCircle />,
          title: "User Profile",
          description:
            "Display key details such as name, photo, contact information, and professional headline.",
        }}
      />
      <Container size={"md"}>
        <Flex
          mb={50}
          columnGap={15}
          wrap={"wrap"}
          align={width < 900 ? "center" : "flex-start"}
          justify={"center"}
          rowGap={15}
        >
          <Avatar
            size={98}
            src={authContext.employer!.profile_picture}
            radius={12}
          />
          <Flex direction={"column"} rowGap={5}>
            <Flex align={"center"} wrap={"wrap"} rowGap={5} columnGap={5}>
              <Text size="32px" fw={600}>
                {authContext.employer!.full_name}
              </Text>
              <Badge radius={3} size="sm" c="#1d2124" bg={"#DBE2E6"}>
                Employer Account
              </Badge>
            </Flex>
            <Text size="16px">
              since {moment(authContext.employer!.created_at).year()}
            </Text>
            <Text size="16px">
              at <strong>{authContext.employer!.company.name}</strong>
            </Text>
          </Flex>
        </Flex>
        <Flex direction={"column"}>
          <Text ta={"center"} mb={15} size="24px" fw={600}>
            Job Ads
          </Text>
          <Text ta={"center"}>No job ads created by you.</Text>
        </Flex>
      </Container>
    </>
  );
};

export default UserProfile;
