export type QueueType =
  | "employer-verification-emails"
  | "employer-recovery-emails";
type QueueUrl = Record<QueueType, string>;

export const queueUrls: QueueUrl = {
  "employer-verification-emails":
    "https://sqs.eu-central-1.amazonaws.com/440744222475/employer-verification-emails",
  "employer-recovery-emails":
    "https://sqs.eu-central-1.amazonaws.com/440744222475/employer-recovery-emails",
};
