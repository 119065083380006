import { Autocomplete, ComboboxItem, Flex, TextInput } from "@mantine/core";
import { useDebouncedCallback } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { searchCityCountryOrAddress } from "../../helpers/data";
import { IJobAdRequest } from "../../interfaces/job";

const JobAddress: React.FC<{
  request: IJobAdRequest;
  setRequest(prop: keyof IJobAdRequest, data: any): void;
}> = ({ request, setRequest }) => {
  const [results, setResults] = useState<ComboboxItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

  const searchForAddress = useDebouncedCallback(async () => {
    if (searchTerm !== null && searchTerm.length > 2) {
      const getAddresses = await searchCityCountryOrAddress(
        searchTerm,
        "city,region,country"
      );
      setResults(getAddresses.result);
      setDropdownOpened(true);
    } else {
      setResults([]);
      setDropdownOpened(false);
    }
  }, 300);

  useEffect(() => {
    searchForAddress();
  }, [searchTerm]);

  return (
    <Autocomplete
      classNames={{
        root: request.addressError !== null ? "show-error" : "",
      }}
      styles={
        request.addressError !== null
          ? { input: { color: "crimson", borderColor: "crimson" } }
          : {}
      }
      error={request.addressError}
      defaultValue={request.address ? request.address : ""}
      onOptionSubmit={(e) => {
        setDropdownOpened(false);
        setRequest("address", e);
        setRequest("addressError", null);
      }}
      onInput={(e) => {
        setSearchTerm(e.currentTarget.value);
      }}
      dropdownOpened={dropdownOpened}
      label="Address"
      data={results.length > 0 ? results : undefined}
      placeholder="search for city, country"
    />
  );
};

export default JobAddress;
