import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { checkRecovery, savePassword } from "../../services/auth";
import { IResult } from "../../interfaces/result";
import Loading from "../../components/Loading";
import { Button, Flex, Paper, Text, TextInput } from "@mantine/core";
import Logo from "../../components/Logo";
import { IEmployerResetPasswordRequest } from "../../interfaces/employer";
import AuthFooter from "../../components/auth/AuthFooter";
import isEmail from "validator/lib/isEmail";
import { isStrongPass } from "../../helpers/validation";

const CreatePassword = () => {
  const [resetPasswordRequest, setResetPasswordRequest] = useState<
    IEmployerResetPasswordRequest
  >({
    email_address: null,
    email_address_error: null,
    password: null,
    password_error: null,
  });
  const [result, setResult] = useState<IResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkLoading, setCheckLoading] = useState<boolean>(true);
  const [checkResult, setCheckResult] = useState<IResult | null>(null);
  const params = useParams<{ code: string }>();
  const navigate = useNavigate();

  const setRequestProp = (
    prop: keyof IEmployerResetPasswordRequest,
    data: any
  ) => {
    setResetPasswordRequest((prev) => ({ ...prev, [prop]: data }));
  };

  useEffect(() => {
    if (typeof params !== "undefined" && params.code !== null) {
      setTimeout(async () => {
        const checkResponse = await checkRecovery(params.code!);
        setCheckResult(checkResponse);
        setCheckLoading(false);
        if (checkResponse.status === true) {
          setRequestProp("email_address", checkResponse.result);
        }
      }, 1000);
    } else {
      navigate("/auth/sign-in");
    }
  }, [params]);

  const onSavePassword = async () => {
    if (
      resetPasswordRequest.password === null ||
      !isStrongPass(resetPasswordRequest.password)
    ) {
      setRequestProp(
        "password_error",
        "Password must be at least 8 chars long with at leat one upper/lowercase letter, one number, one special char."
      );
    }

    if (
      resetPasswordRequest.password !== null &&
      isStrongPass(resetPasswordRequest.password)
    ) {
      setLoading(true);
      const savePass = await savePassword(resetPasswordRequest);
      setResult(savePass);
      setLoading(false);
      if (savePass.status === true) {
        navigate("/auth/sign-in");
      }
    }
  };

  if (checkLoading && checkResult === null) {
    return (
      <>
        <Helmet>
          <title>Create Password</title>
        </Helmet>
        <Flex
          align={"center"}
          py={30}
          direction={"column"}
          h={"100%"}
          justify={"center"}
        >
          <Loading />
        </Flex>
      </>
    );
  } else if (
    checkLoading === false &&
    checkResult !== null &&
    checkResult.status === true
  ) {
    return (
      <>
        <Helmet>
          <title>Create Password</title>
        </Helmet>
        <Flex
          align={"center"}
          py={30}
          direction={"column"}
          h={"100%"}
          justify={"space-between"}
        >
          <Logo />
          <Paper bg={"transparent"} w={320} p={"md"} radius={8} mt={15}>
            <Text ta={"center"} mb={5} size="20px" fw={700}>
              Create Password
            </Text>
            <Text ta={"center"} mb={15}>
              Password must be at least 8 chars long with at leat one
              upper/lowercase letter, one number, one special char.
            </Text>
            {result !== null && result.status === false && (
              <Text mb={15} c="crimson" ta={"center"}>
                Password couldn't save. Please try again!
              </Text>
            )}
            <TextInput
              disabled={loading}
              error={resetPasswordRequest.password_error}
              onChange={(e) => {
                setRequestProp("password", e.currentTarget.value);
                setRequestProp("password_error", null);
                setResult(null);
              }}
              type="password"
              variant="filled"
              radius={0}
              label="New Password"
              placeholder="new password"
              mb={15}
            />
            <Button
              loading={loading}
              onClick={onSavePassword}
              mb={15}
              radius={32}
              color="#1d2124"
              fullWidth
            >
              Save Password
            </Button>
          </Paper>
          <AuthFooter />
        </Flex>
      </>
    );
  } else if (
    checkLoading === false &&
    checkResult !== null &&
    checkResult.status === false
  ) {
    return (
      <>
        <Helmet>
          <title>Create Password</title>
        </Helmet>
        <span>wrong code</span>
      </>
    );
  } else {
    return null;
  }
};

export default CreatePassword;
