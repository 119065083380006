import {
  IconBell,
  IconBellFilled,
  IconBriefcase,
  IconBriefcaseFilled,
  IconHome,
  IconHomeFilled,
  IconMessage,
  IconMessageFilled,
  IconPlayBasketball,
  IconUsersGroup,
} from "@tabler/icons-react";
import { INavbarMenuItem } from "../interfaces/navbar";

export const navbarMenuItems: INavbarMenuItem[] = [
  {
    title: "Home",
    icon: <IconHome />,
    href: "/dashboard",
    iconActive: <IconHomeFilled color="#007B80" />,
  },
  {
    title: "Job Ads",
    icon: <IconBriefcase />,
    href: "/job-ads",
    iconActive: <IconBriefcaseFilled color="#007B80" />,
  },
  {
    title: "Messages",
    icon: <IconMessage />,
    href: "/messages",
    iconActive: <IconMessageFilled color="#007B80" />,
    nCount: 5,
  },
  {
    title: "Notifications",
    icon: <IconBell />,
    href: "/notifications",
    iconActive: <IconBellFilled color="#007B80" />,
  },
  {
    title: "Talent Pool",
    icon: <IconUsersGroup />,
    href: "/talent-pool",
    iconActive: <IconUsersGroup color="#007B80" />,
  },
  {
    title: "Team",
    icon: <IconPlayBasketball />,
    href: "/team",
    iconActive: <IconPlayBasketball color="#007B80" />,
  },
];
