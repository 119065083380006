import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import { IconUsersGroup } from "@tabler/icons-react";

const TalentPool = () => {
  return (
    <>
      <Helmet>
        <title>Talent Pool</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconUsersGroup />,
          title: "Talent Pool",
          description:
            "Save and organize promising profiles into custom lists for easy access during recruitment.",
        }}
      />
    </>
  );
};

export default TalentPool;
