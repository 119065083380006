import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Placeholder from "@tiptap/extension-placeholder";
import { Flex, Text } from "@mantine/core";
import { IJobAdRequest } from "../../interfaces/job";

const JobAdTextEditor: React.FC<{
  label: string;
  request: IJobAdRequest;
  setRequest(prop: keyof IJobAdRequest, data: any): void;
}> = ({ label, request, setRequest }) => {
  const content = null;
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "enter job description" }),
    ],
    content: request.description,
    onUpdate: (e) => {
      const editor = e;
      const data = editor.editor.getText();
      setRequest(
        "description",
        data === null || data === "" ? null : editor.editor.getHTML()
      );
      setRequest("descriptionError", null);
    },
  });

  return (
    <Flex direction={"column"}>
      <Text className="mantine-TextInput-label">{label}</Text>
      {request.descriptionError !== null && (
        <Text mb={5} c={"crimson"} size="13px">
          {request.descriptionError}
        </Text>
      )}
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar bg={"#fcfcfc"} sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </Flex>
  );
};

export default JobAdTextEditor;
