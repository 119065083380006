import {
  ActionIcon,
  Avatar,
  Button,
  Container,
  FileButton,
  Flex,
  Paper,
  Text,
} from "@mantine/core";
import {
  IconExclamationCircle,
  IconImageInPicture,
  IconInfoCircle,
  IconTrash,
} from "@tabler/icons-react";
import PageHeader from "../../components/page/PageHeader";
import { Helmet } from "react-helmet";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { removeFromStorage, uploadToStorage } from "../../services/storage";
import { notifications } from "@mantine/notifications";
import { updateEmployer } from "../../services/employer";

const UserSettingsProfilePicture = () => {
  const authContext = useContext(AuthContext);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string | null>();
  const resetRef = useRef<() => void>(null);

  const selectFile = async (file: File | null) => {
    if (
      file !== null &&
      ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
    ) {
      setUploadLoading(true);
      const uploadProfilePicture = await uploadToStorage(
        "employer-profile-pictures",
        file,
        authContext.employer!.profile_picture
      );
      setUploadLoading(false);

      if (uploadProfilePicture.status === true) {
        await updateEmployer({
          id: authContext.employer!.id,
          profile_picture: uploadProfilePicture.result,
        });
        authContext.update("profile_picture", uploadProfilePicture.result);
        notifications.show({
          position: "top-right",
          title: "Done!",
          message: "Your new profile picture has been uploaded!",
          icon: <IconInfoCircle color="#007B80" />,
          color: "white",
          styles: {
            root: { backgroundColor: "#1d2124" },
            title: { color: "white" },
            body: { color: "white" },
          },
        });
      } else {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Profile picture couldn't updated.",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
      }
    } else if (
      file !== null &&
      !["image/png", "image/jpg", "image/jpeg"].includes(file.type)
    ) {
      setFileError("Invalid file type.");
    }
  };

  const removeFile = async () => {
    setRemoveLoading(true);
    const removePicture = await removeFromStorage(
      "employer-profile-pictures",
      authContext.employer!.profile_picture!
    );
    setRemoveLoading(false);

    if (removePicture.status === true) {
      authContext.update("profile_picture", removePicture.result);
      await updateEmployer({
        id: authContext.employer!.id,
        profile_picture: null,
      });
      notifications.show({
        position: "top-right",
        title: "Done!",
        message: "Your profile picture has been removed!",
        icon: <IconInfoCircle color="#007B80" />,
        color: "white",
        styles: {
          root: { backgroundColor: "#1d2124" },
          title: { color: "white" },
          body: { color: "white" },
        },
      });
    } else {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "Profile picture couldn't removed.",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>User Settings - Profile Picture</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconImageInPicture />,
          title: "Profile Picture",
          description: "Update your profile picture.",
        }}
      />
      <Container size={"xl"}>
        <Flex
          style={{ margin: "0 auto" }}
          align={"center"}
          direction={"column"}
          justify={"center"}
        >
          <Text mb={15} size="16px" fw={600}>
            Update Your Profile Picture
          </Text>
          <Flex align={"flex-start"} columnGap={3}>
            <p style={{ width: "24px" }}>&nbsp;</p>
            <Avatar
              src={authContext.employer!.profile_picture}
              size={128}
              radius={8}
              mb={15}
            />
            {authContext.employer!.profile_picture !== null && (
              <ActionIcon
                disabled={removeLoading || uploadLoading}
                onClick={removeFile}
                radius={32}
                color="crimson"
                size={24}
              >
                <IconTrash size={16} />
              </ActionIcon>
            )}
            {authContext.employer!.profile_picture === null && (
              <p style={{ width: "24px" }}>&nbsp;</p>
            )}
          </Flex>
          <FileButton
            resetRef={resetRef}
            onChange={selectFile}
            accept="image/png, image/jpg, image/jpeg"
          >
            {(props) => (
              <Button
                disabled={removeLoading || uploadLoading}
                mb={15}
                radius={32}
                c={"#1d2124"}
                color="#C6F16D"
                {...props}
                loading={uploadLoading}
              >
                Select New Image
              </Button>
            )}
          </FileButton>
          <Text w={320} c={"crimson"}>
            {fileError}
          </Text>
        </Flex>
      </Container>
    </>
  );
};

export default UserSettingsProfilePicture;
