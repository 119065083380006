import { Checkbox, Drawer, Flex, Text, TextInput } from "@mantine/core";
import {
  IApplication,
  IApplicationFilter,
  IStage,
} from "../interfaces/application";
import { getLocationsOfApplicants } from "../helpers/applications";
import { SetStateAction, useEffect, useState } from "react";
import { applicationStages } from "../data/job";

const ApplicationStageFiltersDrawer: React.FC<{
  filters: IApplicationFilter[];
  opened: boolean;
  close: React.Dispatch<SetStateAction<boolean>>;
  apps: IApplication[];
  setFilters: React.Dispatch<SetStateAction<IApplicationFilter[]>>;
  changeStage: React.Dispatch<SetStateAction<IStage[]>>;
  selectedStages: IStage[];
}> = ({
  filters,
  opened,
  selectedStages,
  close,
  setFilters,
  apps,
  changeStage,
}) => {
  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    if (apps.length > 0) {
      const locations = getLocationsOfApplicants(apps, apps[0].job.id);
      setCountries(locations.countries);
    }
  }, [apps]);

  const setStages = (s: IStage, type: boolean) => {
    let stages = [...selectedStages];
    if (type === true && !stages.includes(s)) {
      stages.push(s);
    } else {
      stages = stages.filter((st) => st.stage !== s.stage);
    }

    if (stages.length === 0) {
      const newFilters = filters.filter((f) => f.type !== "stage");
      setFilters(newFilters);
    } else if (filters.filter((f) => f.type === "stage").length === 0) {
      const newFilters = [...filters];
      newFilters.push({ active: true, type: "stage" });
      setFilters(newFilters);
    }

    changeStage(stages);
  };

  return (
    <Drawer
      radius={12}
      offset={8}
      title={"Applications Filter"}
      styles={{ title: { fontWeight: "600", fontSize: "16px" } }}
      opened={opened}
      position="right"
      onClose={() => {
        close(false);
      }}
    >
      {apps.length > 0 && (
        <Flex direction={"column"} rowGap={15}>
          <TextInput
            placeholder="search for candidate"
            label="Search for Candidate"
          />
          <Text c={"#1d2124"} fw={600}>
            Applicant's Country
          </Text>
          {countries.map((country, i) => {
            return (
              <Checkbox
                disabled={countries.length === 1}
                color="#007B80"
                style={{ cursor: "pointer" }}
                styles={{
                  input: { cursor: "pointer" },
                  label: { cursor: "pointer" },
                }}
                label={country}
                radius={32}
                key={i}
              />
            );
          })}
          <Text c={"#1d2124"} fw={600}>
            Applicant Stage
          </Text>
          {applicationStages.map((as, i) => {
            return (
              <Checkbox
                checked={selectedStages.includes(as)}
                onChange={(e) => {
                  const data = e.currentTarget.checked;
                  setStages(as, data);
                }}
                color="#007B80"
                style={{ cursor: "pointer" }}
                styles={{
                  input: { cursor: "pointer" },
                  label: { cursor: "pointer" },
                }}
                label={as.label}
                radius={32}
                key={i}
              />
            );
          })}
        </Flex>
      )}

      {apps.length === 0 && <Text ta={"center"}>No Application!</Text>}
    </Drawer>
  );
};

export default ApplicationStageFiltersDrawer;
