import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Grid,
  Image,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { Helmet } from "react-helmet";
import PageHeader from "../../components/page/PageHeader";
import {
  IconCheck,
  IconDiamond,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { useContext, useRef, useState } from "react";
import { setOrder } from "../../services/checkout";
import { AuthContext } from "../../contexts/AuthProvider";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

const Purchase = () => {
  const authContext = useContext(AuthContext);
  const { width } = useViewportSize();
  const [selectedOption, setSelectedOption] = useState<
    "10" | "50" | "100" | "150" | "custom"
  >("10");
  const [slotCount, setSlotCount] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const amountRef = useRef<HTMLInputElement>(null);

  const setJobSlotCount = (c: number) => {
    if (loading === false) {
      if (c <= 0) {
        setSlotCount(1);
        amountRef.current!.value = "1";
      } else {
        setSlotCount(c);
      }
    }
  };

  const proceed = async () => {
    const insertOrder = await setOrder(
      slotCount * 199 * 100,
      authContext.employer!.company_id!,
      authContext.employer!.id,
      slotCount
    );

    if (insertOrder.status == true) {
      navigate("/purchase/checkout/" + insertOrder.result);
    } else {
      notifications.show({
        position: "top-right",
        title: "Error!",
        message: "Couldn't create your order.",
        icon: <IconExclamationCircle color="crimson" />,
        color: "white",
        styles: {
          root: { backgroundColor: "crimson" },
          title: { color: "white" },
          body: { color: "white" },
          description: { color: "white" },
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Purchase Job Slots</title>
      </Helmet>
      <PageHeader
        h={{
          icon: <IconDiamond />,
          title: "Purchase Job Slots",
          description:
            "Boost your recruitment strategy by purchasing job slots that connect you with top talent in the oil and gas industry.",
        }}
      />
      <Container size={"lg"}>
        <Flex hiddenFrom="lg" direction={"column"}>
          <Text mb={5} size="18px" fw={600}>
            Hire faster with O&G Talent job ads
          </Text>
          <Text mb={15}>
            Leverage our powerful platform that allows you to create engaging
            job ads and find qualified candidates quickly.
          </Text>
        </Flex>
        <Flex
          align={"flex-start"}
          justify={width < 700 ? "center" : "space-between"}
        >
          <Flex visibleFrom="lg" direction={"column"}>
            <Text mb={5} size="18px" fw={600}>
              Hire faster with O&G Talent job ads
            </Text>
            <Text mb={15}>
              Leverage our powerful platform that allows you to create engaging
              job ads and find qualified candidates quickly.
            </Text>
            <Center>
              <Image h={300} w={300} src={"/purchase.svg"} />
            </Center>
          </Flex>

          <Paper
            w={width < 1300 ? "100%" : 500}
            radius={12}
            bg={"#F2F5F9"}
            p={"xl"}
          >
            <Text mb={5} size="20px" fw={600}>
              Job Slots
            </Text>
            <Text mb={15}>Get started with all the features</Text>
            <Badge mb={5} bg={"#00D296"} radius={0}>
              Bestseller
            </Badge>
            <Flex columnGap={3} align={"flex-end"}>
              <Text fw={600} size="36px">
                £199
              </Text>
              <Text>per job slot</Text>
            </Flex>
            <Text mb={15}>
              (instead of{" "}
              <span style={{ textDecoration: "line-through" }}>£259</span>)
            </Text>
            <Flex
              mb={15}
              align={"center"}
              justify={"space-evenly"}
              columnGap={15}
            >
              <Paper
                onClick={
                  loading === true
                    ? () => {}
                    : () => {
                        setSelectedOption("10");
                        setJobSlotCount(10);
                      }
                }
                w={"100%"}
                h={60}
                style={
                  selectedOption === "10"
                    ? {
                        border: "2px solid #06A7AC",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }
                    : { borderRadius: "6px", cursor: "pointer" }
                }
              >
                <Flex h={56} direction={"column"} justify={"center"}>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "10" ? 600 : 500}
                    ta={"center"}
                  >
                    10
                  </Text>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "10" ? 600 : 500}
                    ta={"center"}
                    size="13px"
                  >
                    Job Ads
                  </Text>
                </Flex>
              </Paper>
              <Paper
                onClick={
                  loading === true
                    ? () => {}
                    : () => {
                        setSelectedOption("50");
                        setJobSlotCount(50);
                      }
                }
                w={"100%"}
                h={60}
                style={
                  selectedOption === "50"
                    ? {
                        border: "2px solid #06A7AC",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }
                    : { borderRadius: "6px", cursor: "pointer" }
                }
              >
                <Flex h={60} direction={"column"} justify={"center"}>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "50" ? 600 : 500}
                    ta={"center"}
                  >
                    50
                  </Text>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "50" ? 600 : 500}
                    ta={"center"}
                    size="13px"
                  >
                    Job Ads
                  </Text>
                </Flex>
              </Paper>
              <Paper
                onClick={
                  loading === true
                    ? () => {}
                    : () => {
                        setSelectedOption("100");
                        setJobSlotCount(100);
                      }
                }
                w={"100%"}
                h={60}
                style={
                  selectedOption === "100"
                    ? {
                        border: "2px solid #06A7AC",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }
                    : { borderRadius: "6px", cursor: "pointer" }
                }
              >
                <Flex h={60} direction={"column"} justify={"center"}>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "100" ? 600 : 500}
                    ta={"center"}
                  >
                    100
                  </Text>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "100" ? 600 : 500}
                    ta={"center"}
                    size="13px"
                  >
                    Job Ads
                  </Text>
                </Flex>
              </Paper>
              <Paper
                onClick={
                  loading === true
                    ? () => {}
                    : () => {
                        setSelectedOption("150");
                        setJobSlotCount(150);
                      }
                }
                w={"100%"}
                h={60}
                style={
                  selectedOption === "150"
                    ? {
                        border: "2px solid #06A7AC",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }
                    : { borderRadius: "6px", cursor: "pointer" }
                }
              >
                <Flex h={60} direction={"column"} justify={"center"}>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "150" ? 600 : 500}
                    ta={"center"}
                  >
                    150
                  </Text>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "150" ? 600 : 500}
                    ta={"center"}
                    size="13px"
                  >
                    Job Ads
                  </Text>
                </Flex>
              </Paper>
              <Paper
                onClick={
                  loading === true
                    ? () => {}
                    : () => {
                        setSelectedOption("custom");
                      }
                }
                w={"100%"}
                h={60}
                style={
                  selectedOption === "custom"
                    ? {
                        border: "2px solid #06A7AC",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }
                    : { borderRadius: "6px", cursor: "pointer" }
                }
              >
                <Flex h={60} direction={"column"} justify={"center"}>
                  <Text
                    c={"#06A7AC"}
                    fw={selectedOption === "custom" ? 600 : 500}
                    ta={"center"}
                  >
                    Custom
                  </Text>
                </Flex>
              </Paper>
            </Flex>
            {selectedOption === "custom" && (
              <TextInput
                disabled={loading}
                defaultValue={slotCount}
                ref={amountRef}
                onChange={(e) => {
                  setJobSlotCount(parseInt(e.currentTarget.value));
                }}
                label="Enter Custom Amount of Job Slots"
                mb={15}
                radius={0}
                placeholder="18"
                type="number"
                min={1}
              />
            )}
            <Text size="16px" mb={15} fw={600}>
              Benefits
            </Text>
            <Flex mb={5} align={"center"} columnGap={15}>
              <Box>
                <IconCheck color="#1d2124" />
              </Box>
              <Text>
                Create unique company profile page which includes cover image
                and logo.
              </Text>
            </Flex>
            <Flex mb={5} align={"center"} columnGap={15}>
              <Box>
                <IconCheck color="#1d2124" />
              </Box>
              <Text>
                Restrict candidates from apply for your job ads. (Ban candidates
                by their email address)
              </Text>
            </Flex>
            <Flex mb={5} align={"center"} columnGap={15}>
              <Box>
                <IconCheck color="#1d2124" />
              </Box>
              <Text>
                Invite any candidate to apply for any job slot with{" "}
                <strong>one-click</strong>.
              </Text>
            </Flex>
            <Flex mb={5} align={"center"} columnGap={15}>
              <Box>
                <IconCheck color="#1d2124" />
              </Box>
              <Text>
                Access to <strong>10k+ candidates' CVs</strong> by using our
                Talent Pool.
              </Text>
            </Flex>
            <Flex mb={5} align={"center"} columnGap={15}>
              <Box>
                <IconCheck color="#1d2124" />
              </Box>
              <Text>
                Send and receive message from your collegues and candidates.
              </Text>
            </Flex>
            <Flex mb={15} align={"center"} columnGap={15}>
              <Box>
                <IconCheck color="#1d2124" />
              </Box>
              <Text>Get job applications as notifications in real-time.</Text>
            </Flex>
            <Button
              loading={loading}
              onClick={proceed}
              radius={32}
              fullWidth
              color="#06A7AC"
            >
              Purchase {slotCount} Job Slots
            </Button>
          </Paper>
        </Flex>
      </Container>
    </>
  );
};

export default Purchase;
