import { Button, Flex, Modal, TextInput } from "@mantine/core";
import { title } from "process";
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthProvider";
import { ICompanyUpdateRequest } from "../interfaces/company";
import { isHost } from "../helpers/validation";
import { updateCompany } from "../services/company";
import { notifications } from "@mantine/notifications";
import { IconExclamationCircle, IconInfoCircle } from "@tabler/icons-react";

const CompanyProfileUpdateModal: React.FC<{
  opened: boolean;
  toggle(): void;
}> = ({ opened, toggle }) => {
  const authContext = useContext(AuthContext);
  const [updateRequest, setUpdateRequest] = useState<ICompanyUpdateRequest>({
    linkedin: null,
    linkedin_error: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const setRequestProp = (prop: keyof ICompanyUpdateRequest, data: any) => {
    setUpdateRequest((prev) => ({ ...prev, [prop]: data }));
  };

  const onUpdate = async () => {
    if (updateRequest.linkedin !== null) {
      if (!isHost(updateRequest.linkedin, "linkedin.com")) {
        setRequestProp("linkedin_error", "Please provide a linkedin url.");
      }

      if (isHost(updateRequest.linkedin, "linkedin.com")) {
        setLoading(true);
        const update = await updateCompany({
          id: authContext.employer!.company.id,
          linkedin: updateRequest.linkedin,
        });
        setLoading(false);
        if (update.status === true) {
            authContext.update("company", update.result);
            notifications.show({
              position: "top-right",
              title: "Done!",
              message: "Company profile has been updated",
              icon: <IconInfoCircle color="#007B80" />,
              color: "white",
              styles: {
                root: { backgroundColor: "#1d2124" },
                title: { color: "white" },
                body: { color: "white" },
              },
            });
            toggle();
          } else {
            notifications.show({
              position: "top-right",
              title: "Error!",
              message: "Company profile couldn't update.",
              icon: <IconExclamationCircle color="crimson" />,
              color: "white",
              styles: {
                root: { backgroundColor: "crimson" },
                title: { color: "white" },
                body: { color: "white" },
                description: { color: "white" },
              },
            });
          }
      }
    } else {
      setLoading(true);
      const update = await updateCompany({
        id: authContext.employer!.company.id,
        linkedin: null,
      });
      setLoading(false);

      if (update.status === true) {
        authContext.update("company", update.result);
        notifications.show({
          position: "top-right",
          title: "Done!",
          message: "Company profile has been updated",
          icon: <IconInfoCircle color="#007B80" />,
          color: "white",
          styles: {
            root: { backgroundColor: "#1d2124" },
            title: { color: "white" },
            body: { color: "white" },
          },
        });
        toggle();
      } else {
        notifications.show({
          position: "top-right",
          title: "Error!",
          message: "Company profile couldn't update.",
          icon: <IconExclamationCircle color="crimson" />,
          color: "white",
          styles: {
            root: { backgroundColor: "crimson" },
            title: { color: "white" },
            body: { color: "white" },
            description: { color: "white" },
          },
        });
      }
    }
  };

  return (
    <Modal
      closeOnClickOutside={false}
      title="Update Company Settings"
      size={"lg"}
      opened={opened}
      onClose={toggle}
      styles={{ title: { fontWeight: "600" } }}
    >
      <Flex direction={"column"} rowGap={15}>
        <TextInput
          description="You cannot change company name. For further please contact."
          radius={0}
          defaultValue={authContext.employer!.company.name}
          disabled
          label="Name"
        />
        <TextInput
          description="You cannot change company address. For further please contact."
          radius={0}
          defaultValue={authContext.employer!.company.address}
          disabled
          label="Address"
        />
        <TextInput
          description="You cannot change company website. For further please contact."
          radius={0}
          defaultValue={authContext.employer!.company.web_site}
          disabled
          label="Website"
        />
        <TextInput
          error={updateRequest.linkedin_error}
          onChange={(e) => {
            setRequestProp("linkedin", e.currentTarget.value);
            setRequestProp("linkedin_error", null);
          }}
          radius={0}
          defaultValue={
            authContext.employer!.company.linkedin
              ? authContext.employer!.company.linkedin
              : undefined
          }
          placeholder="linkedin page url"
          label="LinkedIn Page"
        />
        <Button
          loading={loading}
          onClick={onUpdate}
          mb={15}
          radius={32}
          color="#1d2124"
          fullWidth
        >
          Update
        </Button>
      </Flex>
    </Modal>
  );
};

export default CompanyProfileUpdateModal;
