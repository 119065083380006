import { parseUrl } from "../helpers/data";
import { ICompany } from "../interfaces/company";
import { IResult } from "../interfaces/result";
import { supabase } from "../supabase";

export const getCompanyBy = (by: string, val: string): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data, error } = await supabase
      .from("companies")
      .select("*")
      .eq(by, val)
      .select();

    console.log(data, error);

    if (error === null) {
      const company: ICompany | null =
        data === null || data.length === 0 ? null : data[0];
      resolve({
        error: null,
        status: true,
        result: company,
      });
    } else {
      resolve({ error: error.message, status: false, result: [] });
    }
  });
};

export const createCompany = (
  name: string,
  web_site: string,
  address: string,
  created_by: string
): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const parsedUrl = parseUrl(web_site);
    const { data } = await supabase
      .from("companies")
      .select("*")
      .eq("web_site", parsedUrl)
      .select();

    if (data === null || data.length === 0) {
      const { data: create } = await supabase
        .from("companies")
        .insert({
          name,
          web_site: parsedUrl,
          address,
          created_by,
          created_at: new Date(),
        })
        .select();

      if (create !== null && create.length > 0) {
        resolve({ error: null, status: true, result: create[0] });
      } else {
        resolve({ error: "CompanyCreateError", status: false, result: null });
      }
    } else {
      resolve({ error: null, status: true, result: data[0] });
    }
  });
};

export const updateCompany = (val: any): Promise<IResult> => {
  return new Promise<IResult>(async (resolve) => {
    const { data } = await supabase
      .from("companies")
      .update(val)
      .eq("id", val.id)
      .select();

    if (data !== null && data.length > 0) {
      resolve({ error: null, status: true, result: data[0] });
    } else {
      resolve({ error: null, status: true, result: null });
    }
  });
};
