import validator, { isURL } from "validator";
import axios from "axios";

export const isBusinessEmail = async (email: string) => {
  const emailResult = await axios.get(
    "https://emailvalidation.abstractapi.com/v1/?api_key=210b36d2b29c4ac7be226915dfb7e86f&email=" +
      email
  );

  return emailResult.data.is_free_email.value === false;
};

export const isStrongPass = (pass: string) => {
  return validator.isStrongPassword(pass, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  });
};

export const isHost = (url: string, host: string) => {
  if (isURL(url)) {
    const urlData = new URL(url);
    console.log("host", urlData.host);
    return urlData.host.replace("www.", "") === host;
  } else {
    return false;
  }
};
