import {
  Anchor,
  Button,
  Center,
  Flex,
  Image,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";
import Logo from "../../components/Logo";
import { Helmet } from "react-helmet";
import AuthFooter from "../../components/auth/AuthFooter";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useState } from "react";
import { IEmployerSignInRequest } from "../../interfaces/employer";
import isEmail from "validator/lib/isEmail";
import { signin } from "../../services/auth";
import { IResult } from "../../interfaces/result";
import { AuthContext } from "../../contexts/AuthProvider";

const Login = () => {
  const authContext = useContext(AuthContext);
  const { width } = useViewportSize();
  const [signInRequest, setSignInRequest] = useState<IEmployerSignInRequest>({
    email_address: null,
    email_address_error: null,
    password: null,
    password_error: null,
  });
  const [result, setResult] = useState<IResult>({
    error: null,
    status: false,
    result: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const setRequestProp = (prop: keyof IEmployerSignInRequest, data: any) => {
    setSignInRequest((prev) => ({ ...prev, [prop]: data }));
  };

  const onSignIn = async () => {
    if (
      signInRequest.email_address === null ||
      !isEmail(signInRequest.email_address)
    ) {
      setRequestProp(
        "email_address_error",
        "Please provide a valid email address."
      );
    }

    if (signInRequest.password === null || signInRequest.password.length < 8) {
      setRequestProp(
        "password_error",
        "Password cannot be empty or shorter than 8 chars."
      );
    }

    if (
      isEmail(signInRequest.email_address!) &&
      signInRequest.password !== null &&
      signInRequest.password.length > 7
    ) {
      setLoading(true);
      const signInResponse = await signin(signInRequest);
      if (signInResponse.status === true) {
        authContext.login(signInResponse.result);
      }
      setResult(signInResponse);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>

      <Flex
        align={"center"}
        py={30}
        direction={"column"}
        h={"100%"}
        justify={"space-between"}
      >
        <Logo href="/" />
        <Paper bg={"transparent"} w={320} p={"md"} radius={8} mt={15}>
          <Text ta={"center"} mb={5} size="20px" fw={700}>
            Welcome Back!
          </Text>
          <Text ta={"center"} mb={15}>
            Not joined yet?{" "}
            <Anchor href="/auth/create-account" c={"#0698a0"}>
              Create Account
            </Anchor>
          </Text>
          {result !== null &&
            result.status === false &&
            result.result === "NotVerified" && (
              <Text mb={15} c="crimson" ta={"center"}>
                Please verify your email address to sign into your account.
              </Text>
            )}
          {result !== null &&
            result.status === false &&
            result.result === "InvalidCredentials" && (
              <Text mb={15} c="crimson" ta={"center"}>
                Invalid Credentials!
              </Text>
            )}
          <TextInput
            disabled={loading}
            error={signInRequest.email_address_error}
            onChange={(e) => {
              setRequestProp("email_address", e.currentTarget.value);
              setRequestProp("email_address_error", null);
              setResult((prev) => ({ ...prev, ["result"]: null }));
            }}
            variant="filled"
            radius={0}
            label="Email Address"
            placeholder="enter email address"
            mb={15}
          />
          <TextInput
            disabled={loading}
            error={signInRequest.password_error}
            onChange={(e) => {
              setRequestProp("password", e.currentTarget.value);
              setRequestProp("password_error", null);
              setResult((prev) => ({ ...prev, ["result"]: null }));
            }}
            type="password"
            variant="filled"
            radius={0}
            label="Password"
            placeholder="password"
            mb={15}
          />
          <Button
            loading={loading}
            onClick={onSignIn}
            mb={15}
            radius={32}
            color="#1d2124"
            fullWidth
          >
            Sign In
          </Button>
          <Center>
            <Anchor href="/auth/recovery" ta={"center"} c={"#0698a0"}>
              Having problem with signing in?
            </Anchor>
          </Center>
        </Paper>

        {width < 700 && <Image src={"/people.svg"} w={200} h={200} />}
        <AuthFooter />
      </Flex>
    </>
  );
};

export default Login;
