import { Helmet } from "react-helmet";
import HomeHeader from "../components/home/HomeHeader";
import { Container, Flex } from "@mantine/core";
import HomeTeamSummary from "../components/home/HomeTeamSummary";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Container size={"xl"}>
        <Flex direction={"column"} rowGap={15}>
          <HomeHeader />
          <HomeTeamSummary />
        </Flex>
      </Container>
    </>
  );
};

export default Home;
